import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LOCAL_STORAGE_KEYS } from '../../../localstorage/LocalStorage.keys';
import { ApiResults } from '../../../models/ApiResults';
import { CompanyGroup } from '../../../models/CompanyGroup';
import { ManualInput } from '../../../models/ManualInput';
import { CompaniesService } from '../../../services/Companies.service';
import { ManualInputsService } from '../../../services/ManualInput.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { RootState } from '../../../store/store';
import { checkResponseStatus } from '../../../utils/api/response';

export interface ManualInputsFilterFields extends ManualInput {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  is_active?: any;
  entry_date_before?: string;
  entry_date_after?: string;
  account_id?: number;
}

export interface ManualInputsResult extends ApiResults {
  results: ManualInput[];
}

interface ManualInputsContextProps {
  manualInputs: ManualInputsResult | undefined;
  fetchManualInputs: () => Promise<void>;
  deleteManualInput: (id: number) => Promise<void>;
  deleteManyManualInputs: (items: string[]) => Promise<void>;
  filterFields: ManualInputsFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<ManualInputsFilterFields>>;
  saved: boolean;
  companyGroup: CompanyGroup;
  handleSetSaved: (value: boolean) => void;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
  usingInManualInputsPage?: boolean;
}

const ManualInputsContextProvider = createContext<ManualInputsContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
  usingInManualInputsPage?: boolean;
}

export const ManualInputsContext = ({ children, usingInManualInputsPage }: Props) => {
  const dispatch = useDispatch();
  const { companiesIds, loading } = useSelector((state: RootState) => state.companiesContext);
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [manualInputs, setManualInputs] = React.useState<ManualInputsResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<ManualInputsFilterFields>({} as ManualInputsFilterFields);
  const [companyGroup, setCompanyGroup] = useState<CompanyGroup>({} as CompanyGroup);
  let companies_context = localStorage.getItem(LOCAL_STORAGE_KEYS.COMPANIES_CONTEXT);
  companies_context = companies_context ? JSON.parse(companies_context) : [];

  const fetchManualInputs = async () => {
    dispatch(showSpinner());
    try {
      const response = await ManualInputsService.get(filterFields, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        const results = response.data.results;
        let filteredResults;
        if (!companies_context || companies_context.length === 0) {
          filteredResults = results;
        } else {
          filteredResults = results.filter((result) => companies_context.includes(result.company.id));
        }
        setManualInputs({ ...response.data, results: filteredResults });
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error?.data?.detail || error?.data?.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchCompanyGroup = async () => {
    try {
      const response = await CompaniesService.getOne(companiesIds[0]);
      if (response && checkResponseStatus(response)) {
        setCompanyGroup(response?.data?.group);
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  const deleteManualInput = async (id: number) => {
    dispatch(showSpinner());
    try {
      await ManualInputsService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      await fetchManualInputs();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyManualInputs = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await ManualInputsService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registros excluídos!',
          severity: 'success'
        })
      );
      await fetchManualInputs();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    if (!usingInManualInputsPage) return;

    if (!loading && companiesIds && companiesIds.length > 0) {
      fetchManualInputs();
    }
    fetchCompanyGroup();
  }, [filterFields, page, rowsPerPage, companiesIds, usingInManualInputsPage]);

  const value = useMemo(
    () => ({
      manualInputs,
      fetchManualInputs,
      deleteManualInput,
      deleteManyManualInputs,
      filterFields,
      setFilterFields,
      companyGroup,
      page,
      rowsPerPage,
      handleOrderChange,
      handlePageChange,
      handleOnChangeRowsPerPage,
      usingInManualInputsPage
    }),
    [manualInputs, filterFields, page, rowsPerPage]
  );

  return <ManualInputsContextProvider.Provider value={value}>{children}</ManualInputsContextProvider.Provider>;
};

export const useManualInputsContext = () => {
  const context = useContext(ManualInputsContextProvider);
  if (!context) {
    throw new Error('useManualInputsContext must be used within a ManualInputsProvider');
  }
  return context;
};
