import { Alert, useTheme } from '@mui/material';

interface AlertComponentProps {
  severity: 'error' | 'info' | 'success' | 'warning';
  children: React.ReactNode;
}

export const AlertComponent = ({ severity, children }: AlertComponentProps) => {
  const theme = useTheme();
  return (
    <Alert variant="standard" severity={severity} sx={{ display: 'flex', paddingY: 0, backgroundColor: 'transparent' }}>
      {children}
    </Alert>
  );
};
