import * as yup from 'yup';

const SelectOptionScheme = yup.object({
  id: yup.mixed(),
  inputValue: yup.string(),
  name: yup.string()
});

export const loginValidations = yup.object().shape({
  username: yup.string().required('Usuário é obrigatório'),
  password: yup.string().required('Senha é obrigatória')
});

export const registerValidations = yup.object().shape({
  email: yup.string().email('Email inválido').required('Email é obrigatório'),
  nome_responsavel: yup.string().required('Nome do responsável é obrigatório'),
  telefone: yup.string().required('Telefone é obrigatório')
});

export const CompanyScheme = yup.object().shape({
  company_name: yup.string().required('Nome é obrigatório'),
  group: SelectOptionScheme.required('Grupo é obrigatório').typeError('Escolha um grupo válido')
});

export const CompanyGroupScheme = yup.object().shape({
  name: yup.string().required('Nome é obrigatório'),
  chart_accounts: yup
    .object()
    .nullable()
    .shape({
      id: yup.number().required('Plano de contas é obrigatório')
    })
    .required('Plano de contas é obrigatório')
    .nullable(),
  dre_avg_analytics: yup
    .object()
    .nullable()
    .shape({
      id: yup.number().required('Análise é obrigatória')
    })
    .required('Análise é obrigatória')
    .nullable()
});
export const CompanyGroupConfigurationScheme = yup.object().shape({});

export const AnalyticsTypeScheme = yup.object().shape({
  description: yup.string().required('Descrição é obrigatório'),
  code: yup.string().required('Código é obrigatorio'),
  type: SelectOptionScheme.required('Tipo é obrigatório').typeError('Escolha um tipo válido')
});

export const AnalyticsChartScheme = yup.object().shape({
  title: yup.string().required('Título é obrigatório'),
  analytics_types: yup.array().min(1, 'Selecione ao menos uma análise').required('Selecione ao menos uma análise'),
  use_filters: yup.boolean(),
  current_period: yup.object().when('use_filters', {
    is: true,
    then: yup
      .object()
      .shape({
        id: yup.string().required('Período atual é obrigatório'),
        name: yup.string().required('Nome do período atual é obrigatório')
      })
      .required('Período atual é obrigatório')
      .nullable(),
    otherwise: yup.object().notRequired().nullable()
  }),
  retroactive_period: yup.object().when('use_filters', {
    is: true,
    then: yup
      .object()
      .shape({
        id: yup.string().required('Período retroativo é obrigatório'),
        name: yup.string().required('Nome do período retroativo é obrigatório')
      })
      .required('Período retroativo é obrigatório')
      .nullable(),
    otherwise: yup.object().notRequired().nullable()
  }),
  retroactive_quantity: yup.number().when('use_filters', {
    is: true,
    then: yup.number().typeError('Quantidade retroativa deve ser um número').required('Quantidade retroativa é obrigatória'),
    otherwise: yup.number().nullable()
  })
});

export const LoadChartScheme = yup.object().shape({});

const formulaTypeOptions = ['NONE', 'LINE', 'BAR'];
export const AnalyticsChartFormulaScheme = yup.object().shape({
  formulas: yup.array().of(
    yup.object().shape({
      title: yup.string().required('Título é obrigatório'),
      formula: yup.string().required('Fórmula é obrigatória'),
      type: yup
        .object()
        .shape({
          id: yup.string().oneOf(formulaTypeOptions, 'Tipo inválido').required('Tipo é obrigatório')
        })
        .nullable()
        .required('Tipo é obrigatório')
    })
  )
});

export const ChartAccountsScheme = yup.object().shape({
  description: yup.string().required('Descrição é obrigatório'),
  code: yup.string().required('Código é obrigatorio')
});

export const CompanyTypeScheme = yup.object().shape({
  name: yup.string().required('Nome é obrigatório')
});

export const DefectScheme = yup.object().shape({
  description: yup.string().required('Descrição é obrigatória')
});

export const ProductScheme = yup.object().shape({
  serial_number: yup.string().required('Número de série é obrigatório'),
  quality_sequence: yup.string().required('Sequência de qualidade é obrigatória')
});

export const UserScheme = yup.object().shape({
  username: yup.string().required('Usuário é obrigatório'),
  email: yup.string().email('Email inválido').required('Email é obrigatório'),
  first_name: yup.string().required('Nome é obrigatório'),
  last_name: yup.string().required('Sobrenome é obrigatório')
});

export const UserPasswordEditScheme = yup.object().shape({
  current_password: yup.string().required('Senha Atual é obrigatória'),
  confirm_password: yup.string().required('Confirmação de Senha é obrigatória'),
  password: yup.string().required('Nova Senha é obrigatória')
});
export const BudgetScheme = yup.object().shape({
  reference_year: yup.string().required('Ano é obrigatório'),
  reference_month: SelectOptionScheme.required('Mês é obrigatório').typeError('Escolha um mês válido'),
  company: SelectOptionScheme.required('Empresa é obrigatória').typeError('Escolha uma empresa válida')
});

export const BudgetItemReplicateScheme = yup.object().shape({});
export const BudgetItemCostCenterScheme = yup.object().shape({
  cost_center: SelectOptionScheme.required('Centro de Custo é obrigatório').typeError('Escolha um centro de custo válido')
});

export const CostCenterScheme = yup.object().shape({
  code: yup.string().required('Código é obrigatório'),
  description: yup.string().required('Descrição é obrigatória')
});

export const UserPermissionScheme = yup.object().shape({});

export const PermissionGroupScheme = yup.object().shape({});

export const ClientsScheme = yup.object().shape({});

export const ExpenseScheme = yup.object().shape({});

export const ManualScheme = yup.object().shape({});

export const ServiceOrderScheme = yup.object().shape({});

export const ProcessingScheme = yup.object().shape({});

export const SoldItemFileScheme = yup.object().shape({});

export const ManualInputScheme = yup.object().shape({});
