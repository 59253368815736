import { ExpandMore } from '@mui/icons-material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Box, List, ListItemButton, ListItemIcon, ListItemText, Paper, Tooltip, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { INavGroup, INavItem } from '../../../../../../../models/Menu';
import { activeNavGroup, activeNavItem } from '../../../../../../../store/slicers/menu.slicers';
import { hexToRgba } from '../../../../../../../utils/utils';
import { NavGroup } from '../../NavGroup';
import NavItem from '../../NavItem';

interface Props {
  item: INavGroup;
  navItemSelected: boolean;
}

export const DrawerClosedWithSubmenus = ({ item, navItemSelected }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Define a cor do texto e do ícone selecionados
  const textColor = 'grey.700';

  const handleClickItem = () => {
    if (item?.url) {
      navigate(item.url);
      dispatch(activeNavGroup({ openNavGroup: item.id }));
      dispatch(activeNavItem({ openNavItem: item.id }));
    }
  };

  if (!item) return <></>;

  return (
    <Box
      sx={{
        p: 0,
        display: 'flex',
        justifyContent: 'center',
        aligntItems: 'center',
        '&:hover': {
          backgroundColor: theme.palette.background.default
        }
      }}
    >
      <Tooltip
        arrow={item.children && item.children?.length > 0}
        title={
          <>
            {item.children && item.children.length > 0 ? (
              <List disablePadding component={Paper} elevation={3} sx={{ borderRadius: 1, overflow: 'hidden' }}>
                {item.children.map((menuItem: INavItem | INavGroup) => {
                  //se houver um submenu, renderiza o componente NavGroup de forma recursiva
                  if (menuItem.type === 'group') {
                    return <NavGroup key={menuItem.id} item={menuItem as INavGroup} />;
                  } else {
                    return <NavItem key={menuItem.id} item={menuItem} level={item.level} />;
                  }
                })}
              </List>
            ) : (
              ''
            )}
          </>
        }
        onClick={() => handleClickItem()}
        placement="right-start"
        componentsProps={{
          tooltip: {
            sx: {
              bgcolor: 'background.paper',
              '& .MuiTooltip-arrow': {
                color: 'secondary.light'
              },
              padding: 0
            }
          }
        }}
      >
        {item.level == 1 ? (
          <ListItemIcon
            sx={{
              color: navItemSelected ? theme.palette.primary.main : theme.palette.common.black,
              backgroundColor: navItemSelected ? hexToRgba(theme.palette.primary.main, 0.2) : theme.palette.common.white,
              minWidth: 'auto',
              m: 1,
              p: 1.3,
              borderRadius: 2,
              '&:hover': {
                backgroundColor: hexToRgba(theme.palette.primary.main, 0.1)
              }
            }}
          >
            {item.icon}
          </ListItemIcon>
        ) : (
          <ListItemButton
            sx={{
              height: 40,
              color: navItemSelected ? theme.palette.primary.main : theme.palette.common.black,
              backgroundColor: navItemSelected ? hexToRgba(theme.palette.primary.main, 0.2) : theme.palette.common.white
            }}
          >
            {item?.icon && (
              <ListItemIcon
                sx={{
                  minWidth: 32,
                  color: navItemSelected ? theme.palette.primary.main : textColor
                }}
              >
                {React.cloneElement(item.icon, {
                  fontSize: 'small'
                })}
                {item.icon}
              </ListItemIcon>
            )}
            <ListItemText
              primary={
                <Typography variant="h6" fontSize="0.8rem" color={navItemSelected ? theme.palette.primary.main : textColor}>
                  {item.title}
                </Typography>
              }
            />
            {navItemSelected ? <KeyboardArrowRightIcon fontSize="small" /> : <ExpandMore fontSize="small" />}
          </ListItemButton>
        )}
      </Tooltip>
    </Box>
  );
};
