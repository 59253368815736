import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Divider, Grid, IconButton, InputLabel, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { DropAttachmentComponent, downloadFile } from '../../../components/basics/DropAttachmentComponent';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { useUploadFile } from '../../../hooks/useUploadFileHook';
import { LOCAL_STORAGE_KEYS } from '../../../localstorage/LocalStorage.keys';
import { Company } from '../../../models/Company';
import { SoldItemFile } from '../../../models/SoldItemFile';
import { CompaniesService } from '../../../services/Companies.service';
import { SoldItemFileService } from '../../../services/SoldItemFile.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { SoldItemFileScheme } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useSoldItemFilePageContext } from '../context/SoldItemsContext';

export const priorityOptions = [
  { id: 0, name: 'Baixa' },
  { id: 50, name: 'Média' },
  { id: 100, name: 'Alta' }
];

interface Props {
  editItem?: SoldItemFile;
  onClose: () => void;
}

export const SoldItemsFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const { accessToken } = useAuthContext();
  const { uploadFiles } = useUploadFile();
  const { fetchSoldItemFile } = useSoldItemFilePageContext();
  const [companies, setCompanies] = useState<SelectOption[]>([]);
  const [selectedCompany, setSelectedCompany] = useState<string>('');
  const [uploadedFiles, setUploadedFiles] = useState<any[]>(editItem?.sold_item_attachment ? [editItem?.sold_item_attachment] : []);
  let companies_context = localStorage.getItem(LOCAL_STORAGE_KEYS.COMPANIES_CONTEXT);

  const {
    control,
    handleSubmit,
    setError,
    unregister,
    formState: { errors }
  } = useForm<SoldItemFile>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(SoldItemFileScheme),
    defaultValues: editItem?.id
      ? {
          ...editItem
        }
      : {}
  });

  const months = [
    { id: 1, name: 'Janeiro' },
    { id: 2, name: 'Fevereiro' },
    { id: 3, name: 'Março' },
    { id: 4, name: 'Abril' },
    { id: 5, name: 'Maio' },
    { id: 6, name: 'Junho' },
    { id: 7, name: 'Julho' },
    { id: 8, name: 'Agosto' },
    { id: 9, name: 'Setembro' },
    { id: 10, name: 'Outubro' },
    { id: 11, name: 'Novembro' },
    { id: 12, name: 'Dezembro' }
  ];

  const handleCompanyChange = (value: any) => {
    const company_id = value.target.value;
    setSelectedCompany(company_id);
  };

  const fetchCompanies = async () => {
    try {
      const response = await CompaniesService.get({ is_active: true });
      if (response) {
        const result = response?.data?.results;

        const filteredCompanies = companies_context ? result.filter((company) => companies_context!.includes(company.id)) : result;

        setCompanies(
          filteredCompanies.map((company: Company) => ({
            id: company.id,
            name: company.company_name
          }))
        );
      }
    } catch (error) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar grupos de empresas',
          severity: 'error'
        })
      );
    }
  };

  const createSoldItemFile: SubmitHandler<SoldItemFile> = async (data: SoldItemFile) => {
    dispatch(showSpinner());
    try {
      const files = await uploadFiles(uploadedFiles);
      data = {
        ...data,
        record_month: data.record_month?.id,
        company: selectedCompany,
        sold_item_attachment: files[0]
      };
      await SoldItemFileService.create(data);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Importação adicionada com sucesso!',
          severity: 'success'
        })
      );
      fetchSoldItemFile();
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error?.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const formatFileSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    // @ts-ignore
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return `${Math.round(bytes / 1024 ** i)}${sizes[i]}`;
  };

  const handleUploadFiles = (files: any) => {
    const uploadedFilesSet = new Set(uploadedFiles.map((file) => file.name));
    const newFiles = files.filter((file: any) => !uploadedFilesSet.has(file.name));

    if (newFiles.length < files.length) {
      dispatch(
        showSnackbarAlert({
          message: 'Um ou mais dos arquivos selecionados já foram carregados.',
          severity: 'warning'
        })
      );
    }
    setUploadedFiles([...uploadedFiles, ...newFiles]);
  };

  const handleDeleteFile = (file: any) => {
    const index = uploadedFiles.findIndex((f) => f.name === file.name);
    const updatedUploadedFiles = uploadedFiles.filter((uploadedFile) => uploadedFile.name !== file.name);
    setUploadedFiles(updatedUploadedFiles);
  };

  const handleClose = () => {
    onClose();
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Nova'} Importação de Itens
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createSoldItemFile)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DropAttachmentComponent
                uploadedFiles={uploadedFiles}
                onUploadFile={handleUploadFiles}
                onDeleteFile={handleDeleteFile}
                hideFiles={true}
                multiple={!editItem?.id}
                accept={{
                  'application/vnd.ms-excel': ['.xls'], // para arquivos xls
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'], // para arquivos xlsx
                  'text/csv': ['.csv'] // para arquivos csv
                }}
              />
            </Grid>

            {uploadedFiles?.length > 0 && (
              <>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="company">Empresa</InputLabel>
                  <Controller
                    name="company"
                    control={control}
                    render={({ field }) => (
                      <Select {...field} style={{ width: '100%', height: 35 }} onChange={handleCompanyChange} value={selectedCompany}>
                        {companies.map((company) => (
                          <MenuItem key={company.id} value={company.id}>
                            {company.name}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="record_month">Mês</InputLabel>
                  <ControlledComboBox
                    placeholder="Mês"
                    name="record_month"
                    control={control}
                    errorMessage={errors.record_month?.message?.toString()}
                    selectOptions={months}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <InputLabel htmlFor="record_year">Ano</InputLabel>
                  <ControlledTextInput
                    placeholder="Ano"
                    name="record_year"
                    mask="9999"
                    control={control}
                    errorMessage={errors.record_year?.message?.toString()}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <Table size="small" aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Arquivo</TableCell>
                        <TableCell align="right">Ações</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {uploadedFiles.map((file: any, index: number) => (
                        <TableRow hover key={file.name}>
                          <TableCell component="th" scope="row">
                            {file.name} ({formatFileSize(file.size)})
                          </TableCell>
                          <TableCell align="right">
                            <IconButton onClick={() => handleDeleteFile(file)}>
                              <DeleteIcon />
                            </IconButton>
                            {file?.file && accessToken && (
                              <IconButton onClick={() => downloadFile(file, accessToken)}>
                                <VisibilityIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
