import { Visibility, VisibilityOff } from '@mui/icons-material';
import { BaseTextFieldProps, IconButton, TextField } from '@mui/material';
import { useState } from 'react';
import { Control, Controller, FieldError } from 'react-hook-form';
import InputMask from 'react-input-mask';
import NumberFormat from 'react-number-format';

interface Props extends BaseTextFieldProps {
  name: string;
  control: Control<any>;
  label?: string;
  placeholder?: string;
  autoFocus?: boolean;
  defaultValue?: string;
  errorMessage?: string | FieldError | undefined;
  type?: string;
  required?: boolean;
  minRows?: number;
  variant?: 'outlined' | 'standard' | 'filled';
  size?: 'small' | 'medium';
  mask?: string | RegExp | undefined;
  currency?: boolean;
  percentage?: boolean;
}

export const ControlledTextInput = ({
  name,
  control,
  label,
  placeholder,
  autoFocus,
  defaultValue,
  errorMessage,
  type = 'text',
  minRows,
  variant = 'outlined',
  size = 'small',
  mask,
  currency = false,
  percentage = false,
  helperText,
  ...rest
}: Props) => {
  const [securyEntryVisible, setSecuryEntryVisible] = useState(false);

  const _renderInput = ({ field }: any) => {
    const inputProps = {
      ...field,
      ...rest,
      fullWidth: true,
      type,
      label,
      autoFocus,
      placeholder,
      multiline: !!minRows,
      minRows: minRows || 1,
      error: !!errorMessage,
      helperText: errorMessage ? errorMessage : helperText,
      size,
      variant
    };

    if (currency) {
      return (
        <NumberFormat
          {...inputProps}
          customInput={TextField}
          thousandSeparator="."
          decimalSeparator=","
          prefix={'R$ '}
          decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={false}
        />
      );
    }
    if (percentage) {
      return (
        <NumberFormat
          {...inputProps}
          customInput={TextField}
          thousandSeparator="."
          decimalSeparator=","
          suffix={'%'}
          decimalScale={2}
          fixedDecimalScale={true}
          allowNegative={false}
        />
      );
    }

    return mask ? (
      <InputMask mask={mask} {...inputProps}>
        {(inputProps: any) => <TextField {...inputProps} />}
      </InputMask>
    ) : (
      <TextField
        {...inputProps}
        type={type === 'password' && securyEntryVisible ? 'text' : type}
        InputProps={{
          endAdornment:
            type === 'password' ? (
              <IconButton
                onClick={() => setSecuryEntryVisible(!securyEntryVisible)}
                onMouseDown={(event) => event.preventDefault()}
                edge="end"
                size="small"
              >
                {securyEntryVisible ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            ) : (
              inputProps?.InputProps?.endAdornment ?? null
            )
        }}
      />
    );
  };

  return <Controller name={name} control={control} render={_renderInput} defaultValue={defaultValue ?? ''} />;
};
