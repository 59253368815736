import { yupResolver } from '@hookform/resolvers/yup';
import { Add as AddIcon, Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  InputLabel,
  Typography,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { SubmitHandler, useFieldArray, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledComboBox } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { FormMessage } from '../../../models/FormMessage';
import { User } from '../../../models/User';
import { CompaniesService } from '../../../services/Companies.service';
import { UsersService } from '../../../services/Users.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { UserScheme } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useUsersPageContext } from '../context/UsersPageContext';

// Define as interfaces principais para tipagem dos dados
interface Company {
  id: number;
  company_name: string;
}

interface Group {
  id: number;
  name: string;
}

interface GroupedCompanies {
  group: Group;
  companies: Company[];
}

interface UserFormValues {
  username: string;
  email: string;
  first_name: string;
  last_name: string;
  password?: string;
  confirm_password?: string;
  is_active: boolean;
  access_all_companies: boolean;
  companiesGroup: GroupedCompanies[];
}

interface Props {
  editItem?: User;
  onClose: () => void;
}

// Tipagem da função groupCompanies
const groupCompanies = (companies: Company[]): GroupedCompanies[] => {
  const groupedCompanies = companies.reduce<Record<number, GroupedCompanies>>((acc, company) => {
    const { id, name } = company.group;
    if (!acc[id]) {
      acc[id] = { group: { id, name }, companies: [] };
    }
    acc[id].companies.push({ id: company.id, name: company.company_name });
    return acc;
  }, {});

  return Object.values(groupedCompanies);
};

// Tipagem da função UsersFormDialog
export const UsersFormDialog: React.FC<Props> = ({ editItem, onClose }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { fetchUsers } = useUsersPageContext();

  const [formMessage, setFormMessage] = useState<FormMessage | null>(null);
  const [groupedCompanies, setGroupedCompanies] = useState<GroupedCompanies[]>([]);

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    setValue,
    reset,
    formState: { errors }
  } = useForm<UserFormValues>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(UserScheme),
    defaultValues: editItem ?? {
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      password: '',
      confirm_password: '',
      is_active: true,
      access_all_companies: true,
      companiesGroup: []
    }
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'companiesGroup'
  });

  const watchStatus = useWatch({ control, name: 'is_active', defaultValue: getValues('is_active') });
  const watchCompaniesGroup = useWatch({ control, name: 'companiesGroup', defaultValue: getValues('companiesGroup') });
  const watchLinkCompanies = useWatch({ control, name: 'access_all_companies', defaultValue: getValues('access_all_companies') });

  const fetchGroupedCompanies = async () => {
    try {
      dispatch(showSpinner());
      const response = await CompaniesService.getAll();
      if (checkResponseStatus(response)) {
        setGroupedCompanies(groupCompanies(response.data.results));
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: 'Erro ao buscar Empresas',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleAddNewLink = () => {
    setValue('access_all_companies', false);
    append({ group: { id: 0, name: '' }, companies: [] });
  };

  const getFilteredGroupOptions = (index: number) => {
    const selectedGroups = watchCompaniesGroup.map((group, idx) => (idx !== index ? group.group.id : null)).filter((id) => id !== null);

    return groupedCompanies
      .filter((group) => !selectedGroups.includes(group.group.id))
      .map((group) => ({ id: group.group.id, name: group.group.name }));
  };

  const onSubmitForm: SubmitHandler<UserFormValues> = async (data) => {
    if (!data.password && !data.confirm_password) {
      delete data.password;
      delete data.confirm_password;
    }

    const payload = {
      ...data,
      companies: data.access_all_companies
        ? groupedCompanies.flatMap((group) => group.companies.map((company) => company.id))
        : data.companiesGroup.flatMap((group) => group.companies.map((company) => company.id))
    };

    dispatch(showSpinner());

    try {
      const response = editItem?.id ? await UsersService.update(payload) : await UsersService.create(payload);

      if (response) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso!',
            message: editItem?.id ? 'Usuário atualizado com sucesso!' : 'Usuário criado com sucesso!',
            severity: 'success'
          })
        );
      }
      fetchUsers();
      onClose();
    } catch (error: any) {
      setFormMessage({
        severity: 'error',
        message: setInputErrorsFromApi(setError, error.data)?.join(',') || ''
      });
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    fetchGroupedCompanies();
  }, []);

  useEffect(() => {
    watchCompaniesGroup?.forEach((group, index) => {
      const selectedGroup = groupedCompanies.find((g) => g.group.id === group.group.id);
      if (selectedGroup && group.companies.length === 0) {
        setValue(
          `companiesGroup[${index}].companies`,
          selectedGroup.companies.map((company) => ({ id: company.id, name: company.name }))
        );
      }
    });
  }, [watchCompaniesGroup, groupedCompanies, setValue]);

  useEffect(() => {
    if (editItem?.id && groupedCompanies.length) {
      reset({
        id: editItem.id,
        username: editItem.username,
        email: editItem.email,
        first_name: editItem.first_name,
        last_name: editItem.last_name,
        is_active: editItem.is_active,
        access_all_companies: editItem.access_all_companies,
        companiesGroup: groupCompanies(editItem.companies)
      });
    }
  }, [editItem, groupedCompanies]);

  return (
    <Dialog open fullWidth onClose={onClose}>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Usuário
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmitForm)}>
        <DialogContent>
          <Grid container spacing={2}>
            {formMessage?.message && (
              <Grid item xs={12}>
                <Alert severity={formMessage?.severity} variant="outlined">
                  {formMessage.message}
                </Alert>
              </Grid>
            )}

            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="username">Usuário</InputLabel>
              <ControlledTextInput
                autoComplete="none"
                name="username"
                placeholder="Usuário"
                control={control}
                errorMessage={errors.username?.message?.toString()}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="email">E-mail</InputLabel>
              <ControlledTextInput
                name="email"
                placeholder="E-mail"
                type="email"
                control={control}
                errorMessage={errors.email?.message?.toString()}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="first_name">Nome</InputLabel>
              <ControlledTextInput
                name="first_name"
                placeholder="Nome"
                control={control}
                errorMessage={errors.first_name?.message?.toString()}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="last_name">Sobrenome</InputLabel>
              <ControlledTextInput
                name="last_name"
                placeholder="Sobrenome"
                control={control}
                errorMessage={errors.last_name?.message?.toString()}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="password">Nova Senha</InputLabel>
              <ControlledTextInput
                autoComplete="new-password"
                name="password"
                placeholder="Nova Senha"
                type="password"
                control={control}
                errorMessage={errors.password?.message?.toString()}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel htmlFor="confirm_password">Confirmar Senha</InputLabel>
              <ControlledTextInput
                name="confirm_password"
                placeholder="Confirmar Senha"
                type="password"
                control={control}
                errorMessage={errors.confirm_password?.message?.toString()}
              />
            </Grid>

            <Grid item xs={12} md={12}>
              <Typography variant="body1">Situação:</Typography>
              <ControlledCheckbox name="is_active" label={watchStatus ? 'Ativo' : 'Inativo'} control={control} />
            </Grid>

            <Grid container item xs={12} alignItems="center">
              <Typography variant="h5" component="div" color="primary">
                Vínculos de Empresas:
              </Typography>
              <Box sx={{ ml: 2 }}>
                <ControlledCheckbox name="access_all_companies" label={'Todas Empresas'} control={control} />
              </Box>
            </Grid>

            {!watchLinkCompanies &&
              fields.map((field, index) => {
                const selectedGroup = groupedCompanies.find((group) => group.group.id === getValues(`companiesGroup[${index}].group.id`));

                return (
                  <Grid item xs={12} key={field.id}>
                    <Grid container spacing={2} marginBottom={'10px'}>
                      <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor={`companiesGroup[${index}].group`}>Selecione o Grupo de Empresa</InputLabel>
                        <ControlledComboBox
                          control={control}
                          name={`companiesGroup[${index}].group`}
                          selectOptions={getFilteredGroupOptions(index)}
                          errorMessage={errors.companiesGroup?.[index]?.group?.message?.toString()}
                          disabled={getValues(`companiesGroup[${index}].companies`).length > 0}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <InputLabel htmlFor={`companiesGroup[${index}].companies`}>Selecione a(s) Empresa(s)</InputLabel>
                        <ControlledComboBox
                          control={control}
                          name={`companiesGroup[${index}].companies`}
                          multiple
                          selectOptions={selectedGroup?.companies || []}
                          errorMessage={errors.companiesGroup?.[index]?.companies?.message?.toString()}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                      <Button variant="text" color="primary" startIcon={<CloseIcon />} onClick={() => remove(index)}>
                        Excluir Vínculo
                      </Button>
                    </Grid>
                    <Divider />
                  </Grid>
                );
              })}

            <Grid item sm={12}>
              <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleAddNewLink}>
                Adicionar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>

        <Divider />
        <DialogActions>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
