import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AlertComponent } from '../../../components/basics/AlertComponent';
import { DialogComponent } from '../../../components/basics/DialogComponent';
import { StyledTableRow } from '../../../components/basics/StyledTableRow';
import { HeadCell, Order, TableHeaderComponent } from '../../../components/basics/TableHeaderComponent';
import { TableToolbar } from '../../../components/basics/TableToolbarComponent';
import { PermissionsObj } from '../../../hooks/useCrudPermissions';
import { Company } from '../../../models/Company';
import { ProcessingService } from '../../../services/Processing.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';
import { useCompaniesPageContext } from '../context/CompaniesPageContext';

const headCells: readonly HeadCell[] = [
  {
    id: 'company_name',
    align: 'left',
    disablePadding: true,
    disableSorting: false,
    label: 'Razão Social'
  },
  {
    id: 'cnpj',
    align: 'left',
    disablePadding: true,
    disableSorting: false,
    label: 'CNPJ'
  },
  {
    id: 'is_active',
    align: 'left',
    disablePadding: false,
    disableSorting: true,
    label: 'Situação'
  },
  {
    id: 'group_name',
    align: 'left',
    disablePadding: false,
    disableSorting: false,
    label: 'Grupo de Empresa'
  },
  {
    id: 'type_name',
    align: 'left',
    disablePadding: false,
    disableSorting: false,
    label: 'Tipo'
  },
  {
    id: 'actions',
    align: 'right',
    disablePadding: false,
    disableSorting: true,
    label: 'Ações'
  }
];

interface Props {
  onView: (item: Company) => void;
  onEdit: (item: Company) => void;
  permissions: PermissionsObj;
}

export const CompaniesTable = ({ onView, onEdit, permissions }: Props) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof Company>('company_name');
  const [selected, setSelected] = useState<string[]>([]);
  const dispatch = useDispatch();

  const [deleteItem, setDeleteItem] = useState<Company | undefined>(undefined);
  const [deleteMany, setDeleteMany] = useState<boolean>(false);

  const {
    companies,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage,
    deleteCompany,
    deleteManyCompanies
  } = useCompaniesPageContext();

  const labelDisplayedRows = ({ from, to, count }) => {
    return `${from} - ${to} de ${count !== -1 ? count : `mais de ${to}`}`;
  };

  const handleDeleteItem = (item: Company) => {
    setDeleteItem(item);
  };

  const handleDeleteSelectedItems = () => {
    setDeleteMany(true);
  };

  const checkProcessing = async (id_company: number) => {
    const response = await ProcessingService.get({ company: id_company }, page, rowsPerPage);
    if (response && checkResponseStatus(response)) {
      if (response.data.results.length > 0) {
        return 0;
      }
      return 1;
    }
  };

  const handleDeleteConfirm = async () => {
    if (deleteItem?.id) {
      const can_remove = await checkProcessing(deleteItem.id);
      if (!can_remove) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro!',
            message: 'A empresa tem vínculos com arquivos SPED do sitema.',
            severity: 'error'
          })
        );
      } else {
        await deleteCompany(deleteItem.id);
      }
    } else if (deleteMany) {
      await deleteManyCompanies(selected);
    }
    setDeleteMany(false);
    setDeleteItem(undefined);
    setSelected([]);
  };

  const handleDeleteCancel = () => {
    setDeleteMany(false);
    setDeleteItem(undefined);
  };

  const handleRequestSort = (property: keyof Company) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    handleOrderChange(property, isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = companies?.results.map((item: Company) => String(item.id)) || [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    handleOnChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  function formatCNPJ(cnpj) {
    if (!cnpj) return '';

    cnpj = cnpj.replace(/[^\d]/g, '');

    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
  }

  const isSelected = (id: number) => selected.indexOf(id.toString()) != -1;

  useEffect(() => {
    setSelected([]);
  }, [companies]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableToolbar numSelected={selected?.length} onDeleteSelected={handleDeleteSelectedItems} permissions={permissions} />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
            <TableHeaderComponent
              headCells={headCells}
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={companies?.count || 0}
            />
            <TableBody>
              {companies && companies?.count > 0 ? (
                <>
                  {companies?.results?.map((row: Company, index: number) => {
                    const isItemSelected = isSelected(row?.id!);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        onClick={(event) => handleClick(event, String(row.id))}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox" align="left">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none" align="left">
                          {row.company_name}
                        </TableCell>
                        <TableCell align="left">{formatCNPJ(row.cnpj)}</TableCell>
                        <TableCell align="left">
                          <div>
                            {row.is_active ? (
                              <AlertComponent severity="success">Ativo</AlertComponent>
                            ) : (
                              <AlertComponent severity="error">Inativo</AlertComponent>
                            )}
                          </div>
                        </TableCell>
                        <TableCell align="left">{row.group.name}</TableCell>
                        <TableCell key={index} align="left">
                          <>
                            {row.types?.map((type, index) => (
                              <>{type?.name + (index == row.types?.length - 1 ? '' : ', ') || ' - '}</>
                            ))}
                          </>
                        </TableCell>
                        <TableCell align="right">
                          {permissions.hasReadPermission && (
                            <Tooltip title="Visualizar">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onView(row);
                                }}
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {permissions.hasUpdatePermission && (
                            <Tooltip title="Editar">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onEdit(row);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {permissions.hasDeletePermission && (
                            <Tooltip title="Excluir">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDeleteItem(row as Company);
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </>
              ) : (
                <StyledTableRow>
                  <TableCell align="center" colSpan={6}>
                    Nenhum registro encontrado
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {companies && companies?.count > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 25, 50]}
            component="div"
            count={companies?.count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={labelDisplayedRows}
            showFirstButton
            showLastButton
          />
        )}
        {(deleteItem || deleteMany) && (
          <DialogComponent
            title={deleteItem ? 'Excluir Empresa' : 'Excluir Empresas'}
            description={deleteItem ? 'Tem certeza que deseja excluir esta empresa?' : 'Tem certeza que deseja excluir estas empresas?'}
            onCancel={handleDeleteCancel}
            onConfirm={handleDeleteConfirm}
          />
        )}
      </Paper>
    </Box>
  );
};
