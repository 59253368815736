import { Close, KeyboardArrowUp } from '@mui/icons-material';
import { Box, IconButton, Paper, Stack, Tooltip, Typography } from '@mui/material';

interface Props {
  modalList: any[];
  onDisplayModal: (modal: any) => void;
  onCloseModal: (modal: any) => void;
}

export const MinimizedModalBar = ({ modalList, onDisplayModal, onCloseModal }: Props) => {
  return (
    <Box sx={{ position: 'fixed', bottom: 10, right: 10, zIndex: 1000 }}>
      {/* renderizar a lista de modais como botoes, com o titulo e um botao para caso o usuario queira fechar o modal */}
      <Stack columnGap={1} sx={{ flexDirection: 'row' }}>
        {modalList?.map((modal) => (
          <Stack sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', p: 1 }} component={Paper} elevation={2}>
            <Tooltip title="Restaurar" placement="top">
              <IconButton onClick={() => onDisplayModal(modal)}>
                <KeyboardArrowUp color="primary" />
              </IconButton>
            </Tooltip>
            <Box sx={{ display: 'flex', flexDirection: 'column', mx: 2 }}>
              <Typography variant="h5" color="secondary">
                {modal.title}
              </Typography>
            </Box>
            <Tooltip title="Fechar" placement="top">
              <IconButton onClick={() => onCloseModal(modal)}>
                <Close color="primary" />
              </IconButton>
            </Tooltip>
          </Stack>
        ))}
      </Stack>
    </Box>
  );
};
