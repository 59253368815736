import axios from 'axios';
import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { LOCAL_STORAGE_KEYS } from '../../../localstorage/LocalStorage.keys';
import { ApiResults } from '../../../models/ApiResults';
import { SoldItemFile } from '../../../models/SoldItemFile';
import { SoldItemFileService } from '../../../services/SoldItemFile.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface SoldItemFileFilterFields {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  owner?: number;
  record_month?: number;
  record_year?: number;
  company?: number;
  status?: string;
}

export interface SoldItemFileResult extends ApiResults {
  results: SoldItemFile[];
}

interface SoldItemFileContextProps {
  soldItemFile: SoldItemFileResult | undefined;
  fetchSoldItemFile: () => void;
  handleDownloadDefault: () => void;
  deleteSoldItemFile: (id: number) => Promise<void>;
  deleteManySoldItemFile: (items: string[]) => Promise<void>;
  filterFields: SoldItemFileFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<SoldItemFileFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const SoldItemFileContext = createContext<SoldItemFileContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const SoldItemsPageContext = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [soldItemFile, setSoldItemFile] = React.useState<SoldItemFileResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<SoldItemFileFilterFields>({});
  const { accessToken } = useAuthContext();
  const urlSpreadSheet = process.env.REACT_APP_API_URL + '/accounting-entry/download/spreadsheet-default/';

  const handleDownloadDefault = async () => {
    try {
      const response = await axios.get(urlSpreadSheet, {
        headers: {
          Authorization: `Bearer ${accessToken}`
        },
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'planilha_modelo.xlsx');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Erro ao fazer o download do arquivo:', error);
    }
  };

  const fetchSoldItemFile = async () => {
    dispatch(showSpinner());
    try {
      const payload = { ...filterFields };
      if (payload?.company) {
        payload.company = payload.company.id;
      }
      if (payload?.owner) {
        payload.owner = payload.owner.id;
      }

      let companies_context = localStorage.getItem(LOCAL_STORAGE_KEYS.COMPANIES_CONTEXT);
      companies_context = companies_context ? JSON.parse(companies_context) : [];

      const response = await SoldItemFileService.get(payload, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        const results = response.data.results;

        let filteredResults;
        if (!companies_context || companies_context.length === 0) {
          filteredResults = results;
        } else {
          filteredResults = results.filter((result) => companies_context.includes(result.company.id));
        }
        setSoldItemFile({ ...response.data, results: filteredResults });
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteSoldItemFile = async (id: number) => {
    dispatch(showSpinner());
    try {
      await SoldItemFileService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      fetchSoldItemFile();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManySoldItemFile = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await SoldItemFileService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registros excluídos!',
          severity: 'success'
        })
      );
      fetchSoldItemFile();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchSoldItemFile();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: SoldItemFileContextProps = {
    soldItemFile,
    fetchSoldItemFile,
    handleDownloadDefault,
    deleteSoldItemFile,
    deleteManySoldItemFile,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <SoldItemFileContext.Provider value={contextValue}>{children}</SoldItemFileContext.Provider>;
};

export const useSoldItemFilePageContext = () => {
  const context = useContext(SoldItemFileContext);
  if (!context) {
    throw new Error('useSoldItemFileContext must be used within a SoldItemFileProvider');
  }
  return context;
};
