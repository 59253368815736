import HandshakeOutlinedIcon from '@mui/icons-material/HandshakeOutlined';
// assets
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const ConciliationRoutes: INavGroup = {
  id: 'group-conciliation',
  title: 'Conciliação',
  icon: <HandshakeOutlinedIcon />,
  type: 'group',
  module: 'CONCILIATION',
  level: 1,
  children: [
    {
      id: 'conciliation',
      title: 'Conciliação',
      type: 'item',
      url: ROUTES.authenticated.conciliation.conciliation,
      icon: <HandshakeOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'CONCILIATION'
    }
  ]
};
