import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { AutocompleteSearch } from '../../../../../components/basics/AutocompleteSearch';
import { SelectOption } from '../../../../../components/basics/ControlledComboBox';
import { IAnalyticsDRERowBudget } from '../../../../../models/AnalyticsDRE';
import { BudgetItemReplicate } from '../../../../../models/BudgetItem';
import { CostCenter } from '../../../../../models/CostCenter';
import { CostCenterService } from '../../../../../services/CostCenter.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { BudgetItemCostCenterScheme } from '../../../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../../../utils/utils';

export interface BudgetItemCostCenter {
  cost_center?: number;
}

interface Props {
  onClose: () => void;
  item: BudgetItemReplicate;
  addLineCostCenter: (item: IAnalyticsDRERowBudget) => void;
  company: number;
}

export const BudgetItemCostCenterDialog = ({ onClose, item, addLineCostCenter, company }: Props) => {
  const dispatch = useDispatch();

  const [selectedCostCenter, setSelectedCostCenter] = useState<SelectOption>({} as SelectOption);
  const [costCenters, setCostCenters] = useState<SelectOption[]>([]);

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<BudgetItemCostCenter>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(BudgetItemCostCenterScheme),
    defaultValues: {}
  });
  const fetchCostCenters = async (watchAccountSearch?: string) => {
    try {
      const response = await CostCenterService.get({ search: watchAccountSearch, company: company });
      if (response?.data?.results) {
        const dataAccounts = response?.data?.results;
        setCostCenters(
          dataAccounts.map((account: CostCenter) => ({
            id: account.id,
            name: `${account.code} - ${account.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Centros de Custo',
          severity: 'error'
        })
      );
    }
  };
  const addCostCenter: SubmitHandler<BudgetItemCostCenter> = async (data: BudgetItemCostCenter) => {
    if (!selectedCostCenter?.id) {
      setError('cost_center', {
        type: 'manual',
        message: 'O Centro de Custo é obrigatório'
      });
      return;
    }
    dispatch(showSpinner());
    try {
      const payload = {
        id: selectedCostCenter?.id,
        description: selectedCostCenter?.name,
        level: 4,
        values: {
          year_avg: '0',
          semi_annual_avg: '0',
          quarterly_avg: '0',
          monthly_avg: '0',
          january: 0,
          february: 0,
          march: 0,
          april: 0,
          may: 0,
          june: 0,
          july: 0,
          august: 0,
          september: 0,
          october: 0,
          november: 0,
          december: 0
        },
        children: [],
        account: item?.row
      };

      addLineCostCenter(payload);
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleSelectCostCenter = (value: SelectOption) => {
    setSelectedCostCenter(value);
  };

  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Adicionar Centro de Custo
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(addCostCenter)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <InputLabel htmlFor="cost_center">Centros de Custo</InputLabel>
              <AutocompleteSearch
                value={selectedCostCenter}
                onSearch={fetchCostCenters}
                selectOptions={costCenters}
                onSelect={(event, value: SelectOption) => handleSelectCostCenter(value)}
                errorMessage={errors.cost_center?.message}
                getOptionLabel={(option) => option.name}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<ReplyAllIcon />} variant="contained" type="submit">
            Adicionar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
