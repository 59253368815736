import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { IAnalyticsFilter } from '../models/Analytics';

const AnalysisChartUrl = '/reports/charts/';

export const AnalysisChartService = {
  post: async (chart_id: number, data: IAnalyticsFilter) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${AnalysisChartUrl}${chart_id}`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
