import { lazy } from 'react';
import { Loadable } from '../components/basics/LazyLoaderComponent/Loadable';
import { AccountConfirmationPage } from '../pages/public/AuthenticationPages/AccountConfirmationPage';
import { ROUTES } from './routes';

const AuthLogin = Loadable(lazy(() => import('../pages/public/AuthenticationPages/LoginPage')));
const AuthRegister = Loadable(lazy(() => import('../pages/public/AuthenticationPages/RegisterPage')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  children: [
    {
      path: ROUTES.public.login,
      element: <AuthLogin />
    },
    {
      path: ROUTES.public.signUp,
      element: <AuthRegister />
    },
    {
      path: `${ROUTES.public.accountConfirmation}/:token`,
      element: <AccountConfirmationPage />
    }
  ]
};

export default LoginRoutes;
