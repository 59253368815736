import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SnackbarData = {
  title?: string;
  message?: string;
  severity: 'success' | 'error' | 'info' | 'warning';
  open?: boolean;
};

interface SnackbarAlertReducer {
  snackbarData: SnackbarData;
}

const initialState: SnackbarAlertReducer = {
  snackbarData: {
    title: '',
    message: '',
    severity: 'info',
    open: false
  }
};

export const snackbarAlertSlice = createSlice({
  name: 'snackbarAlertSlice',
  initialState,
  reducers: {
    showSnackbarAlert: (state, action: PayloadAction<SnackbarData>) => {
      state.snackbarData = { ...action.payload, open: true };
    },
    hideSnackbarAlert: (state) => {
      state.snackbarData = initialState.snackbarData;
    }
  }
});

export const { showSnackbarAlert, hideSnackbarAlert } = snackbarAlertSlice.actions;
export default snackbarAlertSlice.reducer;
