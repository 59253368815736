import { createSlice } from '@reduxjs/toolkit';

interface GlobalSpinnerReducer {
  loading: boolean;
  message?: string;
  fullscreen?: boolean;
  requestCount: number;
}

const initialState: GlobalSpinnerReducer = {
  loading: false,
  message: '',
  fullscreen: false,
  requestCount: 0
};

export const globalSpinnerSlice = createSlice({
  name: 'globalSpinner',
  initialState,
  reducers: {
    showSpinner: (state, action: { payload: { message?: string; fullscreen?: boolean } | undefined }) => {
      state.requestCount += 1;
      state.loading = true;
      state.message = action?.payload?.message || 'Aguarde um momento';
      state.fullscreen = action?.payload?.fullscreen || false;
    },
    hideSpinner: (state) => {
      state.requestCount -= 1;
      if (state.requestCount <= 0) {
        state.loading = false;
        state.message = '';
        state.fullscreen = false;
        state.requestCount = 0;
      }
    },
    clearSpinner: (state) => {
      state.loading = false;
      state.message = '';
      state.fullscreen = false;
      state.requestCount = 0;
    }
  }
});

export const { showSpinner, hideSpinner, clearSpinner } = globalSpinnerSlice.actions;
export default globalSpinnerSlice.reducer;
