export interface AccountingEntryFilterFields {
  account?: string;
  parent_account?: string;
  analytics?: string;
  cost_center?: string;
  entry_date_after?: string;
  entry_date_before?: string;
  is_manual?: boolean;
}

type AnalyticType = 'BP' | 'DRE' | 'NCG' | 'FC';

export const buildFiltersByAnalysisType = (
  analyticType: AnalyticType,
  level: number,
  row_id: string,
  analytic: string,
  filters: AccountingEntryFilterFields
): AccountingEntryFilterFields => {
  if (analyticType === 'BP') {
    if (level === 4) {
      filters.account = row_id;
      filters.analytics = analytic;
    } else if (level === 3) {
      filters.parent_account = row_id;
      filters.analytics = analytic;
    } else if (level <= 2) {
      filters.analytics = row_id;
    }
  }

  if (analyticType === 'DRE') {
    if (level === 4) {
      filters.cost_center = row_id;
      filters.analytics = analytic;
    } else if (level === 3) {
      filters.analytics = analytic;
      filters.account = row_id;
    } else if (level <= 2) {
      filters.analytics = row_id;
    }
  }

  if (analyticType === 'NCG' || analyticType === 'FC') {
    if (level === 3) {
      filters.account = row_id;
      filters.analytics = analytic;
    } else if (level <= 2) {
      filters.analytics = row_id;
    }
  }

  return filters;
};

export const getBackgroundColor = (row, columnId, isRetroactive, theme, type) => {
  const backgroundColors = row.totals[columnId]?.background_colors;
  const colorFromBackend = backgroundColors?.[type];

  if (colorFromBackend) return colorFromBackend;
  if (isRetroactive) return theme.palette.primary.lighter;

  return 'transparent';
};
