import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Alert, Divider, Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox } from '../../../components/basics/ControlledComboBox';
import { ControlledSwitch } from '../../../components/basics/ControlledSwitch';
import { DropAttachmentComponent, downloadFile } from '../../../components/basics/DropAttachmentComponent';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { useUploadFile } from '../../../hooks/useUploadFileHook';
import { Processing, ProcessingLot, StatusValueEnum } from '../../../models/Processing';
import { ProcessingService } from '../../../services/Processing.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { ProcessingScheme } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useProcessingPageContext } from '../context/ProcessingContext';

export const priorityOptions = [
  { id: 0, name: 'Baixa' },
  { id: 50, name: 'Média' },
  { id: 100, name: 'Alta' }
];

interface Props {
  editItem?: Processing;
  onClose: () => void;
}

export const ProcessingFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const { accessToken } = useAuthContext();
  const { uploadFiles } = useUploadFile();
  const { fetchProcessing } = useProcessingPageContext();
  const [uploadedFiles, setUploadedFiles] = useState<any[]>(editItem?.ecd_file ? [editItem?.ecd_file] : []);

  const {
    control,
    handleSubmit,
    setError,
    unregister,
    formState: { errors }
  } = useForm<ProcessingLot>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ProcessingScheme),
    defaultValues: editItem?.id
      ? {
          items: [
            {
              ...editItem,
              priority: editItem.priority < 40 ? priorityOptions[0] : editItem.priority < 60 ? priorityOptions[1] : priorityOptions[2]
            }
          ]
        }
      : {}
  });

  const createOrUpdateProcessing: SubmitHandler<ProcessingLot> = async (data: ProcessingLot) => {
    dispatch(showSpinner());
    try {
      const files = await uploadFiles(uploadedFiles);
      const filteredData = data.items.filter((d) => !!d);
      const payload = filteredData.map((d: any, index: number) => ({
        ...d,
        priority: d.priority?.id || 0,
        ecd_file: files[index]
      }));

      editItem?.id ? await ProcessingService.update(payload[0]) : await ProcessingService.create({ items: payload });
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Processamento alterado com sucesso!' : 'Processamento adicionado com sucesso!',
          severity: 'success'
        })
      );
      fetchProcessing();
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error?.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const formatFileSize = (bytes: number) => {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes === 0) return '0 Byte';
    // @ts-ignore
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    return `${Math.round(bytes / 1024 ** i)}${sizes[i]}`;
  };

  const itemOnQueue = () => {
    return !!(editItem?.id && editItem?.status === StatusValueEnum.ON_QUEUE);
  };

  const handleUploadFiles = (files: any) => {
    const uploadedFilesSet = new Set(uploadedFiles.map((file) => file.name));
    const newFiles = files.filter((file: any) => !uploadedFilesSet.has(file.name));

    if (newFiles.length < files.length) {
      dispatch(
        showSnackbarAlert({
          message: 'Um ou mais dos arquivos selecionados já foram carregados.',
          severity: 'warning'
        })
      );
    }
    setUploadedFiles([...uploadedFiles, ...newFiles]);
  };

  const handleDeleteFile = (file: any) => {
    const index = uploadedFiles.findIndex((f) => f.name === file.name);
    unregister(`items.${index}`);
    const updatedUploadedFiles = uploadedFiles.filter((uploadedFile) => uploadedFile.name !== file.name);
    setUploadedFiles(updatedUploadedFiles);
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog open={true} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Processamento
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createOrUpdateProcessing)}>
        <DialogContent>
          <Grid container spacing={2}>
            {itemOnQueue() && (
              <Grid item xs={12}>
                <Alert variant="standard" severity="warning">
                  Este item já está na fila de processamento, algumas informações não podem mais ser modificadas.
                </Alert>
              </Grid>
            )}
            <Grid item xs={12}>
              <DropAttachmentComponent
                uploadedFiles={uploadedFiles}
                onUploadFile={handleUploadFiles}
                onDeleteFile={handleDeleteFile}
                hideFiles={true}
                multiple={!editItem?.id}
                accept={{ 'text/plain': [] }}
              />
            </Grid>

            {uploadedFiles?.length > 0 && (
              <Table size="small" aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Arquivo</TableCell>
                    <TableCell>Prioridade</TableCell>
                    <TableCell align="center">Executar carga imediatamente</TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {uploadedFiles.map((file: any, index: number) => (
                    <TableRow hover key={file.name}>
                      <TableCell component="th" scope="row">
                        {file.name} ({formatFileSize(file.size)})
                      </TableCell>

                      <TableCell align="right">
                        <ControlledComboBox
                          name={`items.${index}.priority`}
                          control={control}
                          selectOptions={priorityOptions}
                          disabled={itemOnQueue()}
                          disableClearable={true}
                          defaultValue={priorityOptions[0]}
                        />
                      </TableCell>
                      <TableCell align="center">
                        <ControlledSwitch name={`items.${index}.run_immediately`} control={control} disabled={itemOnQueue()} />
                      </TableCell>
                      <TableCell align="right">
                        <IconButton onClick={() => handleDeleteFile(file)}>
                          <DeleteIcon />
                        </IconButton>
                        {file?.file && accessToken && (
                          <IconButton onClick={() => downloadFile(file, accessToken)}>
                            <VisibilityIcon />
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
