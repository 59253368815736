import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import ReplyAllIcon from '@mui/icons-material/ReplyAll';
import { Divider, Grid, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../../../components/basics/ControlledTextInput';
import { IAnalyticsDRERowBudget } from '../../../../../models/AnalyticsDRE';
import { BudgetItemReplicate } from '../../../../../models/BudgetItem';
import { BudgetItemService } from '../../../../../services/BudgetItem.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { BudgetItemReplicateScheme } from '../../../../../utils/forms/validations/formValidations';
import { findSelectedOption, monthMapping, setInputErrorsFromApi } from '../../../../../utils/utils';

interface Props {
  onClose: () => void;
  item: BudgetItemReplicate;
  onReplicate: (valuesByMonth: { [monthId: number]: string }, row: IAnalyticsDRERowBudget) => void;
  setRefresh: (value: boolean) => void;
  max_level: number;
}

const months: SelectOption[] = [
  { id: 1, name: 'Janeiro', key: 'january' },
  { id: 2, name: 'Fevereiro', key: 'february' },
  { id: 3, name: 'Março', key: 'march' },
  { id: 4, name: 'Abril', key: 'april' },
  { id: 5, name: 'Maio', key: 'may' },
  { id: 6, name: 'Junho', key: 'june' },
  { id: 7, name: 'Julho', key: 'july' },
  { id: 8, name: 'Agosto', key: 'august' },
  { id: 9, name: 'Setembro', key: 'september' },
  { id: 10, name: 'Outubro', key: 'october' },
  { id: 11, name: 'Novembro', key: 'november' },
  { id: 12, name: 'Dezembro', key: 'december' }
];

export const BudgetItemReplicateDialog = ({ onClose, item, onReplicate, setRefresh, max_level }: Props) => {
  const dispatch = useDispatch();
  const filteredMonths = months.filter((month) => month.id !== monthMapping[item?.month]);
  const MAX_LEVEL = max_level;
  const getDefaultValues = (): BudgetItemReplicate => {
    return {
      month_origin: item?.month ? findSelectedOption(months, monthMapping[item?.month]) : null,
      value: item?.value ?? '',
      months_destiny: [],
      readjustments: {}
    };
  };

  const {
    control,
    handleSubmit,
    setError,
    setValue,
    formState: { errors }
  } = useForm<BudgetItemReplicate>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(BudgetItemReplicateScheme),
    defaultValues: getDefaultValues()
  });

  const selectedMonths = useWatch({ control, name: 'months_destiny' });

  const replicateValues: SubmitHandler<BudgetItemReplicate> = async (data: BudgetItemReplicate) => {
    dispatch(showSpinner());
    try {
      const monthsIdsList = data?.months_destiny?.map((month) => month.id);
      const readjustments = selectedMonths?.reduce((acc, month) => {
        const monthId = month.id;
        acc[monthId] = data.readjustments[monthId] || '0.00';
        return acc;
      }, {});
      let payload = {};

      const replicateForRow = async (row: IAnalyticsDRERowBudget) => {
        const replicateValue = row?.values[data?.month_origin?.key];
        payload = {
          budget: item?.budget,
          month_origin: data?.month_origin?.id,
          months_destiny: monthsIdsList,
          value: replicateValue,
          readjustments,
          account: row.level === 4 ? row.parent_id || row.account?.id : row.id,
          cost_center: row.level === 4 ? row.id : undefined
        };

        const response_row = await BudgetItemService.createMany(payload);
        if (response_row) {
          const valuesByMonth = response_row.data.reduce((acc, monthData) => {
            acc[monthData.month] = monthData.value;
            return acc;
          }, {});
          if (item?.row?.level === MAX_LEVEL) {
            onReplicate(valuesByMonth, row);
          }
        }
      };

      const replicateForChildren = async (row: IAnalyticsDRERowBudget) => {
        if (row.level === MAX_LEVEL) {
          await replicateForRow(row);
        } else if (row.children) {
          for (const child of row?.children) {
            await replicateForChildren(child);
          }
        }
      };

      if (item?.row?.level === MAX_LEVEL) {
        await replicateForRow(item?.row);
      } else {
        await replicateForChildren(item?.row);
        setRefresh(true);
      }

      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Replicar Valores - {item?.row?.description}
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(replicateValues)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="month_origin">Mês Origem</InputLabel>
              <ControlledComboBox
                placeholder="Mês Origem"
                name="month_origin"
                control={control}
                errorMessage={errors.month_origin?.message?.toString()}
                selectOptions={months}
                disabled={true}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="months_destiny">Meses Destino</InputLabel>
              <ControlledComboBox
                placeholder="Meses Destino"
                name="months_destiny"
                multiple={true}
                control={control}
                allowSelectAll={true}
                errorMessage={errors.months_destiny?.message?.toString()}
                selectOptions={filteredMonths}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="value">Valor Replicado</InputLabel>
              <ControlledTextInput
                name="value"
                placeholder="Valor Replicado"
                control={control}
                disabled={true}
                errorMessage={errors.value?.message?.toString()}
              />
            </Grid>
            <Grid container item xs={12} sm={12} alignItems="center">
              <Typography variant="h5" component="div" color="primary">
                Reajustes por Mês:
              </Typography>
            </Grid>
            {selectedMonths?.map((month) => (
              <Grid key={month.id} item xs={12} md={2}>
                <InputLabel htmlFor={`readjustment_${month.name}`}>{month.name}</InputLabel>
                <ControlledTextInput
                  name={`readjustments.${month.id}`}
                  placeholder="Reajuste (%)"
                  control={control}
                  percentage={true}
                  errorMessage={errors.readjustments?.[month.id]?.message?.toString()}
                />
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<ReplyAllIcon />} variant="contained" type="submit">
            Replicar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
