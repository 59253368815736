import { Attachment } from './Attachment';
import { Company } from './Company';
import { User } from './User';

export type ProcessingStatus = 'WAITING_PROCESS' | 'ON_QUEUE' | 'PROCESSING' | 'COMPLETED' | 'CANCELED' | 'ERROR';

export interface Processing {
  id?: number;
  owner?: User | number;
  ecd_file?: Attachment | number | null;
  last_processed_block?: any;
  priority: number | any;
  reference_year: number;
  run_immediately: boolean;
  start_at?: string;
  finished_at?: string;
  company?: Company | number;
  status: ProcessingStatus;
  status_label: string;
  created_at: string;
  updated_at: string;
}

export interface ProcessingLot {
  items: Processing[];
}

export enum StatusValueEnum {
  WAITING_PROCESS = 'WAITING_PROCESS',
  ON_QUEUE = 'ON_QUEUE',
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
  ERROR = 'ERROR'
}

export enum StatusLabelEnum {
  WAITING_PROCESS = 'Aguardando Processamento',
  ON_QUEUE = 'Na Fila',
  PROCESSING = 'Processando',
  COMPLETED = 'Concluído',
  CANCELED = 'Cancelado',
  ERROR = 'Com Erro'
}

export enum StatusColorEnum {
  WAITING_PROCESS = 'info',
  ON_QUEUE = 'info',
  PROCESSING = 'info',
  COMPLETED = 'success',
  CANCELED = 'warning',
  ERROR = 'error'
}
