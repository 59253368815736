import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { CostCenter } from '../models/CostCenter';

export interface CostCentersFilterFields extends CostCenter {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

const CostCenterUrl = '/analytics/cost-centers/';

export const CostCenterService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(CostCenterUrl, { params: { page_size: 0 } });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },
  get: async (filterFields: CostCentersFilterFields, page: number = 0, page_size: number = 0) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(CostCenterUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },
  create: async (data: CostCenter) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(CostCenterUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
