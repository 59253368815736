import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';
import { Autocomplete, Box, Chip, TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuthContext } from '../../../../context/AuthContextProvider';
import { LOCAL_STORAGE_KEYS } from '../../../../localstorage/LocalStorage.keys';
import { Company } from '../../../../models/Company';
import { UserCompaniesService } from '../../../../services/UserCompanies.service';
import { clearCompanies, setCompanies } from '../../../../store/slicers/companiesContext.slicer';
import { hideSpinner, showSpinner } from '../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../store/slicers/snackbarAlert.slicer';
import { RootState } from '../../../../store/store';

const isOptionEqualToValue = (option, value) => {
  return option.group_id === value.group_id && option.company_id === value.company_id;
};

export const CompaniesContextSelectionComponent = () => {
  const dispatch = useDispatch();

  const { user } = useAuthContext();
  const { companiesIds } = useSelector((state: RootState) => state.companiesContext);

  const [companiesResult, setCompaniesResult] = useState<Company[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<Company[]>([]);
  const [allCompaniesData, setAllCompaniesData] = useState<Company[]>([]);

  const CustomAutocompleteOption = ({ option, selected, ...props }) => (
    <li
      {...props}
      style={{
        marginLeft: option.company_id ? '15px' : '0'
      }}
    >
      <Box component={DoneIcon} sx={{ width: 17, height: 17, mr: '5px', ml: '-2px', visibility: selected ? 'visible' : 'hidden' }} />
      <Box
        sx={{
          flexGrow: 1,
          '& span': {
            fontWeight: option.group_id ? 'bold' : 'normal'
          }
        }}
      >
        {option.company_id ? option.company_name : <strong>{option.group_name}</strong>}
      </Box>
      <Box component={CloseIcon} sx={{ opacity: 0.6, width: 18, height: 18, visibility: selected ? 'visible' : 'hidden' }} />
    </li>
  );

  const restoreAllCompaniesData = () => {
    setSelectedOptions([]);
    setCompaniesResult(allCompaniesData);
  };

  const handleAutocompleteChange = (event, newValue) => {
    if (newValue.length === 0) {
      restoreAllCompaniesData();
      removeContext();
    } else {
      const updatedValue = newValue.flatMap((option) => {
        if (option.company_id === null && !selectedOptions.find((selectedOption) => selectedOption.group_id === option.group_id)) {
          const groupCompanies = companiesResult.filter((company) => company.group_id === option.group_id && company.company_id !== null);
          const filteredGroupCompanies = groupCompanies.filter(
            (company) => !selectedOptions.find((selectedOption) => selectedOption.company_id === company.company_id)
          );
          return [option, ...filteredGroupCompanies];
        }
        return [option];
      });

      const isOnlyGroupSelected = updatedValue.length === 1 && updatedValue[0].company_id === null;
      if (newValue.length < selectedOptions.length && isOnlyGroupSelected) {
        restoreAllCompaniesData();
        removeContext();
      } else {
        setSelectedOptions(updatedValue);
        const selectedGroupIds = newValue.map((option) => option.group_id);

        const updatedOptions = allCompaniesData.filter((option) => selectedGroupIds.includes(option.group_id));
        setCompaniesResult(updatedOptions);
      }
    }
  };

  const transformAndSortData = (data): Company[] => {
    const sortedGroups: Company[] = [];
    const groups: Company[] = [];
    const companies: Company[] = [];

    for (const item of data) {
      groups.push({
        group_id: item.group.id,
        group_name: item.group.name,
        type_id: null,
        company_id: null,
        company_name: ''
      });
      companies.push({
        group_id: item.group?.id || null,
        group_name: item.group?.name || '',
        type_id: item.type?.id,
        company_id: item.id,
        company_name: item.company_name
      });
    }

    const uniqueGroups = groups.filter((group, index, self) => index === self.findIndex((g) => g.group_id === group.group_id));

    for (const group of uniqueGroups) {
      const groupCompanies = companies.filter((company) => company.group_id === group.group_id);
      if (groupCompanies.length > 0) {
        groupCompanies.sort((a, b) => (a.company_id || 0) - (b.company_id || 0));
        sortedGroups.push(group, ...groupCompanies);
      }
    }

    return sortedGroups;
  };

  const fetchCompanies = async () => {
    try {
      const response = await UserCompaniesService.getAll();
      if (response?.data?.results) {
        const dataCompany = response?.data?.results;
        const resultData = transformAndSortData(dataCompany);

        setCompaniesResult(resultData);
        setAllCompaniesData(resultData);
        await fetchStoredData(resultData);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error?.data?.detail || 'Erro ao buscar Empresas',
          severity: 'error'
        })
      );
    }
  };

  const fetchStoredData = async (companiesResult: Company[]) => {
    try {
      const storedUserId = localStorage.getItem(LOCAL_STORAGE_KEYS.USER_ID);
      if (Number(storedUserId) !== user?.id) {
        removeContext();
        return;
      }
      let storedOptions = localStorage.getItem(LOCAL_STORAGE_KEYS.SELECTED_COMPANIES_OPTIONS);
      storedOptions = storedOptions ? JSON.parse(storedOptions) : [];

      if (Array.isArray(storedOptions) && storedOptions?.length > 0) {
        const selectedGroupIds = storedOptions.map((option) => option.group_id);
        const updatedOptions = companiesResult.filter((option) => selectedGroupIds.includes(option.group_id));
        setCompaniesResult(updatedOptions);
        setSelectedOptions(storedOptions);
        dispatch(
          setCompanies({
            companiesIds: storedOptions.filter((option) => option.company_id !== null).map((option) => Number(option.company_id)),
            companiesData: storedOptions,
            loading: false
          })
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: 'Erro ao buscar Dados Local',
          severity: 'error'
        })
      );
    }
  };

  const removeContext = () => {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.COMPANIES_CONTEXT);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.SELECTED_COMPANIES_OPTIONS);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.USER_ID);
    dispatch(clearCompanies());
  };

  const setContext = (selectedOptionsParam: Company[]) => {
    const companyIds = selectedOptionsParam.filter((option) => option.company_id !== null).map((option) => Number(option.company_id));

    //verificar se os companyIds sao diferentes dos que já estao no contexto
    if (companyIds.length === companiesIds.length && companyIds.every((value, index) => value === companiesIds[index])) {
      return;
    }

    if (companyIds.length > 0) {
      dispatch(
        setCompanies({
          companiesIds: companyIds,
          companiesData: selectedOptionsParam,
          loading: false
        })
      );
    } else {
      dispatch(clearCompanies());
    }

    if (selectedOptions.length > 0) {
      localStorage.setItem(LOCAL_STORAGE_KEYS.COMPANIES_CONTEXT, JSON.stringify(companyIds));
      localStorage.setItem(LOCAL_STORAGE_KEYS.SELECTED_COMPANIES_OPTIONS, JSON.stringify(selectedOptionsParam));
      localStorage.setItem(LOCAL_STORAGE_KEYS.USER_ID, JSON.stringify(user?.id));
    } else {
      removeContext();
    }
  };

  useEffect(() => {
    (async () => {
      if (user?.id) {
        dispatch(showSpinner({ message: 'Aguarde um momento, carregando empresas...' }));
        await fetchCompanies();
        dispatch(hideSpinner());
      }
    })();
  }, [user]);

  return (
    <Autocomplete
      multiple
      renderOption={(props, option: Company, { selected }) => <CustomAutocompleteOption {...props} option={option} selected={selected} />}
      options={companiesResult}
      isOptionEqualToValue={isOptionEqualToValue}
      id="size-small-filled"
      value={selectedOptions}
      size="small"
      onChange={handleAutocompleteChange}
      noOptionsText="Nenhuma opção disponível"
      renderTags={(value, getTagProps) => (
        <>
          {value.slice(0, 1).map((option, index) => (
            <Chip
              style={{ maxWidth: value.length > 1 ? '60%' : '80%' }}
              label={option.company_id ? option.company_name ?? '' : option.group_name ?? ''}
              {...getTagProps({ index })}
            />
          ))}
          {value.length > 1 && <Chip label={`+ ${value.length - 1}`} />}
        </>
      )}
      getOptionLabel={(option) => (option.company_id ? option.company_name ?? '' : option.group_name ?? '')}
      disableCloseOnSelect
      sx={{
        width: 500,
        marginLeft: '10px',
        '& .MuiChip-root': {
          maxHeight: 25
        },
        '& .MuiInputLabel-root': {
          color: 'white'
        }
      }}
      renderInput={(params) => <TextField label="Selecione o Contexto" {...params} size="small" variant="filled" />}
      onClose={() => setContext(selectedOptions)}
    />
  );
};
