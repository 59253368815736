import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import LockIcon from '@mui/icons-material/Lock';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, Tooltip } from '@mui/material';

interface Props {
  row: any;
  onClick: (row: any) => void;
  type: 'edit' | 'delete' | 'view' | 'permission';
  disabled?: boolean;
}

export const IconButtonHover = ({ row, onClick, type, disabled }: Props) => {
  const _returnIcon = () => {
    switch (type) {
      case 'edit':
        return <EditIcon fontSize="small" />;
      case 'delete':
        return <DeleteIcon fontSize="small" />;
      case 'view':
        return <VisibilityIcon fontSize="small" />;
      case 'permission':
        return <LockIcon fontSize="small" />;
      default:
        return <></>;
    }
  };

  const _returnTitle = () => {
    switch (type) {
      case 'edit':
        return 'Editar';
      case 'delete':
        return 'Excluir';
      case 'view':
        return 'Ver';
      case 'permission':
        return 'Permissão';
      default:
        return '';
    }
  };

  return (
    <Tooltip title={_returnTitle()}>
      <IconButton
        onClick={(event) => {
          event.stopPropagation();
          onClick(row);
        }}
        disabled={disabled}
      >
        {_returnIcon()}
      </IconButton>
    </Tooltip>
  );
};
