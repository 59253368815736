import { AnalyticsTypePage } from '../../../sections/@analytics-type/AnalyticsTypePage';
import { AnalyticsTypeContext } from '../../../sections/@analytics-type/context/AnalyticsTypeContext';

export const AnalyticsTypeWrapper = () => {
  return (
    <AnalyticsTypeContext>
      <AnalyticsTypePage />
    </AnalyticsTypeContext>
  );
};
