import React, { forwardRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

// Material-UI components
import { ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// Redux
import { INavItem } from '../../../../../models/Menu';
import { activeNavItem } from '../../../../../store/slicers/menu.slicers';
import { RootState } from '../../../../../store/store';

// Interface para os itens de navegação
interface NavItemProps {
  item: INavItem;
  level: number;
  handlePopoverClose?: () => void;
}

const NavItem = ({ item, level, handlePopoverClose }: NavItemProps) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const { drawerOpen, openNavItem } = useSelector((state: RootState) => state.menu);

  const itemTarget = item.target ? '_blank' : '_self';

  // Determine o tipo de componente com base na propriedade 'external'
  const listItemProps = item.external
    ? { component: 'a', href: item.url, target: itemTarget }
    : { component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />) };

  const handleItemClick = () => {
    handlePopoverClose && handlePopoverClose();
    dispatch(activeNavItem({ openNavItem: item.id }));
  };

  // Verifique se o item de menu está selecionado
  const isSelected = openNavItem == item.id;

  // Define a cor do texto e do ícone selecionados
  const textColor = 'grey.700';
  const iconSelectedColor = 'primary.main';

  // Ativa o item de menu quando a página é carregada
  useEffect(() => {
    if (pathname.includes(item.url)) {
      dispatch(activeNavItem({ openNavItem: item.id }));
    }
  }, [pathname, dispatch, item]);

  return (
    <ListItemButton
      {...listItemProps}
      disabled={item.disabled}
      onClick={handleItemClick}
      selected={isSelected}
      sx={{
        height: 40,
        pl: drawerOpen ? level * 3.4 : 1.5,
        py: !drawerOpen && level === 1 ? 2 : 1,
        ...(drawerOpen && {
          '&.Mui-selected': {
            borderRight: `3px solid ${theme.palette.primary.main}`
          }
        })
      }}
    >
      {item?.icon && (
        <ListItemIcon
          sx={{
            minWidth: 32,
            color: isSelected ? iconSelectedColor : textColor,
            ...(!drawerOpen && {
              borderRadius: 1.5,
              alignItems: 'center',
              justifyContent: 'center'
            })
          }}
        >
          {React.cloneElement(item.icon, {
            fontSize: 'small'
          })}
        </ListItemIcon>
      )}
      <ListItemText
        primary={
          <Typography variant="h6" fontSize="0.8rem" color={isSelected ? iconSelectedColor : textColor}>
            {item.title}
          </Typography>
        }
      />
    </ListItemButton>
  );
};

export default NavItem;
