import BusinessIcon from '@mui/icons-material/Business';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import FactoryOutlinedIcon from '@mui/icons-material/FactoryOutlined';
import WorkspacesOutlinedIcon from '@mui/icons-material/WorkspacesOutlined';
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';

export const OrganizationsRoutes: INavGroup = {
  id: 'group-organizations',
  title: 'Organização',
  icon: <CorporateFareIcon />,
  type: 'group',
  module: 'ORGANIZATION',
  level: 1,
  children: [
    {
      id: 'CompaniesTypes',
      title: 'Tipos de Empresas',
      type: 'item',
      url: ROUTES.authenticated.organizations.companiesTypes,
      icon: <FactoryOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'COMPANY_TYPE' // Módulo para verificar permissão
    },
    {
      id: 'CompaniesGroups',
      title: 'Grupos de Empresas',
      type: 'item',
      url: ROUTES.authenticated.organizations.companiesGroups,
      icon: <WorkspacesOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'COMPANY_GROUP' // Módulo para verificar permissão
    },
    {
      id: 'Companies',
      title: 'Empresas',
      type: 'item',
      url: ROUTES.authenticated.organizations.companies,
      icon: <BusinessIcon />,
      breadcrumbs: true,
      submodule: 'COMPANY' // Módulo para verificar permissão
    }
  ]
};
