import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, Grid, Stack, Switch, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Block } from '../../../models/Block';
import { CompanyGroup } from '../../../models/CompanyGroup';
import { CompaniesGroupsService } from '../../../services/CompaniesGroups.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { hexToRgba } from '../../../utils/utils';
import { Registry } from '../../../models/Registry';
import { BlockService } from '../../../services/Block.service';
import { AccordionRow, BlockContainer, RegistryContainer } from './styles';

interface Props {
  item: CompanyGroup;
  onClose: () => void;
}

export const CompanyGroupEcdRegistry = ({ item, onClose }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const companyGroupId = item.id;
  const [blocks, setBlocks] = useState<Block[]>([]);
  const [selectedRegistries, setSelectedRegistries] = useState<Set<number>>(new Set<number>());

  const handleBlockToggle = (registries: Registry[]) => {
    setSelectedRegistries(currentRegistries => {
      if (registries.every(r => registryIsSelected(r))) {
        const registriesIds = registries.map(r => r.id)
        return new Set(Array.from(currentRegistries).filter(id => !registriesIds.includes(id)));
      } else {
        return new Set([...currentRegistries, ...registries.map(r => r.id)]);
      }
    });
  };

  const handleRegistryToggle = (registry: Registry, registries: Registry[]) => {
    if (!registryIsSelected(registry)) {
      const toSelect = getRegistryParents(registry, registries);
      setSelectedRegistries(new Set([...selectedRegistries, ...toSelect]));
    } else {
      const toUnselect = getRegistryChildren(registry, registries);
      setSelectedRegistries(new Set(Array.from(selectedRegistries).filter(id => !toUnselect.includes(id))));
    }
  };

  const getRegistryParents = (registry: Registry, registries: Registry[]): number[] => {
    let _registry = registry;
    const toSelect = [_registry.id];
    while (_registry?.parent && _registry.level > 1) {
      _registry = registries.find(r => r.id == _registry?.parent)!;
      toSelect.push(_registry.id);
    }
    return toSelect;
  }

  const getRegistryChildren = (registry: Registry, registries: Registry[]): number[] => {
    let children = [registry.id];
    registries.forEach(child => {
      if (child.parent === registry.id) {
        children = children.concat([child.id, ...getRegistryChildren(child, registries)])
      }
    })
    return children;
  }

  const fetchEcdBlock = async () => {
    try {
      const response = await BlockService.get();
      if (response?.data?.results) {
        const blockData = response?.data?.results;
        if (blockData) {
          setBlocks(blockData.filter((b: Block) => b.block !== 'A'));
        } else {
          dispatch(
            showSnackbarAlert({
              title: 'Erro',
              message: 'Erro ao buscar Registros',
              severity: 'error'
            })
          );
        }
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Registros',
          severity: 'error'
        })
      );
    }
  };

  const fetchCompanyGroupRegistries = async () => {
    try {
      const response = await CompaniesGroupsService.getOne(companyGroupId!);

      if (response?.data) {
        const registriesData = response?.data.registries;
        setSelectedRegistries(new Set([...selectedRegistries, ...registriesData]))
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao encontrar Grupo de empresa',
          severity: 'error'
        })
      );
    }
  };

  const updateEcdRegistriesCompanyGroup = async () => {
    dispatch(showSpinner());
    try {
      // @ts-ignore
      await CompaniesGroupsService.partialUpdate(companyGroupId, { "registries": Array.from(selectedRegistries) });
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registros ECD atualizados com sucesso',
          severity: 'success'
        })
      );
      onClose();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao salvar registros',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const registryIsSelected = (registry: Registry) => {
    return selectedRegistries.has(registry.id);
  }

  useEffect(() => {
    fetchCompanyGroupRegistries();
  }, [blocks]);

  useEffect(() => {
    (async () => {
      dispatch(showSpinner());
      fetchEcdBlock();
      dispatch(hideSpinner());
    })();
  }, []);

  return (
    <Dialog open={!!item} onClose={onClose} fullWidth>
      <DialogTitle variant="h4" color="primary">
        Selecione os Registros que serão Importados
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          {blocks.map((block) => (
            <Grid item xs={12} key={block.id}>
              <Box>
                <Accordion expanded={true}>
                  <AccordionSummary
                    sx={{
                      backgroundColor: hexToRgba(theme.palette.info.main, 0.2)
                    }}
                    style={{ margin: 0 }}
                  >
                    <AccordionRow>
                      <BlockContainer>
                        BLOCO {block.block} - {block.description}
                      </BlockContainer>
                    </AccordionRow>
                    <Switch
                      checked={block.registries.every(r => registryIsSelected(r))}
                      onChange={() => handleBlockToggle(block.registries)}
                    />
                  </AccordionSummary>
                  {block.registries.map((registry: Registry) => (
                    <AccordionDetails
                      key={registry.id}
                      sx={{
                        backgroundColor: hexToRgba(theme.palette.info.main, 0.1)
                      }}
                    >
                      <AccordionRow>
                        <RegistryContainer sx={{paddingLeft: `${registry.level * 5}%`}}>
                          {registry.registry} - {registry.description}
                        </RegistryContainer>
                        <Switch
                          checked={registryIsSelected(registry)}
                          onChange={() => handleRegistryToggle(registry, block.registries)}
                        />
                      </AccordionRow>
                    </AccordionDetails>
                  ))}
                </Accordion>
                <Divider />
              </Box>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
        <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={updateEcdRegistriesCompanyGroup}>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
