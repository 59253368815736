import axios, { AxiosHeaders, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'; // Certifique-se de importar corretamente
import CryptoJS from 'crypto-js';
import { LOCAL_STORAGE_KEYS } from '../localstorage/LocalStorage.keys';
import { Token } from '../models/Token';

interface InternalAxiosRequestConfig extends AxiosRequestConfig {
  headers: AxiosHeaders;
}

export const decryptUserTokens = () => {
  try {
    let tokenStorage = localStorage.getItem(LOCAL_STORAGE_KEYS.TOKEN);
    if (tokenStorage) {
      const secretKey = process.env.REACT_APP_CRYPTO_KEY;
      return JSON.parse(CryptoJS.AES.decrypt(tokenStorage, secretKey!).toString(CryptoJS.enc.Utf8)) as Token;
    }
  } catch (error) {
    throw new Error('Erro ao descriptografar o token de acesso');
  }
};

const getSelectedCompaniesFromLocalStorage = () => {
  const selectedCompanies = localStorage.getItem(LOCAL_STORAGE_KEYS.COMPANIES_CONTEXT);
  if (selectedCompanies) {
    return JSON.parse(selectedCompanies);
  }
  return [];
};

const checkCompanyWhitelist = (url: string) => {
  const whiteList = ['/accounts/', '/companies/'];
  if (whiteList.some((route) => url.includes(route))) {
    return true;
  }
  return false;
};

const createAxiosInstance = () => {
  const endpoint = process.env.REACT_APP_API_URL;

  const axiosInstance: AxiosInstance = axios.create({
    baseURL: endpoint || '',
    paramsSerializer: {
      indexes: null
    }
  });

  axiosInstance.interceptors.request.use(
    async (config: InternalAxiosRequestConfig) => {
      let tokens: Token | undefined;
      try {
        tokens = decryptUserTokens();
        if (tokens) {
          config.headers.set({
            ...config.headers,
            Authorization: `Bearer ${tokens.access_token}`
          });

          //se for uma requisicao que nao necessita do header de empresa, nao adiciona
          if (!checkCompanyWhitelist(config.url!)) {
            config.headers.set({
              'X-Company-ID': getSelectedCompaniesFromLocalStorage()
            });
          }
        }
      } catch (e) {
        console.log('Erro no interceptor', e);
      }
      return config;
    },
    (error: any) => {
      console.error('error', error);
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      if (response.status === 401 && window.location.pathname !== '/login') {
        localStorage.removeItem(LOCAL_STORAGE_KEYS.TOKEN);
        window.location.href = '/login';
        return Promise.reject(response);
      } else if (response.status >= 200 && response.status < 300) {
        return response;
      } else {
        return Promise.reject(response);
      }
    },
    (error: any) => {
      console.error('error', error);
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};

export default createAxiosInstance;
