import { ChartAccountsPage } from '../../../sections/@chart-accounts/ChartAccountPage';
import { ChartAccountsContext } from '../../../sections/@chart-accounts/context/ChartAccountsContext';

export const ChartAccountWrapper = () => {
  return (
    <ChartAccountsContext>
      <ChartAccountsPage />
    </ChartAccountsContext>
  );
};
