import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { AnalyticsAccounts } from '../../../models/AnalyticsAccounts';
import { ApiResults } from '../../../models/ApiResults';
import { ChartAccounts } from '../../../models/ChartAccounts';
import { ChartAccountsService } from '../../../services/ChartAccounts.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface ChartAccountsFilterFields extends ChartAccounts {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface AnalyticsAccountsFilterFields extends AnalyticsAccounts {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface ChartAccountsResult extends ApiResults {
  results: ChartAccounts[];
}

interface ChartAccountsContextProps {
  chartAccounts: ChartAccountsResult | undefined;
  fetchChartAccounts: () => void;
  deleteChartAccounts: (id: number) => Promise<void>;
  deleteManyChartAccounts: (items: string[]) => Promise<void>;
  filterFields: ChartAccountsFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<ChartAccountsFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const ChartAccountsContextProvider = createContext<ChartAccountsContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const ChartAccountsContext = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [chartAccounts, setChartAccounts] = React.useState<ChartAccountsResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<ChartAccountsFilterFields>({});

  const fetchChartAccounts = async () => {
    dispatch(showSpinner());
    try {
      const response = await ChartAccountsService.get(filterFields, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setChartAccounts(response.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteChartAccounts = async (id: number) => {
    dispatch(showSpinner());
    try {
      await ChartAccountsService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      fetchChartAccounts();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyChartAccounts = async (items: string[]) => {
    dispatch(showSpinner());
    debugger;
    try {
      await ChartAccountsService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registros excluídos!',
          severity: 'success'
        })
      );
      fetchChartAccounts();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchChartAccounts();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: ChartAccountsContextProps = {
    chartAccounts,
    fetchChartAccounts,
    deleteChartAccounts,
    deleteManyChartAccounts,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <ChartAccountsContextProvider.Provider value={contextValue}>{children}</ChartAccountsContextProvider.Provider>;
};

export const useChartAccountsContext = () => {
  const context = useContext(ChartAccountsContextProvider);
  if (!context) {
    throw new Error('useChartAccountsContext must be used within a ChartAccountsContextProvider');
  }
  return context;
};
