import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Company } from '../../models/Company';

interface CompaniesContextState {
  companiesIds: number[];
  companiesData: any;
  loading: boolean;
}

const initialState: CompaniesContextState = {
  companiesIds: [],
  companiesData: {},
  loading: true
};

export const companiesContextSlice = createSlice({
  name: 'companiesContext',
  initialState,
  reducers: {
    setCompanies: (state, action: PayloadAction<{ companiesIds: number[]; companiesData: Company[]; loading: boolean }>) => {
      state.companiesIds = action.payload.companiesIds;
      state.companiesData = action.payload.companiesData;
      state.loading = action.payload.loading;
    },
    clearCompanies: (state) => {
      state.companiesIds = [];
      state.loading = true;
    }
  }
});

export const { setCompanies, clearCompanies } = companiesContextSlice.actions;
export default companiesContextSlice.reducer;
