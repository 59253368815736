import { Alert, AlertTitle, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FormMessage } from '../../../models/FormMessage';
import { AuthWrapper } from '../../../sections/@auth/auth-wrappers/AuthWrapper';
import { AuthService } from '../../../services/Auth.service';

export const AccountConfirmationPage = () => {
  const navigate = useNavigate();
  const { token } = useParams();

  const [loading, setLoading] = useState(true);
  const [formState, setFormState] = useState<FormMessage>({
    severity: undefined,
    message: ''
  });

  const handleNavigateRegistration = () => {
    navigate('/login');
  };

  const handleValidateToken = async () => {
    setLoading(true);

    try {
      const response = await AuthService.accountConfirmation(String(token));
      setFormState({
        severity: 'success',
        message: response?.data?.message || 'Sua conta foi validada com sucesso!'
      });
    } catch (error: any) {
      setFormState({
        severity: 'error',
        message: error?.data?.errors || 'Algo inesperado ocorreu ao validar a sua conta, entre em contato com nosso suporte.'
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (token) {
      handleValidateToken();
    }
  }, [token]);

  return (
    <AuthWrapper>
      {loading ? (
        <Stack spacing={3} alignItems="center">
          <Typography variant="h6">Estamos validando a sua conta, por favor, aguarde.</Typography>
          <CircularProgress />
        </Stack>
      ) : (
        <Stack spacing={4}>
          <Typography variant="h4">Validação da conta</Typography>
          <Alert severity={formState.severity}>
            <AlertTitle>{formState.severity == 'success' ? 'Sucesso' : 'Erro'}</AlertTitle>
            {formState.message}
          </Alert>
          <Button fullWidth size="large" type="submit" variant="contained" onClick={handleNavigateRegistration}>
            Fazer Login
          </Button>
        </Stack>
      )}
    </AuthWrapper>
  );
};
