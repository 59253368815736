import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CircularProgress, TableHead, TablePagination } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import Tooltip from '@mui/material/Tooltip';
import { ChangeEvent, useEffect, useState } from 'react';
import { AccountEntryResult } from '../..';
import { DialogComponent } from '../../../../../../components/basics/DialogComponent';
import { StyledTableRow } from '../../../../../../components/basics/StyledTableRow';
import { TableToolbar } from '../../../../../../components/basics/TableToolbarComponent';
import { PermissionsObj } from '../../../../../../hooks/useCrudPermissions';
import { AccountingEntry } from '../../../../../../models/AccountingEntry';
import { AccountingEntryMetadata } from '../../../../../../models/AccountingEntryMetadata';
import { CompanyGroup } from '../../../../../../models/CompanyGroup';
import { formatSimpleDate, labelDisplayedRows } from '../../../../../../utils/utils';

interface Props {
  onView: (item: AccountingEntry) => void;
  onEdit: (item: AccountingEntry) => void;
  onDelete: (item: AccountingEntry) => void;
  accountingEntries: AccountEntryResult;
  accountingEntriesMetadata: AccountingEntryMetadata;
  companyGroup: CompanyGroup;
  permissions: PermissionsObj;
  isLoadingTotals: boolean;
  rowsPerPage: number;
  page: number;
  handleChangeRowsPerPage: (event: ChangeEvent<HTMLInputElement>) => void;
  handlePageChange: (event: unknown, page: number) => void;
}

export const ManualInputsTableAnalysis = ({
  onView,
  onEdit,
  onDelete,
  permissions,
  accountingEntries,
  accountingEntriesMetadata,
  companyGroup,
  rowsPerPage,
  page,
  handlePageChange,
  handleChangeRowsPerPage,
  isLoadingTotals
}: Props) => {
  const [selected, setSelected] = useState<string[]>([]);

  const [deleteItem, setDeleteItem] = useState<AccountingEntry | undefined>(undefined);
  const [deleteMany, setDeleteMany] = useState<boolean>(false);

  const handleDeleteItem = (item: AccountingEntry) => {
    setDeleteItem(item);
  };

  const handleDeleteSelectedItems = () => {
    setDeleteMany(true);
  };

  const handleDeleteConfirm = async () => {
    if (deleteItem?.manual_entry) {
      onDelete(deleteItem);
    }
    setDeleteMany(false);
    setDeleteItem(undefined);
    setSelected([]);
  };

  const handleDeleteCancel = () => {
    setDeleteMany(false);
    setDeleteItem(undefined);
  };

  useEffect(() => {
    setSelected([]);
  }, [accountingEntries]);

  return (
    <>
      <TableToolbar numSelected={selected?.length} onDeleteSelected={handleDeleteSelectedItems} permissions={permissions} />
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
          <TableHead style={{ position: 'sticky', top: 0, backgroundColor: 'white' }}>
            <StyledTableRow>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '11px' }}>Data</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '11px' }}>Empr.</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '11px' }}>Contrapartida</TableCell>
              {companyGroup.use_cost_center && <TableCell sx={{ fontWeight: 'bold', fontSize: '11px' }}>Centro de Custo</TableCell>}
              <TableCell sx={{ fontWeight: 'bold', fontSize: '11px' }}>Histórico</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '11px', textAlign: 'right' }}>Débito</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '11px', textAlign: 'right' }}>Crédito</TableCell>
              <TableCell sx={{ fontWeight: 'bold', fontSize: '11px', textAlign: 'right' }}>Ações</TableCell>
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {accountingEntries && accountingEntries?.count > 0 ? (
              <>
                {accountingEntries?.results?.map((row: AccountingEntry, index: number) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <StyledTableRow tabIndex={-1} key={row.manual_entry} sx={{ cursor: 'pointer' }}>
                      <TableCell component="td" id={labelId} scope="row" padding="normal" align="left">
                        {formatSimpleDate(row.entry_date)}
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="normal" align="left">
                        {row.company_name}
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="normal" align="left">
                        {row.counterpart_description ? row.counterpart_description : '-'}
                      </TableCell>
                      {companyGroup.use_cost_center && (
                        <TableCell component="td" id={labelId} scope="row" padding="normal" align="left">
                          {row.cost_center_description ? row.cost_center_description : '-'}
                        </TableCell>
                      )}
                      <TableCell component="td" id={labelId} scope="row" padding="normal" align="left">
                        {row.history}
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="normal" align="right">
                        {row.origin == 'D' ? row.entry_value : '-'}
                      </TableCell>
                      <TableCell component="td" id={labelId} scope="row" padding="normal" align="right">
                        {row.origin == 'C' ? row.entry_value : '-'}
                      </TableCell>
                      <TableCell align="right">
                        {permissions.hasReadPermission && (
                          <Tooltip title="Visualizar">
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                onView(row);
                              }}
                            >
                              <VisibilityIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {permissions.hasUpdatePermission && (
                          <Tooltip title="Editar">
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                onEdit(row);
                              }}
                            >
                              <EditIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                        {permissions.hasDeletePermission && (
                          <Tooltip title="Excluir">
                            <IconButton
                              onClick={(event) => {
                                event.stopPropagation();
                                handleDeleteItem(row as AccountingEntry);
                              }}
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </>
            ) : (
              <StyledTableRow>
                <TableCell align="center" colSpan={companyGroup?.use_cost_center ? 8 : 7}>
                  Nenhum registro encontrado
                </TableCell>
              </StyledTableRow>
            )}
            <StyledTableRow style={{ backgroundColor: '#d9d9d9', position: 'sticky', bottom: 0, width: '100%' }}>
              <TableCell colSpan={companyGroup?.use_cost_center ? 5 : 4} align="right" sx={{ fontWeight: 'bold' }}>
                TOTAL:
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                {isLoadingTotals ? <CircularProgress size={10} /> : accountingEntriesMetadata?.total_debit || '-'}
              </TableCell>
              <TableCell sx={{ fontWeight: 'bold', textAlign: 'right' }}>
                {isLoadingTotals ? <CircularProgress size={10} /> : accountingEntriesMetadata?.total_credit || '-'}
              </TableCell>
              <TableCell colSpan={1} />
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
      {(deleteItem || deleteMany) && (
        <DialogComponent
          title={deleteItem ? 'Excluir ajuste' : 'Excluir ajustes'}
          description={deleteItem ? 'Tem certeza que deseja excluir este ajuste?' : 'Tem certeza que deseja excluir estes ajustes?'}
          onCancel={handleDeleteCancel}
          onConfirm={handleDeleteConfirm}
        />
      )}
      {accountingEntries && accountingEntries?.count > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={accountingEntries?.count || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Linhas por página"
          labelDisplayedRows={labelDisplayedRows}
          showFirstButton
          showLastButton
        />
      )}
    </>
  );
};
