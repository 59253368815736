// assets
import HomeIcon from '@mui/icons-material/Home';
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const DashboardRoutes: INavGroup = {
  id: 'group-dashboard',
  title: 'Dashboard',
  icon: <HomeIcon />,
  type: 'group',
  level: 1,
  url: ROUTES.authenticated.dashboard.home
};
