import { BudgetPage } from '../../../sections/@budgets-page/BudgetPage';
import { BudgetContext } from '../../../sections/@budgets-page/context/BudgetContext';

export const BudgetWrapper = () => {
  return (
    <BudgetContext>
      <BudgetPage />
    </BudgetContext>
  );
};
