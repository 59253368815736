import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ChartAccounts } from '../../../models/ChartAccounts';

interface Props {
  item: ChartAccounts;
  onClose: () => void;
}

export const ChartAccountsDetailsDialog = ({ item, onClose }: Props) => {
  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Detalhes do Plano de Contas
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              codigo:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.code}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Descrição:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.description}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              BP:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.analytics_type_bp.description}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              DRE:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.analytics_type_dre.description}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              NCG:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.analytics_type_ncg.description}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              FC:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.analytics_type_fc.description}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
