import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { IAnalyticsFilter } from '../models/Analytics';

const AnalysisBPUrl = '/reports/bp';

export const AnalysisBPService = {
  post: async (data: IAnalyticsFilter) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(AnalysisBPUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  getChartData: async (filters: IAnalyticsFilter) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(`${AnalysisBPUrl}/chart`, {
        params: filters
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
