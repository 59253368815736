import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { IAnalyticsFilter } from '../models/Analytics';

const AnalysisNCGUrl = '/reports/ncg';

export const AnalysisNCGService = {
  post: async (data: IAnalyticsFilter) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(AnalysisNCGUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
