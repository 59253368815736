import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TablePagination from '@mui/material/TablePagination';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/material/styles';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { DialogComponent } from '../../../components/basics/DialogComponent';
import { StyledTableRow } from '../../../components/basics/StyledTableRow';
import { HeadCell, Order, TableHeaderComponent } from '../../../components/basics/TableHeaderComponent';
import { PermissionsObj } from '../../../hooks/useCrudPermissions';
import { PermissionGroup } from '../../../models/PermissionGroup';
import { usePermissionGroupsPageContext } from '../context/PermissionGroupsContext';

const headCells: readonly HeadCell[] = [
  {
    id: 'name',
    align: 'left',
    disablePadding: true,
    disableSorting: false,
    label: 'Nome Perfil'
  },
  {
    id: 'actions',
    align: 'right',
    disablePadding: false,
    disableSorting: true,
    label: 'Ações'
  }
];

interface TableToolbarProps {
  numSelected: number;
  onDeleteSelected?: () => void;
}

const TableToolbar = ({ numSelected, onDeleteSelected }: TableToolbarProps) => {
  return (
    <>
      {numSelected > 0 && (
        <Toolbar
          sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
              bgcolor: (theme) => alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
            })
          }}
        >
          <>
            <Typography sx={{ flex: '1 1 100%' }} color="inherit" variant="subtitle1" component="div">
              {numSelected} Selecionados
            </Typography>
            <Tooltip title="Excluir os registros selecionados na tabela">
              <Button variant="contained" startIcon={<DeleteIcon />} onClick={onDeleteSelected}>
                Excluir
              </Button>
            </Tooltip>
          </>
        </Toolbar>
      )}
    </>
  );
};

interface Props {
  permissions: PermissionsObj;
  onView: (item: PermissionGroup) => void;
  onEdit: (item: PermissionGroup) => void;
}

export const PermissionGroupsTable = ({ permissions, onView, onEdit }: Props) => {
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof PermissionGroup | string>('name' as keyof PermissionGroup);
  const [selected, setSelected] = useState<string[]>([]);

  const [deleteItem, setDeleteItem] = useState<PermissionGroup | undefined>(undefined);
  const [deleteMany, setDeleteMany] = useState<boolean>(false);

  const {
    permissionGroups,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage,
    deletePermissionGroup,
    deleteManyPermissionGroups
  } = usePermissionGroupsPageContext();

  const labelDisplayedRows = ({ from, to, count }) => {
    return `${from} - ${to} de ${count !== -1 ? count : `mais de ${to}`}`;
  };

  const handleDeleteItem = (item: PermissionGroup) => {
    setDeleteItem(item);
  };

  const handleDeleteSelectedItems = () => {
    setDeleteMany(true);
  };

  const handleDeleteConfirm = async () => {
    if (deleteItem?.id) {
      await deletePermissionGroup(deleteItem.id);
    } else if (deleteMany) {
      await deleteManyPermissionGroups(selected);
    }
    setDeleteMany(false);
    setDeleteItem(undefined);
    setSelected([]);
  };

  const handleDeleteCancel = () => {
    setDeleteMany(false);
    setDeleteItem(undefined);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    handleOrderChange(property, isAsc ? 'desc' : 'asc');
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = permissionGroups?.results.map((item: PermissionGroup) => String(item.id)) || [];
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: MouseEvent<unknown>, name: string) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    handlePageChange(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    handleOnChangeRowsPerPage(parseInt(event.target.value, 10));
  };

  const isSelected = (id: number) => selected.indexOf(id.toString()) != -1;

  useEffect(() => {
    setSelected([]);
  }, [permissionGroups]);

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableToolbar numSelected={selected?.length} onDeleteSelected={handleDeleteSelectedItems} />
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size={'small'}>
            <TableHeaderComponent
              headCells={headCells}
              numSelected={selected?.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={permissionGroups?.count || 0}
            />
            <TableBody>
              {permissionGroups && permissionGroups?.count > 0 ? (
                <>
                  {permissionGroups?.results?.map((row: PermissionGroup, index: number) => {
                    const isItemSelected = isSelected(row?.id!);
                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <StyledTableRow
                        onClick={(event) => handleClick(event, String(row.id))}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={row.id}
                        sx={{ cursor: 'pointer' }}
                      >
                        <TableCell padding="checkbox" align="left">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId
                            }}
                          />
                        </TableCell>
                        <TableCell component="th" id={labelId} scope="row" padding="none" align="left">
                          {row.name}
                        </TableCell>
                        <TableCell align="right">
                          {permissions.hasReadPermission && (
                            <Tooltip title="Visualizar">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onView(row);
                                }}
                              >
                                <VisibilityIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {permissions.hasUpdatePermission && (
                            <Tooltip title="Editar">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  onEdit(row);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                          {permissions.hasDeletePermission && (
                            <Tooltip title="Excluir">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDeleteItem(row as PermissionGroup);
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          )}
                        </TableCell>
                      </StyledTableRow>
                    );
                  })}
                </>
              ) : (
                <StyledTableRow>
                  <TableCell align="center" colSpan={6}>
                    Nenhum registro encontrado
                  </TableCell>
                </StyledTableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {permissionGroups && permissionGroups?.count > 0 && (
          <TablePagination
            rowsPerPageOptions={[5, 25, 50]}
            component="div"
            count={permissionGroups?.count || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            labelRowsPerPage="Linhas por página"
            labelDisplayedRows={labelDisplayedRows}
            showFirstButton
            showLastButton
          />
        )}
        {(deleteItem || deleteMany) && (
          <DialogComponent
            title={deleteItem ? 'Excluir Perfil' : 'Excluir Perfis'}
            description={deleteItem ? 'Tem certeza que deseja excluir este perfil?' : 'Tem certeza que deseja excluir estes perfis?'}
            onCancel={handleDeleteCancel}
            onConfirm={handleDeleteConfirm}
          />
        )}
      </Paper>
    </Box>
  );
};
