import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Theme, Typography, useMediaQuery } from '@mui/material';
import { Stack } from '@mui/system';
import { Transition } from '../TransitionComponent';

interface Props {
  open?: boolean;
  title?: string;

  description?: string;
  cancelText?: string;
  confirmText?: string;
  onCancel?: () => void;
  onConfirm?: () => void;

  children?: any;
}

export const DialogComponent = ({
  open = true,
  title,
  description,
  children,
  cancelText = 'Fechar',
  confirmText = 'Confirmar',
  onCancel,
  onConfirm
}: Props) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  return (
    <Dialog open={open} onClose={onCancel} fullScreen={isMobile} TransitionComponent={Transition}>
      <Box
        sx={{
          display: 'flex',
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <DialogTitle variant="h4" color="primary.main">
            {title}
          </DialogTitle>
          <Divider />
          <DialogContent sx={{ mt: 2 }}>
            {description ? (
              <Stack spacing={4}>
                <Typography variant="body1">{description}</Typography>
              </Stack>
            ) : (
              <>{children}</>
            )}
          </DialogContent>
          <DialogActions sx={{ p: 2 }}>
            <Stack direction="row" spacing={2}>
              {onCancel && (
                <Button startIcon={<CloseIcon />} fullWidth variant="outlined" onClick={onCancel}>
                  {cancelText}
                </Button>
              )}
              {onConfirm && (
                <Button startIcon={<CheckIcon />} fullWidth variant="contained" onClick={onConfirm}>
                  {confirmText}
                </Button>
              )}
            </Stack>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};
