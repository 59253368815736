// assets
import BuildIcon from '@mui/icons-material/Build';
import DisplaySettingsOutlinedIcon from '@mui/icons-material/DisplaySettingsOutlined';
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

export const SettingsRoutes: INavGroup = {
  id: 'group-setting',
  title: 'Configurações',
  icon: <BuildIcon />,
  type: 'group',
  module: 'SETTINGS',
  level: 1,
  children: [
    {
      id: 'dashboard-structure',
      title: 'Estrutura da Dashboard',
      type: 'item',
      url: ROUTES.authenticated.settings.dashboardStructure,
      icon: <BuildIcon />,
      breadcrumbs: true,
      submodule: 'DASHBOARD_STRUCTURE'
    },
    {
      id: 'parameters',
      title: 'Parâmetros',
      type: 'item',
      url: ROUTES.authenticated.auxiliary.parameters,
      icon: <DisplaySettingsOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'PARAMETER'
    }
  ]
};
