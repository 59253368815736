import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import { User } from '../../../models/User';
import { PermissionGroupsTab } from './tabs/PermissionGroupsTab';

interface Props {
  editItem?: User;
  onClose: () => void;
}

export const UsersPermissionDialog = ({ editItem, onClose }: Props) => {
  const theme = useTheme();

  const [tabValue, setTabValue] = useState('1');
  const handleChangeTab = (event: React.SyntheticEvent, newValue: string) => {
    setTabValue(newValue);
  };

  return (
    <Dialog open={!!editItem?.id} onClose={onClose} aria-labelledby="responsive-dialog-title" fullScreen>
      {/* Header */}
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
        <DialogTitle variant="h4" color="primary">
          Alterar Permissões do Usuário
        </DialogTitle>
        <Button variant="text" color="primary" onClick={onClose} startIcon={<CloseIcon />}>
          Fechar
        </Button>
      </Box>
      <Divider />

      {/* Content */}
      <DialogContent sx={{ flex: 1, backgroundColor: theme.palette.background.default }}>
        <PermissionGroupsTab user={editItem!} onClose={onClose} />
        {/* <TabContext value={tabValue}>
          <Box sx={{ display: 'flex', flexDirection: 'row', borderBottom: 1, borderColor: 'divider', height: '100%' }}>
            <TabList
              orientation="vertical"
              onChange={handleChangeTab}
              aria-label="basic tabs example"
              sx={{ borderRight: 1, borderColor: 'divider', minWidth: '100px' }}
            >
              <Tab label="Perfis" value="1" icon={<GroupsIcon />} />
              <Tab label="Permissões individuais" value="2" icon={<LockIcon />} />
            </TabList>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
              <TabPanel value="1" key={1}>
                <PermissionGroupsTab user={editItem!} onClose={onClose} />
              </TabPanel>
              <TabPanel value="2" key={2}>
                <ModulePermissionsTab user={editItem!} onClose={onClose} />
              </TabPanel>
            </Box>
          </Box>
        </TabContext> */}
      </DialogContent>
      <Divider />
    </Dialog>
  );
};
