import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Stack, Typography } from '@mui/material';
import axios from 'axios';
import { useDropzone } from 'react-dropzone';
import { Link } from 'react-router-dom';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { Attachment } from '../../../models/Attachment';


export const downloadFile = async (file: any, accessToken: string) => {
  if (!accessToken) {
    console.error('Token de acesso não disponível.');
    return;
  }

  try {
    const response = await axios.get(file.file, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
      responseType: 'blob'
    });

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', file.name);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  } catch (error) {
    console.error('Erro ao fazer o download do arquivo:', error);
    // Lidar com o erro, exibir uma mensagem para o usuário, etc.
  }
};

interface Props {
  uploadedFiles: any | any[];
  onUploadFile: (files: any | any[]) => void;
  onDeleteFile: (file: any) => void;
  multiple?: boolean;
  accept?: { [key: string]: string[] };
  hideFiles?: boolean;
}

export const DropAttachmentComponent = ({ uploadedFiles, onUploadFile, onDeleteFile, multiple, accept, hideFiles }: Props) => {

  const maxSizeMB = 250;
  const { accessToken } = useAuthContext();

  const renderFileItem = (file: Attachment, key?: any) => {
    if (!file) return;
    return (
      <ListItem key={key}>
        <ListItemText primary={file.name} />
        <ListItemSecondaryAction>
          <IconButton onClick={() => onDeleteFile(file)}>
            <DeleteIcon />
          </IconButton>
          {file?.file && accessToken && (
            <IconButton onClick={() => downloadFile(file, accessToken)}>
              <VisibilityIcon />
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    );
  };

  const { getRootProps, getInputProps } = useDropzone({
    multiple: multiple !== false,
    accept,
    maxSize: maxSizeMB * 1024 * 1024,
    onDrop: (acceptedFiles) => {
      onUploadFile(multiple ? acceptedFiles : acceptedFiles[0]);
    }
  });

  return (
    <>
      <div {...getRootProps()}>
        <Stack
          direction="row"
          spacing={4}
          alignItems="center"
          sx={{
            display: 'flex',
            flex: 1,
            p: 2,
            border: 'solid 0.5px #d9d9d9',
            borderRadius: '4px',
            justifyContent: 'space-evenly'
          }}
        >
          <AttachFileIcon fontSize="small" />
          <Typography variant="body2" gutterBottom>
            Arraste e largue seu(s) arquivo(s) aqui para anexar ou <Link to="#">localize em seu computador.</Link>
            <br />
            {multiple && 'Você pode anexar vários arquivos de uma vez.'}
          </Typography>
          <Typography variant="caption">Tamanho máximo de {maxSizeMB}MB</Typography>
        </Stack>
        <input {...getInputProps()} />
      </div>

      {hideFiles !== true &&
        <List>
          {multiple ? uploadedFiles?.map(renderFileItem) : renderFileItem(uploadedFiles)}
        </List>
      }
    </>
  );
};