import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableRow } from '../../../../components/basics/StyledTableRow';
import { Analytics } from '../../../../models/Analytics';
import { AnalyticsType } from '../../../../models/AnalyticsType';
import { AnalyticsService } from '../../../../services/Analytics.service';
import { hideSpinner, showSpinner } from '../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../store/slicers/snackbarAlert.slicer';
import { RootState } from '../../../../store/store';

interface AnalyticsTabProps {
  item: AnalyticsType;
  scrollToBottom: void;
}

export const AnalyticsTab = ({ item, scrollToBottom }: AnalyticsTabProps) => {
  const dispatch = useDispatch();

  const [analytics, setAnalytics] = useState<Analytics[]>([]);
  const [originalAnalytics, setOriginalAnalytics] = useState<Analytics[]>([]);
  const [editedAnalytics, setEditedAnalytics] = useState<Record<number, Analytics>>({});
  const [editModeMap, setEditModeMap] = useState<Record<number, boolean>>({});
  const { loading } = useSelector((state: RootState) => state.globalSpinner);

  const canSave = (index: number): boolean => {
    const editedAnalytic = editedAnalytics[index];
    return editedAnalytic?.code !== '' && editedAnalytic?.level !== '' && editedAnalytic?.level !== 0 && editedAnalytic?.description !== '';
  };

  const toggleEditMode = (index: number, analytic: Analytics) => {
    setEditModeMap((prevState) => {
      const newEditModeMap = { ...prevState };
      if (newEditModeMap[index]) {
        newEditModeMap[index] = false;
        if (
          !editedAnalytics[index]?.id ||
          !editedAnalytics[index]?.code ||
          !editedAnalytics[index]?.level ||
          !editedAnalytics[index]?.description
        ) {
          const updatedAnalytics = [...analytics];
          updatedAnalytics.splice(index, 1);
          setAnalytics(updatedAnalytics);
          setEditedAnalytics((prevEditedAnalytics) => {
            const newEditedAnalytics = { ...prevEditedAnalytics };
            delete newEditedAnalytics[index];
            return newEditedAnalytics;
          });
        } else {
          setEditedAnalytics({ ...originalAnalytics });
        }
      } else {
        Object.keys(newEditModeMap).forEach((key) => {
          newEditModeMap[key] = false;
        });
        newEditModeMap[index] = true;
        setEditedAnalytics((prevEditedAnalytics) => {
          const newEditedAnalytics = { ...prevEditedAnalytics };
          newEditedAnalytics[index] = { ...analytic };
          return newEditedAnalytics;
        });
        setOriginalAnalytics([...analytics]);
      }
      return newEditModeMap;
    });
  };

  const saveEditedAnalytic = async (index: number) => {
    try {
      const editedAnalytic = editedAnalytics[index];

      if (!editedAnalytic?.code || !editedAnalytic?.level || !editedAnalytic?.description) {
        dispatch(
          showSnackbarAlert({
            title: 'Aviso',
            message: 'Por favor, preencha os campos de código, nível e descrição antes de salvar.',
            severity: 'warning'
          })
        );
        return;
      }

      let response;
      if (!editedAnalytic.id) {
        editedAnalytic.analytics_type = item.id;
        response = await AnalyticsService.create(editedAnalytic);
      } else {
        if (editedAnalytic.formula == '') {
          editedAnalytic.formula = null;
        }
        if (editedAnalytic.origin == '') {
          editedAnalytic.origin = null;
        }
        editedAnalytic.analytics_type = item.id;
        response = await AnalyticsService.update(editedAnalytic);
      }
      const updatedAnalytic = response.data;
      setAnalytics((prevAnalytics) => {
        const updatedList = [...prevAnalytics];
        updatedList[index] = updatedAnalytic;

        updatedList.sort((a, b) => parseInt(a.code) - parseInt(b.code));
        return updatedList;
      });
      setEditedAnalytics((prevEditedAnalytics) => ({ ...prevEditedAnalytics, [index]: updatedAnalytic }));
      setEditModeMap((prevState) => ({ ...prevState, [index]: false }));
    } catch (error) {
      console.error('Erro ao salvar as informações editadas:', error);
    }
  };

  const handleFieldChange = (e: React.ChangeEvent<HTMLInputElement>, fieldName: string, index: number) => {
    setEditedAnalytics((prevEditedAnalytics) => ({
      ...prevEditedAnalytics,
      [index]: {
        ...prevEditedAnalytics[index],
        [fieldName]: e.target.value
      }
    }));
  };

  const fetchAnalytics = async () => {
    dispatch(showSpinner());
    try {
      const response = await AnalyticsService.get({ analytics_type_id: item.id });
      if (response?.data?.results) {
        const sortedAnalytics = response.data.results.sort((a, b) => parseInt(a.code) - parseInt(b.code));
        setAnalytics(sortedAnalytics);
        setEditedAnalytics(sortedAnalytics.reduce((acc, analytic) => ({ ...acc, [analytic.id]: { ...analytic } }), {}));
      }
    } catch (error: any) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar análises', severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleDeleteItem = async (analyticToDelete: AnalyticsType) => {
    dispatch(showSpinner());
    try {
      await AnalyticsService.delete(analyticToDelete.id);
      const updatedAnalytics = analytics.filter((analytic) => analytic.id !== analyticToDelete.id);
      setAnalytics(updatedAnalytics);
      setEditedAnalytics((prevEditedAnalytics) => {
        const { [analyticToDelete.id]: deletedAnalytic, ...rest } = prevEditedAnalytics;
        return rest;
      });
      dispatch(showSnackbarAlert({ title: 'Sucesso', message: 'Análise excluída com sucesso', severity: 'success' }));
    } catch (error) {
      console.error('Erro ao excluir análise:', error);
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao excluir análise', severity: 'error' }));
    }
    dispatch(hideSpinner());
  };

  const handleAddNewAnalytic = () => {
    const isAnyUnsaved = Object.values(editModeMap).some((editMode) => editMode);

    if (isAnyUnsaved) {
      return;
    }

    const cleanEditedAnalytic: Record<number, Analytics> = {};
    Object.keys(editedAnalytics).forEach((key) => {
      cleanEditedAnalytic[key] = { code: '', level: 0, description: '', origin: undefined, formula: undefined, analytics_type: item.id };
    });
    setEditedAnalytics(cleanEditedAnalytic);
    const newAnalytic: Analytics = {
      id: 0,
      code: '',
      level: 0,
      description: '',
      origin: undefined,
      formula: undefined,
      analytics_type: item.id
    };
    const updatedAnalytics = [...analytics, newAnalytic];
    setAnalytics(updatedAnalytics);
    const newIndex = updatedAnalytics.length - 1;
    toggleEditMode(newIndex, newAnalytic);
    setTimeout(() => {
      scrollToBottom();
    }, 0);
  };

  useEffect(() => {
    fetchAnalytics();
  }, []);

  return (
    <>
      {!loading && (
        <Box sx={{ display: 'flex', flexDirection: 'column', px: 4, pt: 1, paddingBottom: '60px' }}>
          <Box>
            <Typography variant="h4">Cadastro de Análises</Typography>
            <TableContainer component={Paper} sx={{ mt: 6, borderRadius: '4px', p: 1 }} elevation={1}>
              <Table aria-label="simple table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        Código
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        Nível
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        Descrição
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        Origem
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={2}>
                      <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                        Composição de Cálculo
                      </Typography>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {analytics.map((analytic, index) => (
                    <StyledTableRow key={index}>
                      <TableCell sx={{ width: '10%', paddingLeft: analytic.level > 1 ? 5 : 2 }}>
                        {editModeMap[index] ? (
                          <TextField
                            placeholder="Código"
                            variant="standard"
                            size="small"
                            sx={{ width: 40 }}
                            value={editedAnalytics[index]?.code || ''}
                            onChange={(e) => handleFieldChange(e, 'code', index)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                saveEditedAnalytic(index);
                              }
                            }}
                          />
                        ) : (
                          <Typography variant="h6" sx={{ fontWeight: analytic.level > 1 ? 'normal' : 'bold' }}>
                            {analytic.code}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell sx={{ width: '5%' }}>
                        {editModeMap[index] ? (
                          <TextField
                            placeholder="Niv."
                            variant="standard"
                            size="small"
                            sx={{ width: 20 }}
                            value={editedAnalytics[index]?.level || ''}
                            onChange={(e) => handleFieldChange(e, 'level', index)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                saveEditedAnalytic(index);
                              }
                            }}
                          />
                        ) : (
                          <Typography variant="h6" sx={{ fontWeight: analytic.level > 1 ? 'normal' : 'bold' }}>
                            {analytic.level}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell sx={{ width: '40%', paddingLeft: analytic.level > 1 ? 5 : 2 }}>
                        {editModeMap[index] ? (
                          <TextField
                            placeholder="Descrição"
                            variant="standard"
                            size="small"
                            value={editedAnalytics[index]?.description || ''}
                            onChange={(e) => handleFieldChange(e, 'description', index)}
                            sx={{ width: 300 }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                saveEditedAnalytic(index);
                              }
                            }}
                          />
                        ) : (
                          <Typography variant="h6" sx={{ fontWeight: analytic.level > 1 ? 'normal' : 'bold' }}>
                            {analytic.description}
                          </Typography>
                        )}
                      </TableCell>
                      <TableCell sx={{ width: '5%' }}>
                        {editModeMap[index] ? (
                          <TextField
                            placeholder="Orig."
                            variant="standard"
                            size="small"
                            value={editedAnalytics[index]?.origin || ''}
                            sx={{ width: 20 }}
                            onChange={(e) => handleFieldChange(e, 'origin', index)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                saveEditedAnalytic(index);
                              }
                            }}
                          />
                        ) : (
                          <Typography variant="h6">{analytic.origin}</Typography>
                        )}
                      </TableCell>
                      <TableCell sx={{ width: '30%' }}>
                        {editModeMap[index] ? (
                          <TextField
                            placeholder="Fórmula"
                            variant="standard"
                            size="small"
                            value={editedAnalytics[index]?.formula || undefined}
                            sx={{ width: 300 }}
                            onChange={(e) => handleFieldChange(e, 'formula', index)}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                saveEditedAnalytic(index);
                              }
                            }}
                          />
                        ) : (
                          <Typography variant="h6">{analytic.formula}</Typography>
                        )}
                      </TableCell>
                      <TableCell align="right">
                        {editModeMap[index] ? (
                          <>
                            <Tooltip title="Salvar">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  if (!canSave(index)) {
                                    dispatch(
                                      showSnackbarAlert({
                                        title: 'Aviso',
                                        message: 'Por favor, preencha os campos de código, nível e descrição antes de salvar.',
                                        severity: 'warning'
                                      })
                                    );
                                  } else {
                                    saveEditedAnalytic(index);
                                    toggleEditMode(index, analytic);
                                  }
                                }}
                              >
                                <CheckIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Cancelar">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  toggleEditMode(index, analytic);
                                }}
                              >
                                <CloseIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </>
                        ) : (
                          <>
                            <Tooltip title="Editar">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  toggleEditMode(index, analytic);
                                }}
                              >
                                <EditIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title="Excluir">
                              <IconButton
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleDeleteItem(analytic);
                                }}
                              >
                                <DeleteIcon fontSize="small" />
                              </IconButton>
                            </Tooltip>
                          </>
                        )}
                      </TableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Divider />
          </Box>
          <Box
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              width: '100%',
              backgroundColor: 'white',
              padding: 2,
              borderTop: '1px solid #e0e0e0',
              zIndex: 1,
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleAddNewAnalytic}>
              Adicionar Análise
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default AnalyticsTab;
