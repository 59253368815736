import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { IAnalyticsFilter } from '../models/Analytics';
import { IAnalyticsDREFilter } from '../models/AnalyticsDRE';

const AnalysisDREUrl = '/reports/dre';

export const AnalysisDREService = {
  post: async (data: IAnalyticsFilter) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(AnalysisDREUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  getChartData: async (filters: IAnalyticsDREFilter) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(`${AnalysisDREUrl}/chart`, {
        params: filters
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
