import CloseIcon from '@mui/icons-material/Close';
import { Alert, Divider, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Company } from '../../../models/Company';

interface Props {
  item: Company;
  onClose: () => void;
}

export const CompanyDetailsDialog = ({ item, onClose }: Props) => {
  function formatCNPJ(cnpj) {
    if (!cnpj) return '';

    cnpj = cnpj.replace(/[^\d]/g, '');

    return cnpj.replace(/^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/, '$1.$2.$3/$4-$5');
  }

  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Detalhes da Empresa
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Razão Social:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.company_name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Nome Fantasia:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.trade_name ? item.trade_name : '-'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              CNPJ:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {formatCNPJ(item.cnpj)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Grupo de Empresa:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.group.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Tipos:
            </Typography>
            <ul>
              {item.types?.map((d) => (
                <li key={d.id}>{d.name}</li>
              ))}
            </ul>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Situação:
            </Typography>
            <>{item.is_active ? <Alert severity="success">Ativo</Alert> : <Alert severity="error">Inativo</Alert>}</>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
