import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { LOCAL_STORAGE_KEYS } from '../../../localstorage/LocalStorage.keys';
import { ApiResults } from '../../../models/ApiResults';
import { Processing } from '../../../models/Processing';
import { ProcessingService } from '../../../services/Processing.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface ProcessingFilterFields {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  owner?: number;
  run_immediately?: boolean;
  company?: number;
  status?: string;
}

export interface ProcessingResult extends ApiResults {
  results: Processing[];
}

interface ProcessingContextProps {
  processing: ProcessingResult | undefined;
  fetchProcessing: () => void;
  deleteProcessing: (id: number) => Promise<void>;
  deleteManyProcessing: (items: string[]) => Promise<void>;
  filterFields: ProcessingFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<ProcessingFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const ProcessingContext = createContext<ProcessingContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const ProcessingPageContext = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [processing, setProcessing] = React.useState<ProcessingResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<ProcessingFilterFields>({});

  const fetchProcessing = async () => {
    dispatch(showSpinner());
    try {
      const payload = { ...filterFields };
      if (payload?.company) {
        payload.company = payload.company.id;
      }
      if (payload?.owner) {
        payload.owner = payload.owner.id;
      }

      let companies_context = localStorage.getItem(LOCAL_STORAGE_KEYS.COMPANIES_CONTEXT);
      companies_context = companies_context ? JSON.parse(companies_context) : [];

      const response = await ProcessingService.get(payload, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        const results = response.data.results;

        let filteredResults;
        if (!companies_context || companies_context.length === 0) {
          filteredResults = results;
        } else {
          filteredResults = results.filter((result) => companies_context.includes(result.company.id));
        }
        setProcessing({ ...response.data, results: filteredResults });
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteProcessing = async (id: number) => {
    dispatch(showSpinner());
    try {
      await ProcessingService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      fetchProcessing();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyProcessing = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await ProcessingService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registros excluídos!',
          severity: 'success'
        })
      );
      fetchProcessing();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchProcessing();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: ProcessingContextProps = {
    processing,
    fetchProcessing,
    deleteProcessing,
    deleteManyProcessing,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <ProcessingContext.Provider value={contextValue}>{children}</ProcessingContext.Provider>;
};

export const useProcessingPageContext = () => {
  const context = useContext(ProcessingContext);
  if (!context) {
    throw new Error('useProcessingContext must be used within a ProcessingProvider');
  }
  return context;
};
