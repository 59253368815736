import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MenuState {
  openNavItem: string;
  openNavGroup: string[];
  navGroupAnchorEl: null | Element;
  drawerOpen: boolean;
  componentDrawerOpen: boolean;
}

const initialState: MenuState = {
  openNavItem: 'inicio',
  openNavGroup: ['group-dashboard'],
  navGroupAnchorEl: null,
  drawerOpen: false,
  componentDrawerOpen: false
};

const menu = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    activeNavItem(state, action: PayloadAction<{ openNavItem: string }>) {
      state.openNavItem = action.payload.openNavItem;
    },

    activeNavGroup(state, action: PayloadAction<{ openNavGroup: string }>) {
      // se o grupo existir no state, remove
      if (state.openNavGroup.includes(action.payload.openNavGroup)) {
        state.openNavGroup = state.openNavGroup.filter((item) => item !== action.payload.openNavGroup);
      } else {
        // se o grupo não existir no state, adiciona
        state.openNavGroup = [...state.openNavGroup, action.payload.openNavGroup];
      }
    },

    openDrawer(state, action: PayloadAction<{ drawerOpen: boolean }>) {
      state.drawerOpen = action.payload.drawerOpen;
    },

    openComponentDrawer(state, action: PayloadAction<{ componentDrawerOpen: boolean }>) {
      state.componentDrawerOpen = action.payload.componentDrawerOpen;
    }
  }
});

export const { activeNavItem, activeNavGroup, openDrawer, openComponentDrawer } = menu.actions;
export default menu.reducer;
