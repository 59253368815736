import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

import { debounce } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { useCrudPermissions } from '../../../hooks/useCrudPermissions';
import { AnalyticsType } from '../../../models/AnalyticsType';
import { AnalyticsDialog } from '../AnalyticsFormDialog';
import { AnalyticsTypeDetailsDialog } from '../AnalyticsTypeDetailsDialog';
import { AnalyticsTypeFilterDialog } from '../AnalyticsTypeFilterDialog';
import { AnalyticsTypeFormDialog } from '../AnalyticsTypeFormDialog';
import { AnalyticsTypeTable } from '../AnalyticsTypeTable';
import { useAnalyticsTypeContext } from '../context/AnalyticsTypeContext';
import { ButtonsContainer } from './styles';

export const AnalyticsTypePage = () => {
  const { permissions } = useCrudPermissions({ submodule: 'ANALYTICS' });

  const [showFilter, setShowFilter] = useState(false);
  const [editItem, setEditItem] = useState<AnalyticsType | undefined>(undefined);
  const [editAnalytics, setEditAnalytics] = useState<AnalyticsType | undefined>(undefined);
  const [viewItem, setViewItem] = useState<AnalyticsType | undefined>(undefined);
  const [newItem, setNewItem] = useState(false);
  const [numFilters, setNumFilters] = useState<number>(0);

  const { control } = useForm();
  const watchSearch = useWatch({
    control,
    name: 'search'
  });

  const { filterFields, setFilterFields } = useAnalyticsTypeContext();

  const handleCloseDialogs = () => {
    setShowFilter(false);
    setNewItem(false);
    setEditItem(undefined);
    setEditAnalytics(undefined);
    setViewItem(undefined);
  };

  const handleViewItem = (item: AnalyticsType) => {
    setViewItem(item);
  };

  const handleEditItem = (item: AnalyticsType) => {
    setEditItem(item);
  };

  const handleEditAnalytics = (item: AnalyticsType) => {
    setEditAnalytics(item);
  };

  const handleAddAnalyticType = () => {
    setNewItem(true);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  useEffect(() => {
    if (watchSearch) {
      const debounced = debounce(() => {
        setFilterFields({
          ...filterFields,
          search: watchSearch
        });
      }, 500);
      debounced();
      return debounced.cancel;
    } else {
      setFilterFields({
        ...filterFields,
        search: undefined
      });
    }
  }, [watchSearch]);

  return (
    <>
      <ButtonsContainer>
        {permissions.hasReadPermission && (
          <>
            <Box mr={1}>
              <ControlledTextInput
                control={control}
                name="search"
                label="Buscar Tipos de Analises"
                placeholder="Digite para Pesquisar"
                sx={{ mr: 1 }}
              />
            </Box>
            <Tooltip title="Filtros">
              <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} sx={{ mr: 1 }} onClick={handleShowFilter}>
                Filtros {numFilters > 0 && `(${numFilters})`}
              </Button>
            </Tooltip>
          </>
        )}
        {permissions.hasCreatePermission && (
          <Tooltip title="Criar Tipo de Analise">
            <Button startIcon={<AddIcon />} variant="contained" onClick={handleAddAnalyticType}>
              Adicionar
            </Button>
          </Tooltip>
        )}
      </ButtonsContainer>
      <AnalyticsTypeTable onView={handleViewItem} onEdit={handleEditItem} onEditAnalytics={handleEditAnalytics} permissions={permissions} />
      {viewItem && <AnalyticsTypeDetailsDialog item={viewItem} onClose={handleCloseDialogs} />}
      {showFilter && <AnalyticsTypeFilterDialog onClose={handleCloseDialogs} setNumFilters={setNumFilters} />}
      {editAnalytics && <AnalyticsDialog editItem={editAnalytics} onClose={handleCloseDialogs} />}
      {(newItem || editItem) && <AnalyticsTypeFormDialog editItem={editItem} onClose={handleCloseDialogs} />}
    </>
  );
};
