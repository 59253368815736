import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { AnalyticsAccountReplicate } from '../models/AnalyticsAccountReplicate';
const AnalyticsAccountReplicateUrl = '/analytics/replicate-accounts/';

export const AnalyticsAccountReplicateService = {
  create: async (data: AnalyticsAccountReplicate) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(AnalyticsAccountReplicateUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
