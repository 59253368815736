import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { CompanyGroup } from '../../../models/CompanyGroup';
import { CompanyType } from '../../../models/CompanyType';
import { CompaniesGroupsService } from '../../../services/CompaniesGroups.service';
import { CompaniesTypesService } from '../../../services/CompaniesTypes.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { findSelectedOption } from '../../../utils/utils';
import { CompaniesFilterFields, useCompaniesPageContext } from '../context/CompaniesPageContext';

interface Props {
  onClose: () => void;
  setNumFilters: (numFilters: number) => void;
}

const filterStatusOptions: SelectOption[] = [
  { id: null, name: 'Todos' },
  { id: true, name: 'Ativo' },
  { id: false, name: 'Inativo' }
];

const getDefaultValues = (filter?: CompaniesFilterFields): CompaniesFilterFields => {
  return {
    company_name: filter?.company_name ?? '',
    trade_name: filter?.trade_name ?? '',
    is_active: findSelectedOption(filterStatusOptions, filter?.is_active),
    group: filter?.group,
    type: filter?.type
  };
};

export const CompaniesFilterDialog = ({ onClose, setNumFilters }: Props) => {
  const { filterFields, setFilterFields } = useCompaniesPageContext();
  const [groups, setGroups] = useState<SelectOption[]>([]);
  const [types, setTypes] = useState<SelectOption[]>([]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<CompaniesFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const updateNumFilters = (data: CompaniesFilterFields) => {
    let count = 0;
    if (data.company_name) count++;
    if (data.trade_name) count++;
    if (data.is_active && data.is_active.id !== null) count++;
    if (data.type && data.type.id !== null) count++;
    if (data.group && data.group.id !== null) count++;
    setNumFilters(count);
  };

  const onSubmit: SubmitHandler<CompaniesFilterFields> = async (data: CompaniesFilterFields) => {
    const payload: any = { ...data };

    if (data.is_active?.id != null) {
      payload.is_active = data.is_active.id;
    } else {
      delete payload.is_active;
    }

    setFilterFields({ ...payload } as CompaniesFilterFields);
    updateNumFilters(data);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as CompaniesFilterFields);
    setNumFilters(0);
    onClose();
  };

  const fetchGroups = async () => {
    try {
      const response = await CompaniesGroupsService.get({ is_active: true });
      if (response) {
        const users = response?.data?.results;
        setGroups(
          users.map((group: CompanyGroup) => ({
            id: group.id,
            name: group.name
          }))
        );
      }
    } catch (error) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar grupos',
          severity: 'error'
        })
      );
    }
  };

  const fetchTypes = async () => {
    try {
      const response = await CompaniesTypesService.get({ is_active: true });
      if (response) {
        const result = response?.data?.results;
        setTypes(
          result.map((type: CompanyType) => ({
            id: type.id,
            name: type.name
          }))
        );
      }
    } catch (error) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar tipos de empresas',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    fetchGroups();
    fetchTypes();
  }, []);

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="company_name">Razão Social</InputLabel>
              <ControlledTextInput
                name="company_name"
                placeholder="Razão Social"
                control={control}
                errorMessage={errors.company_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="trade_name">Nome Fantasia</InputLabel>
              <ControlledTextInput
                name="trade_name"
                placeholder="Nome Fantasia"
                control={control}
                errorMessage={errors.trade_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="group">Perfil</InputLabel>
              <ControlledComboBox name="group" control={control} errorMessage={errors.group?.message?.toString()} selectOptions={groups} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="type">Tipo</InputLabel>
              <ControlledComboBox name="type" control={control} errorMessage={errors.type?.message?.toString()} selectOptions={types} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="is_active">Situação</InputLabel>
              <ControlledComboBox
                name="is_active"
                placeholder="Situação"
                control={control}
                errorMessage={errors.is_active?.message?.toString()}
                selectOptions={[
                  { id: null, name: 'Todos' },
                  { id: true, name: 'Ativo' },
                  { id: false, name: 'Inativo' }
                ]}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
