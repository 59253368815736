import { ProcessingPage } from "../../../sections/@processing/ProcessingPage";
import { ProcessingPageContext } from "../../../sections/@processing/context/ProcessingContext";

export const ProcessingWrapper = () => {
  return (
    <ProcessingPageContext>
      <ProcessingPage />
    </ProcessingPageContext>
  );
};
