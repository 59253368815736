import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { CompanyType } from '../../../models/CompanyType';
import { CompaniesTypesService } from '../../../services/CompaniesTypes.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface CompaniesTypesFilterFields extends CompanyType {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  is_active?: any;
}

export interface CompaniesTypesResult extends ApiResults {
  results: CompanyType[];
}

interface CompaniesTypesContextProps {
  companiesTypes: CompaniesTypesResult | undefined;
  fetchCompaniesTypes: () => void;
  deleteCompanyType: (id: number) => Promise<void>;
  deleteManyCompaniesTypes: (items: string[]) => Promise<void>;
  filterFields: CompaniesTypesFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<CompaniesTypesFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const CompaniesTypesContextProvider = createContext<CompaniesTypesContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const CompaniesTypesContext = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [companiesTypes, setCompaniesTypes] = React.useState<CompaniesTypesResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<CompaniesTypesFilterFields>({});

  const fetchCompaniesTypes = async () => {
    dispatch(showSpinner());
    try {
      const response = await CompaniesTypesService.get(filterFields, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setCompaniesTypes(response.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteCompanyType = async (id: number) => {
    dispatch(showSpinner());
    try {
      await CompaniesTypesService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      fetchCompaniesTypes();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyCompaniesTypes = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await CompaniesTypesService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registros excluídos!',
          severity: 'success'
        })
      );
      fetchCompaniesTypes();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchCompaniesTypes();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: CompaniesTypesContextProps = {
    companiesTypes,
    fetchCompaniesTypes,
    deleteCompanyType,
    deleteManyCompaniesTypes,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <CompaniesTypesContextProvider.Provider value={contextValue}>{children}</CompaniesTypesContextProvider.Provider>;
};

export const useCompaniesTypesContext = () => {
  const context = useContext(CompaniesTypesContextProvider);
  if (!context) {
    throw new Error('useCompaniesTypesContext must be used within a CompaniesTypesProvider');
  }
  return context;
};
