import { Container, useTheme } from '@mui/material';
import { TabsComponent } from '../../../sections/@user-profile/TabsComponent';
import { UserCoverComponent } from '../../../sections/@user-profile/UserCoverComponent';
import { UserTabsContextProvider } from '../../../sections/@user-profile/context/UserTabsContext';

export const ProfilePageWrapper = () => {
  const theme = useTheme();

  return (
    <Container>
      <UserTabsContextProvider>
        <UserCoverComponent />
        <TabsComponent />
      </UserTabsContextProvider>
    </Container>
  );
};
