import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { Company } from '../../../models/Company';
import { StatusLabelEnum } from '../../../models/SoldItemFile';
import { User, getUserName } from '../../../models/User';
import { CompaniesService } from '../../../services/Companies.service';
import { UsersService } from '../../../services/Users.service';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { findSelectedOption } from '../../../utils/utils';
import { SoldItemFileFilterFields, useSoldItemFilePageContext } from '../context/SoldItemsContext';

interface Props {
  onClose: () => void;
  setNumFilters: (numFilters: number) => void;
}

const filterStatusOptions: SelectOption[] = [
  { id: undefined, name: 'Todos' },
  ...Object.entries(StatusLabelEnum).map(([key, value]) => ({ id: key, name: value }))
];

const getDefaultValues = (filter?: SoldItemFileFilterFields): SoldItemFileFilterFields => {
  return {
    owner: filter?.owner,
    company: filter?.company,
    status: findSelectedOption(filterStatusOptions, filter?.status)
  };
};

export const SoldItemsFilterDialog = ({ onClose, setNumFilters }: Props) => {
  const { filterFields, setFilterFields } = useSoldItemFilePageContext();
  const [companies, setCompanies] = useState<SelectOption[]>([]);
  const [users, setUsers] = useState<SelectOption[]>([]);

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<SoldItemFileFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const updateNumFilters = (data: UsersFilterFields) => {
    let count = 0;
    if (data.company) count++;
    if (data.owner) count++;
    if (data.status && data.status.id !== undefined) count++;
    setNumFilters(count);
  };

  const onSubmit: SubmitHandler<SoldItemFileFilterFields> = async (data: SoldItemFileFilterFields) => {
    const payload: any = { ...data };

    if (data.status?.id != undefined) {
      payload.status = data.status.id;
    } else {
      delete payload.status;
    }

    setFilterFields({ ...payload } as SoldItemFileFilterFields);
    updateNumFilters(data);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as SoldItemFileFilterFields);
    setNumFilters(0);
    onClose();
  };

  const fetchCompanies = async () => {
    try {
      const response = await CompaniesService.get({ page_size: 0 });
      if (response) {
        setCompanies(
          response.data.results.map((company: Company) => ({
            id: company.id,
            name: company.company_name
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar empresas',
          severity: 'error'
        })
      );
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await UsersService.get({ page_size: 0 });
      if (response) {
        setUsers(
          response.data.results.map((user: User) => ({
            id: user.id,
            name: getUserName(user)
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar usuários',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    fetchCompanies();
    fetchUsers();
  }, []);

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="company">Empresa</InputLabel>
              <ControlledComboBox
                name="company"
                placeholder="Empresa"
                control={control}
                errorMessage={errors.company?.message?.toString()}
                selectOptions={companies}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="owner">Usuário</InputLabel>
              <ControlledComboBox
                name="owner"
                placeholder="Usuário responsável pelo cadastro"
                control={control}
                errorMessage={errors.owner?.message?.toString()}
                selectOptions={users}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="status">Status</InputLabel>
              <ControlledComboBox
                name="status"
                placeholder="Situação"
                control={control}
                errorMessage={errors.status?.message?.toString()}
                selectOptions={filterStatusOptions}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(onSubmit)}>
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
function dispatch(arg0: any) {
  throw new Error('Function not implemented.');
}
