import { AxiosResponse, isAxiosError } from 'axios';
import { default as createAxiosInstance } from '../middlewares/axios.interceptors';
import { LoginFormData } from '../sections/@auth/auth-forms/AuthLogin';
import { User } from '../models/User';

const accountUrl = '/accounts/';
const loginUrl = `${accountUrl}auth/`;
const accountConfirmationUrl = '/auth/ativacao-conta';
const forgotPasswordUrl = '/auth/forgot-password';
const forgotPasswordChangeUrl = forgotPasswordUrl;
const editPasswordUrl = '/accounts/edit-password/';
const validateCpfUrl = '/auth/verifica-cpf';

export const AuthService = {
  doLogin: async (data: LoginFormData) => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.post(loginUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  signUp: async (data: any) => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.post(accountUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  accountConfirmation: async (token: string) => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.post(`${accountConfirmationUrl}`, { token });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  requestPasswordChange: async (email: string) => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.post(forgotPasswordChangeUrl, { email });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  changePasswordValidateToken: async (token: string) => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.get(`${forgotPasswordUrl}/${token}`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  changePassword: async (token: string, data: { password: string; password_confirmation: string }) => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.post(`${forgotPasswordUrl}/${token}`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  validateCpf: async (cpf: string) => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.get(validateCpfUrl, {
        params: {
          cpf
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  getUserSessionData: async () => {
    const axiosInstance = createAxiosInstance();

    try {
      return await axiosInstance.get(accountUrl);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: User) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(accountUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  editPassword: async (data: any) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(editPasswordUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
