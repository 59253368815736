// material-ui
import { Box, IconButton, Toolbar, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

// project import

// assets
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import { CompaniesContextSelectionComponent } from './CompaniesContextSelectionComponent';
import { Profile } from './Profile';
import { AppBarStyled } from './styles';

// ==============================|| MAIN LAYOUT - HEADER ||============================== //
interface Props {
  open: boolean;
  handleDrawerToggle: () => void;
}

export const Header = ({ open, handleDrawerToggle }: Props) => {
  const theme = useTheme();
  const ismobile = useMediaQuery(theme.breakpoints.down('md'));

  const mainHeader = (
    <Toolbar sx={{ backgroundColor: theme.palette.primary.main }}>
      <Box sx={{ display: 'flex', flex: 1, justifyContent: 'flex-start' }}>
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            disableRipple
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            edge="start"
            color="secondary"
            size="large"
            sx={{ color: 'common.white' }}
          >
            {!open ? <MenuIcon fontSize="small" /> : <MenuOpenIcon fontSize="small" />}
          </IconButton>
          <CompaniesContextSelectionComponent />
        </Box>
        <Box style={{ marginLeft: 'auto' }}>
          <Profile />
        </Box>
      </Box>
    </Toolbar>
  );

  //ESTILOS DA BARRA DO HEADER
  const appBar = {
    position: 'fixed',
    color: 'inherit',
    sx: {
      boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)'
    }
  };

  return (
    <AppBarStyled open={open} ismobile={ismobile ? true : undefined} {...appBar}>
      {mainHeader}
    </AppBarStyled>
  );
};
