import CloseIcon from '@mui/icons-material/Close';
import DownloadIcon from '@mui/icons-material/Download';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { Divider, Grid, IconButton, Paper, Popper, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { capitalize } from 'lodash';
import React from 'react';
import { useAuthContext } from '../../../context/AuthContextProvider';
import createAxiosInstance from '../../../middlewares/axios.interceptors';
import { Attachment } from '../../../models/Attachment';
import { Company } from '../../../models/Company';
import { MonthNameEnum, SoldItemFile } from '../../../models/SoldItemFile';
import { User, getUserName } from '../../../models/User';
import { formatDateTime } from '../../../utils/utils';

interface Props {
  item: SoldItemFile;
  onClose: () => void;
}

export const SoldItemsDetailsDialog = ({ item, onClose }: Props) => {
  const { accessToken } = useAuthContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;
  const onDownloadFile = async (file: any) => {
    if (!accessToken) {
      console.error('Token de acesso não disponível.');
      return;
    }

    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(`/attachments/media/${file.id}`, {
        responseType: 'blob'
      });

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', file.name);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    } catch (error) {
      console.error('Erro ao fazer o download do arquivo:', error);
    }
  };

  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Detalhes da Importação
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Arquivo:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.sold_item_attachment ? (
                <Button
                  component="label"
                  variant="text"
                  color="secondary"
                  startIcon={<DownloadIcon />}
                  onClick={() => onDownloadFile(item.sold_item_attachment)}
                >
                  {(item.sold_item_attachment as Attachment).name}
                </Button>
              ) : (
                'Arquivo não encontrado'
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Data de cadastro:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {formatDateTime(item.created_at)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Responsável:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {getUserName(item?.owner as User)}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Status:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {capitalize(item.status_label)}{' '}
              {item.status === 'ERROR' ? (
                <>
                  <IconButton aria-label="delete" onClick={handleClick}>
                    <ErrorOutlineIcon />
                  </IconButton>

                  <Popper id={id} open={open} anchorEl={anchorEl} sx={{ zIndex: 1301 }}>
                    <Paper>
                      <Typography sx={{ p: 2 }}>Erro: {item.error_message}</Typography>
                    </Paper>
                  </Popper>
                </>
              ) : (
                ''
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Importação iniciada em:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item?.start_at ? formatDateTime(item.start_at) : 'Não iniciado'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Importação concluída em:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item?.finished_at ? formatDateTime(item.finished_at) : 'Não concluído'}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Empresa:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {(item.company as Company)?.company_name || ' - '}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Mês:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {MonthNameEnum[item.record_month] || ' - '}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Ano:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.record_year}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
