import { TableRow } from '@mui/material';
import styled from 'styled-components';
import { hexToRgba } from '../../../utils/utils';

export const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover
  },
  '&:hover': {
    backgroundColor: hexToRgba(theme.palette.primary.main, 0.1)
  },
  '&:last-child td, &:last-child th': {
    border: 0
  }
}));
