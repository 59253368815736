import { AnalyticsPage } from '../../../sections/@analytics/AnalyticsPage';
import { AnalyticFormContext } from '../../../sections/@analytics/context/AnalyticsContext';

export const AnalyticsWrapper = () => {
  return (
    <AnalyticFormContext>
      <AnalyticsPage />
    </AnalyticFormContext>
  );
};
