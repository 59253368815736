import AddIcon from '@mui/icons-material/Add';
import FilterListIcon from '@mui/icons-material/FilterList';
import { Box, Button, Tooltip } from '@mui/material';
import { useEffect, useState } from 'react';

import { debounce } from 'lodash';
import { useForm, useWatch } from 'react-hook-form';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { useCrudPermissions } from '../../../hooks/useCrudPermissions';
import { Company } from '../../../models/Company';
import { CompaniesFilterDialog } from '../CompaniesFilterDialog';
import { CompaniesTable } from '../CompaniesTable';
import { CompanyDetailsDialog } from '../CompanyDetailsDialog';
import { CompanyFormDialog } from '../CompanyFormDialog';
import { useCompaniesPageContext } from '../context/CompaniesPageContext';
import { ButtonsContainer } from './styles';

export const CompaniesPage = () => {
  const { permissions } = useCrudPermissions({
    submodule: 'COMPANY'
  });

  const [showFilter, setShowFilter] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [editItem, setEditItem] = useState<Company | undefined>(undefined);
  const [viewItem, setViewItem] = useState<Company | undefined>(undefined);
  const [numFilters, setNumFilters] = useState<number>(0);

  const { control } = useForm();
  const watchSearch = useWatch({
    control,
    name: 'search'
  });

  const { filterFields, setFilterFields } = useCompaniesPageContext();

  const handleAddCompany = () => {
    setNewItem(true);
  };

  const handleViewItem = (item: Company) => {
    setViewItem(item);
  };

  const handleEditItem = (item: Company) => {
    setEditItem(item);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  const handleCloseDialogs = () => {
    setShowFilter(false);
    setNewItem(false);
    setEditItem(undefined);
    setViewItem(undefined);
  };

  useEffect(() => {
    if (watchSearch) {
      const debounced = debounce(() => {
        setFilterFields({
          ...filterFields,
          search: watchSearch
        });
      }, 500);
      debounced();
      return debounced.cancel;
    } else {
      setFilterFields({
        ...filterFields,
        search: undefined
      });
    }
  }, [watchSearch]);

  return (
    <>
      <ButtonsContainer>
        {permissions.hasReadPermission && (
          <>
            <Box mr={1}>
              <ControlledTextInput
                control={control}
                name="search"
                label="Busca de Empresas"
                placeholder="Digite para Pesquisar"
                sx={{ mr: 1 }}
              />
            </Box>
            <Tooltip title="Filtros">
              <Button variant="outlined" color="primary" startIcon={<FilterListIcon />} sx={{ mr: 1 }} onClick={handleShowFilter}>
                Filtros {numFilters > 0 && `(${numFilters})`}
              </Button>
            </Tooltip>
          </>
        )}
        {permissions.hasCreatePermission && (
          <Tooltip title="Criar Empresa">
            <Button startIcon={<AddIcon />} variant="contained" onClick={handleAddCompany}>
              Adicionar
            </Button>
          </Tooltip>
        )}
      </ButtonsContainer>
      <CompaniesTable onView={handleViewItem} onEdit={handleEditItem} permissions={permissions} />
      {showFilter && <CompaniesFilterDialog onClose={handleCloseDialogs} setNumFilters={setNumFilters} />}
      {viewItem && <CompanyDetailsDialog item={viewItem} onClose={handleCloseDialogs} />}
      {(newItem || editItem) && <CompanyFormDialog editItem={editItem} onClose={handleCloseDialogs} />}
    </>
  );
};
