import { AnalyticsChartPage } from '../../../sections/@analytics-chart/AnalyticsChartPage';
import { AnalyticsChartContext } from '../../../sections/@analytics-chart/context/AnalyticsChartContext';

export const AnalyticsChartWrapper = () => {
  return (
    <AnalyticsChartContext>
      <AnalyticsChartPage />
    </AnalyticsChartContext>
  );
};
