import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Analytics } from '../../../models/Analytics';
import { AnalyticsChart } from '../../../models/AnalyticsChart';
import { ApiResults } from '../../../models/ApiResults';
import { AnalyticsChartService } from '../../../services/AnalyticsChart.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface AnalyticsChartFilterFields extends AnalyticsChart {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  analytics_type?: string;
}

export interface AnalyticsFilterFields extends Analytics {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  list_children?: number;
}

export interface AnalyticsChartResult extends ApiResults {
  results: AnalyticsChart[];
}

interface AnalyticsChartContextProps {
  analyticsType: AnalyticsChartResult | undefined;
  fetchAnalyticsChart: () => void;
  deleteAnalyticsChart: (id: number) => Promise<void>;
  deleteManyAnalyticsChart: (items: string[]) => Promise<void>;
  filterFields: AnalyticsChartFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<AnalyticsChartFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const AnalyticsChartContextProvider = createContext<AnalyticsChartContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const AnalyticsChartContext = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [analyticsType, setAnalyticsChart] = React.useState<AnalyticsChartResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<AnalyticsChartFilterFields>({});

  const fetchAnalyticsChart = async () => {
    dispatch(showSpinner());
    try {
      const response = await AnalyticsChartService.get(filterFields, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setAnalyticsChart(response.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteAnalyticsChart = async (id: number) => {
    dispatch(showSpinner());
    try {
      await AnalyticsChartService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      fetchAnalyticsChart();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyAnalyticsChart = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await AnalyticsChartService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registros excluídos!',
          severity: 'success'
        })
      );
      fetchAnalyticsChart();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchAnalyticsChart();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: AnalyticsChartContextProps = {
    analyticsType,
    fetchAnalyticsChart,
    deleteAnalyticsChart,
    deleteManyAnalyticsChart,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <AnalyticsChartContextProvider.Provider value={contextValue}>{children}</AnalyticsChartContextProvider.Provider>;
};

export const useAnalyticsChartContext = () => {
  const context = useContext(AnalyticsChartContextProvider);
  if (!context) {
    throw new Error('useAnalyticsChartContext must be used within a AnalyticsChartContextProvider');
  }
  return context;
};
