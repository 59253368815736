import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { PermissionGroup } from '../../../models/PermissionGroup';
import { PermissionGroupsService } from '../../../services/PermissionGroups.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';

export interface PermissionGroupsFilterFields extends PermissionGroup {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface PermissionGroupsResult extends ApiResults {
  results: PermissionGroup[];
}

interface PermissionGroupsContextProps {
  permissionGroups: PermissionGroupsResult | undefined;
  fetchPermissionGroups: () => void;
  deletePermissionGroup: (id: number) => Promise<void>;
  deleteManyPermissionGroups: (items: string[]) => Promise<void>;
  filterFields: PermissionGroupsFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<PermissionGroupsFilterFields>>;
  setShouldRefresh: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (pageSize: number) => void;
}

const PermissionGroupsContext = createContext<PermissionGroupsContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const PermissionGroupsContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [shouldRefresh, setShouldRefresh] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [permissionGroups, setPermissionGroups] = useState<PermissionGroupsResult | undefined>(undefined);
  const [filterFields, setFilterFields] = useState<PermissionGroupsFilterFields>({});

  const fetchPermissionGroups = async () => {
    dispatch(showSpinner());

    try {
      const response = await PermissionGroupsService.get(filterFields, page, rowsPerPage);
      if (response) {
        setPermissionGroups(response.data);
      }
    } catch (error) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Houve um erro ao processar a sua solicitação', severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deletePermissionGroup = async (id: number) => {
    dispatch(showSpinner());
    try {
      await PermissionGroupsService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Perfil excluído com sucesso',
          severity: 'success'
        })
      );
      fetchPermissionGroups();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data?.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyPermissionGroups = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await PermissionGroupsService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Perfis excluídos com sucesso',
          severity: 'success'
        })
      );
      fetchPermissionGroups();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (pageSize: number) => {
    setRowsPerPage(pageSize);
  };

  useEffect(() => {
    fetchPermissionGroups();
  }, [filterFields, page, rowsPerPage]);

  useEffect(() => {
    if (shouldRefresh) {
      fetchPermissionGroups();
      setShouldRefresh(false);
    }
  }, [shouldRefresh]);

  const contextValue: PermissionGroupsContextProps = {
    permissionGroups,
    fetchPermissionGroups,
    deletePermissionGroup,
    deleteManyPermissionGroups,
    filterFields,
    setFilterFields,
    setShouldRefresh,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <PermissionGroupsContext.Provider value={contextValue}>{children}</PermissionGroupsContext.Provider>;
};

export const usePermissionGroupsPageContext = () => {
  const context = useContext(PermissionGroupsContext);
  if (!context) {
    throw new Error('usePermissionGroupsContext must be used within a PermissionGroupsProvider');
  }
  return context;
};
