// material-ui
import { useTheme } from '@mui/material/styles';

import { Box } from '@mui/system';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';
import Icon from '../../../../assets/images/media/icon.ico';
import Logo from '../../../../assets/images/media/logo.png';
import { ROUTES } from '../../../../routes/routes';
import { activeNavItem } from '../../../../store/slicers/menu.slicers';
import { RootState } from '../../../../store/store';
import { DrawerHeaderStyled } from './styles';

// ==============================|| DRAWER HEADER ||============================== //
interface DrawerHeaderProps {
  open: boolean;
}

export const DrawerHeader = ({ open }: DrawerHeaderProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { drawerOpen } = useSelector((state: RootState) => state.menu);

  const handleNavigate = () => {
    dispatch(activeNavItem({ openNavItem: '' }));
    navigate(ROUTES.authenticated.dashboard.home);
  };

  return (
    <DrawerHeaderStyled theme={theme} open={drawerOpen}>
      {!open ? (
        <Link onClick={handleNavigate} to={ROUTES.authenticated.dashboard.home}>
          <Box sx={{ display: 'flex', height: '71px', p: 1.5 }}>
            <img src={Icon} alt="logo" width="100%" color="red" />
          </Box>
        </Link>
      ) : (
        <Link onClick={handleNavigate} to={ROUTES.authenticated.dashboard.home}>
          <Box sx={{ display: 'flex', flex: 1, px: '2rem', py: '1rem' }}>
            <img src={Logo} alt="logo" width="100%" />
          </Box>
        </Link>
      )}
    </DrawerHeaderStyled>
  );
};
