import AddIcon from '@mui/icons-material/Add';
import { Button, Divider, Tooltip } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { AccountEntryResult, AccountingEntryFilterFields } from '..';
import { DialogWithToggleFullscreen } from '../../../../../components/basics/DialogWithToggleFullscreen';
import { useCrudPermissions } from '../../../../../hooks/useCrudPermissions';
import { AccountingEntry } from '../../../../../models/AccountingEntry';
import { AccountingEntryMetadata } from '../../../../../models/AccountingEntryMetadata';
import { CompanyGroup } from '../../../../../models/CompanyGroup';
import { AccountingEntryService } from '../../../../../services/AccountingEntry.service';
import { AccountingEntryMetadataService } from '../../../../../services/AccountingEntryMetadata.service';
import { AnalyticsAccountService } from '../../../../../services/AnalyticsAccount.service';
import { ManualInputsService } from '../../../../../services/ManualInput.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../../../utils/api/response';
import { getStartAndFinalDate } from '../../../../../utils/utils';
import { ButtonsContainer } from '../../../../@analytics-type/AnalyticsTypePage/styles';
import { ManualInputDetailsDialog } from '../../../../@manual-inputs/ManualInputsDetailsDialog';
import { ManualInputFormDialog } from '../../../../@manual-inputs/ManualInputsFormDialog';
import { IAnalysisModal } from '../../../models/AnalysisModal';
import { AccountingEntryMetadataFilterFields } from '../../../TabsContent/AnalyticBP/DynamicTableBP/DetailsModal';
import { buildFiltersByAnalysisType } from '../utils';
import { ManualInputsTableAnalysis } from './ManualInputsTable';

interface Props {
  modalData: IAnalysisModal;
  companyGroup: CompanyGroup;
  onClose: () => void;
  onSubmit: (refresh: boolean) => Promise<void>;
  onMinimize: () => void;
  onToggleModalFullscreen: () => void;
}

export const ManualInputsTableModal = ({ modalData, companyGroup, onClose, onSubmit, onMinimize, onToggleModalFullscreen }: Props) => {
  const { permissions } = useCrudPermissions({ submodule: 'MANUAL_ACCOUNTING_ENTRY' });
  const [newItem, setNewItem] = useState(false);
  const [editItem, setEditItem] = useState<AccountingEntry | undefined>(undefined);
  const [viewItem, setViewItem] = useState<AccountingEntry | undefined>(undefined);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);
  const [page, setPage] = useState<number>(0);
  const [accountingEntries, setAccountingEntries] = useState<AccountEntryResult | undefined>(undefined);
  const [accountingEntriesMetadata, setAccountingEntriesMetadata] = useState<AccountingEntryMetadata | undefined>(undefined);
  const [isLoadingTotals, setIsLoadingTotals] = useState<boolean>(true);

  const dispatch = useDispatch();
  let row_id = modalData?.data?.row?.id;
  let analytic = modalData?.data?.row?.analytic;
  let entry_date = modalData?.data?.key;

  const account_description = modalData?.data?.row?.description;
  const { startDate, finalDate } = getStartAndFinalDate(entry_date);

  const handleAddManualInput = async () => {
    const accountData = await getAccount();
    setNewItem(true);

    const accountCode = accountData?.code;
    const accountDetails = {
      ...modalData?.data?.row,
      code: accountCode
    };

    const item: AccountingEntry = {
      entry_date,
      ...(modalData?.data?.row?.origin === 'D' ? { debit_account: accountDetails } : { credit_account: accountDetails })
    };

    setEditItem(item);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const fetchDetailsData = async () => {
    dispatch(
      showSpinner({
        message: 'Buscando ajustes...'
      })
    );

    let filters: AccountingEntryFilterFields = {
      entry_date_after: startDate,
      entry_date_before: finalDate,
      is_manual: true
    };

    const { level } = modalData.data.row;
    filters = buildFiltersByAnalysisType(modalData?.data.analytic_type, level, row_id, analytic, filters);

    try {
      const response = await AccountingEntryService.get(filters, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setAccountingEntries(response.data);
      }
    } catch (error) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar dados', severity: 'error' }));
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchTotalDetails = async () => {
    setIsLoadingTotals(true);
    let filters: AccountingEntryMetadataFilterFields = {
      entry_date_after: startDate,
      entry_date_before: finalDate,
      is_manual: true
    };

    const { level } = modalData.data.row;
    filters = buildFiltersByAnalysisType(modalData?.data.analytic_type, level, row_id, analytic, filters);

    try {
      const response = await AccountingEntryMetadataService.get(filters, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setAccountingEntriesMetadata(response.data);
      }
    } catch (error) {
      dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao buscar dados', severity: 'error' }));
    } finally {
      setIsLoadingTotals(false);
    }
  };

  const getAccount = async () => {
    dispatch(showSpinner());
    try {
      const response = await AnalyticsAccountService.getOne(row_id);
      if (response && checkResponseStatus(response)) {
        const results = response.data;
        return results;
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error?.data?.detail || 'Houve um erro ao buscar informações da conta',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
    return null;
  };

  const getManualInputItem = async (item_id: number) => {
    dispatch(showSpinner());
    try {
      const response = await ManualInputsService.getOne(item_id);
      if (response && checkResponseStatus(response)) {
        const results = response.data;
        return results;
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error?.data?.detail || 'Houve um erro ao buscar informações da conta',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
    return null;
  };

  const handleViewItem = async (item: AccountingEntry) => {
    const manual_input = await getManualInputItem(item.manual_entry);
    setViewItem(manual_input);
  };

  const handleEditItem = async (item: AccountingEntry) => {
    const manual_input = await getManualInputItem(item.manual_entry);
    setEditItem(manual_input);
  };

  const handleDeleteItem = async (item: AccountingEntry) => {
    dispatch(showSpinner());
    try {
      await ManualInputsService.delete(item?.manual_entry);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      fetchDetailsData();
      fetchTotalDetails();
      await onSubmit(true);
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleCloseDialogs = () => {
    setNewItem(false);
    setEditItem(undefined);
    setViewItem(undefined);
  };

  const handleSubmit = async () => {
    await onSubmit(!!newItem || !!editItem);
    fetchDetailsData();
    fetchTotalDetails();
  };

  useEffect(() => {
    fetchDetailsData();
  }, [modalData, rowsPerPage, page]);

  useEffect(() => {
    fetchTotalDetails();
  }, []);

  return (
    <DialogWithToggleFullscreen
      open={!!accountingEntries}
      onClose={onClose}
      title={`Ajustes - ${account_description}`}
      onMinimize={onMinimize}
      fullscreen={modalData.fullscreen}
      toggleFullscreen={onToggleModalFullscreen}
    >
      <Divider />
      <ButtonsContainer>
        {permissions.hasCreatePermission && (
          <Tooltip title="Cadastrar ">
            <Button startIcon={<AddIcon />} variant="contained" onClick={handleAddManualInput}>
              Adicionar
            </Button>
          </Tooltip>
        )}
      </ButtonsContainer>
      <ManualInputsTableAnalysis
        onView={handleViewItem}
        onEdit={handleEditItem}
        onDelete={handleDeleteItem}
        permissions={permissions}
        companyGroup={companyGroup}
        accountingEntries={accountingEntries!}
        accountingEntriesMetadata={accountingEntriesMetadata!}
        rowsPerPage={rowsPerPage}
        page={page}
        handlePageChange={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        isLoadingTotals={isLoadingTotals}
      />
      {viewItem && <ManualInputDetailsDialog item={viewItem} onClose={handleCloseDialogs} />}
      {(newItem || editItem) && <ManualInputFormDialog editItem={editItem} onClose={handleCloseDialogs} onSubmit={handleSubmit} />}
    </DialogWithToggleFullscreen>
  );
};
