import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Analytics } from '../../../../../../models/Analytics';
import { ApiResults } from '../../../../../../models/ApiResults';
import { SoldItemAnalytic } from '../../../../../../models/SoldItem';
import { SoldItemService } from '../../../../../../services/SoldItem.service';
import { hideSpinner, showSpinner } from '../../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../../store/slicers/snackbarAlert.slicer';
import { RootState } from '../../../../../../store/store';
import { checkResponseStatus } from '../../../../../../utils/api/response';

export interface SoldItemAnalyticFilterFields {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  owner?: number;
  record_month?: number;
  record_year?: number;
  company?: number;
  status?: string;
}

export interface AnalyticsFilterFields extends Analytics {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  list_children?: number;
}

export interface SoldItemAnalyticResult extends ApiResults {
  results: SoldItemAnalytic[];
}

interface SoldItemAnalyticContextProps {
  soldItemAnalytic: SoldItemAnalyticResult | undefined;
  fetchSoldItemAnalytic: () => Promise<void>;
  filterFields: SoldItemAnalyticFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<SoldItemAnalyticFilterFields>>;
  handleSetFilters: (filters: SoldItemAnalyticFilterFields) => void;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const SoldItemAnalyticContextProvider = createContext<SoldItemAnalyticContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const SoldItemAnalyticContext = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(0);
  const [soldItemAnalytic, setSoldItemAnalytic] = React.useState<SoldItemAnalyticResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<SoldItemAnalyticFilterFields>({});
  const { companiesIds, loading } = useSelector((state: RootState) => state.companiesContext);

  const fetchSoldItemAnalytic = async () => {
    dispatch(showSpinner());
    try {
      const response = await SoldItemService.get({ ...filterFields }, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setSoldItemAnalytic(response.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleSetFilters = (filters: SoldItemAnalyticFilterFields) => {
    setFilterFields(filters);
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    if (filterFields?.record_year?.trim() && !loading && companiesIds && companiesIds.length > 0) {
      fetchSoldItemAnalytic();
    }
  }, [filterFields, companiesIds]);

  const value = useMemo(
    () => ({
      soldItemAnalytic,
      fetchSoldItemAnalytic,
      filterFields,
      setFilterFields,
      handleSetFilters,
      page,
      rowsPerPage,
      handleOrderChange,
      handlePageChange,
      handleOnChangeRowsPerPage
    }),
    [soldItemAnalytic, filterFields, page, rowsPerPage]
  );

  return <SoldItemAnalyticContextProvider.Provider value={value}>{children}</SoldItemAnalyticContextProvider.Provider>;
};

export const useSoldItemAnalyticContext = () => {
  const context = useContext(SoldItemAnalyticContextProvider);
  if (!context) {
    throw new Error('useSoldItemAnalyticContext must be used within a SoldItemAnalyticContextProvider');
  }
  return context;
};
