import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';

const PermissionsUrl = '/users/permissions/';

export const PermissionsService = {
  get: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(PermissionsUrl);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
