import { CompaniesTypesPage } from "../../../sections/@companies-type/CompaniesTypesPage";
import { CompaniesTypesContext } from "../../../sections/@companies-type/context/CompaniesTypesContext";

export const CompaniesTypesPageWrapper = () => {
  return (
    <CompaniesTypesContext>
      <CompaniesTypesPage />
    </CompaniesTypesContext>
  );
};
