import { Attachment } from './Attachment';
import { Company } from './Company';
import { User } from './User';

export type SoldItemFileStatus = 'PROCESSING' | 'COMPLETED' | 'ERROR';

export interface SoldItemFile {
  id?: number;
  owner?: User | number;
  sold_item_attachment?: Attachment | number | null;
  record_year: number;
  record_month: number;
  start_at?: string;
  finished_at?: string;
  error_message?: string;
  company?: Company | number;
  status: SoldItemFileStatus;
  status_label: string;
  created_at: string;
  updated_at: string;
}

export enum StatusValueEnum {
  PROCESSING = 'PROCESSING',
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR'
}

export enum StatusLabelEnum {
  PROCESSING = 'Processando',
  COMPLETED = 'Concluído',
  ERROR = 'Com Erro'
}

export enum StatusColorEnum {
  PROCESSING = 'info',
  COMPLETED = 'success',
  ERROR = 'error'
}

export enum MonthNameEnum {
  Janeiro = 1,
  Fevereiro = 2,
  Março = 3,
  Abril = 4,
  Maio = 5,
  Junho = 6,
  Julho = 7,
  Agosto = 8,
  Setembro = 9,
  Outubro = 10,
  Novembro = 11,
  Dezembro = 12
}
