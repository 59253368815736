import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { AnalyticsChart } from '../../../models/AnalyticsChart';
import { findSelectedOption } from '../../../utils/utils';
import { AnalyticsChartFilterFields, useAnalyticsChartContext } from '../context/AnalyticsChartContext';

interface Props {
  onClose: () => void;
  setNumFilters: (numFilters: number) => void;
}
const periodOptions: SelectOption[] = [
  { id: 'YEARLY', name: 'Anual' },
  { id: 'MONTHLY', name: 'Mensal' }
];

const getDefaultValues = (filter?: AnalyticsChart): AnalyticsChartFilterFields => {
  return {
    title: filter?.title ?? '',
    current_period: filter?.current_period ? findSelectedOption(periodOptions, filter?.current_period) : '',
    retroactive_period: filter?.retroactive_period ? findSelectedOption(periodOptions, filter?.retroactive_period) : ''
  };
};

export const AnalyticsChartFilterDialog = ({ onClose, setNumFilters }: Props) => {
  const { filterFields, setFilterFields } = useAnalyticsChartContext();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<AnalyticsChartFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const updateNumFilters = (data: AnalyticsChartFilterFields) => {
    let count = 0;
    if (data.title) count++;
    if (data.retroactive_period) count++;
    if (data.current_period) count++;
    setNumFilters(count);
  };

  const onSubmit: SubmitHandler<AnalyticsChartFilterFields> = async (data: AnalyticsChartFilterFields) => {
    data.current_period = data.current_period?.id || null;
    data.retroactive_period = data.retroactive_period?.id || null;
    setFilterFields({ ...data } as AnalyticsChartFilterFields);
    updateNumFilters(data);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as AnalyticsChartFilterFields);
    setNumFilters(0);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="title">Título</InputLabel>
              <ControlledTextInput name="title" placeholder="Título" control={control} errorMessage={errors.title?.message?.toString()} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="current_period">Periodo Atual</InputLabel>
              <ControlledComboBox
                name="current_period"
                control={control}
                errorMessage={errors.current_period?.message?.toString()}
                selectOptions={periodOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="retroactive_period">Periodo Retroativo</InputLabel>
              <ControlledComboBox
                name="retroactive_period"
                control={control}
                errorMessage={errors.retroactive_period?.message?.toString()}
                selectOptions={periodOptions}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
