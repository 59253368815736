import React, { createContext, useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { User } from '../../../models/User';
import { UsersService } from '../../../services/Users.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface UsersFilterFields extends User {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface UsersResult extends ApiResults {
  results: User[];
}

interface UsersContextProps {
  users: UsersResult | undefined;
  fetchUsers: () => void;
  deleteUser: (id: number) => Promise<void>;
  deleteManyUsers: (items: string[]) => Promise<void>;
  filterFields: UsersFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<UsersFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const UsersPageContext = createContext<UsersContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const UsersPageContextProvider = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(50);
  const [users, setUsers] = useState<UsersResult | undefined>(undefined);
  const [filterFields, setFilterFields] = useState<UsersFilterFields>({} as UsersFilterFields);

  const fetchUsers = async () => {
    dispatch(showSpinner());

    try {
      const response = await UsersService.get(filterFields, page, rowsPerPage);
      if (checkResponseStatus(response)) {
        setUsers(response?.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({ title: 'Erro', message: error?.data || 'Houve um erro ao processar a sua solicitação', severity: 'error' })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteUser = async (id: number) => {
    dispatch(showSpinner());
    try {
      await UsersService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registro excluído com sucesso!',
          severity: 'success'
        })
      );
      fetchUsers();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyUsers = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await UsersService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Registros excluídos com sucesso!',
          severity: 'success'
        })
      );
      fetchUsers();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail ?? 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchUsers();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: UsersContextProps = {
    users,
    fetchUsers,
    deleteUser,
    deleteManyUsers,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <UsersPageContext.Provider value={contextValue}>{children}</UsersPageContext.Provider>;
};

export const useUsersPageContext = () => {
  const context = useContext(UsersPageContext);
  if (!context) {
    throw new Error('useUsersPageContext must be used within a UsersPageContextProvider');
  }
  return context;
};
