import { Box, Card } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import styled from 'styled-components';

export const CoverCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  min-height: 300px;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.palette.primary.main};
  border-radius: 4px;
`;

export const UserAvatarWrapper = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
`;

export const CardTabs = styled(Box)`
  bottom: 0;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background.paper};
`;

export const UserAvatarWrapperHover = styled.div`
  position: relative;
  .edit-icon {
    position: absolute;
    top: 0;
    right: -20px;
    color: ${({ theme }) => theme.palette.primary.main};
    background-color: ${({ theme }) => theme.palette.common.white};
    border-radius: 50%;
    padding: 5px;
    font-size: 2rem;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    cursor: pointer;

    .edit-icon {
      opacity: 1;
    }

    .user-image {
      opacity: 0.8;
    }
  }
`;

export const UserAvatar = styled(Avatar)`
  width: 150px;
  height: 150px;
  margin: 0 auto;
  border: 5px solid ${({ theme }) => theme.palette.background.paper};
`;
