import { Box } from '@mui/material';
import styled from 'styled-components';

export const TabsWrapper = styled(Box).attrs({
  typography: 'body1'
})`
  display: flex;
  width: 100%;
  justify-content: center;

  /* background-color: blue; */
`;
