import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { IBudgetFilter } from '../models/Budget';

const BudgetItemDREUrl = '/reports/budget/';

export const BudgetItemDREService = {
  post: async (budget_id: number, data: IBudgetFilter) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${BudgetItemDREUrl}${budget_id}`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
