import { yupResolver } from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../../../components/basics/ControlledTextInput';
import { AnalyticsChart } from '../../../../../models/AnalyticsChart';
import { AnalyticsChartService } from '../../../../../services/AnalyticsChart.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { AnalyticsChartFormulaScheme } from '../../../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../../../utils/utils';
import { useAnalyticsChartContext } from '../../../context/AnalyticsChartContext';

interface Props {
  editItem?: AnalyticsChart;
  onClose: () => void;
  setEditItem: (item: AnalyticsChart) => void;
}

const formulaTypeOptions: SelectOption[] = [
  { id: 'NONE', name: 'Nenhum' },
  { id: 'LINE', name: 'Linha' },
  { id: 'BAR', name: 'Barra' }
];

const mapFormulasWithType = (formulas) => {
  return formulas.map((formula) => ({
    ...formula,
    type:
      typeof formula.type === 'string'
        ? {
            id: formula.type,
            name: formulaTypeOptions.find((option) => option.id === formula.type)?.name || ''
          }
        : formula.type
  }));
};

export const AnalyticsChartFormulasForm = ({ editItem, onClose, setEditItem }: Props) => {
  const dispatch = useDispatch();
  const [formulas, setFormulas] = useState(editItem?.formulas || []);
  const { fetchAnalyticsChart } = useAnalyticsChartContext();

  const getDefaultValues = (): AnalyticsChart => {
    return {
      formulas:
        editItem?.formulas.map((formula) => ({
          ...formula,
          type: formula.type
            ? ({ id: formula.type, name: formulaTypeOptions.find((option) => option.id === formula.type)?.name || '' } as SelectOption)
            : null
        })) || []
    };
  };

  const {
    control,
    handleSubmit,
    setError,
    unregister,
    reset,
    formState: { errors }
  } = useForm<AnalyticsChart>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(AnalyticsChartFormulaScheme),
    defaultValues: getDefaultValues()
  });

  const handleAddFormula = () => {
    setFormulas([...formulas, { id: 0, title: '', formula: '', type: 'NONE' }]);
  };

  const handleDeleteFormula = (index: number) => {
    const updatedFormulas = formulas.filter((_, i) => i !== index);
    setFormulas(mapFormulasWithType(updatedFormulas));
    reset({ formulas: mapFormulasWithType(updatedFormulas) });
    unregister(`formulas.${index - 1}`);
  };

  const createOrUpdateAnalyticsChartFormulas: SubmitHandler<AnalyticsChart> = async (data: AnalyticsChart) => {
    dispatch(showSpinner());
    try {
      const validFormulas = data?.formulas?.length
        ? data?.formulas?.filter((formula) => formula !== null && formula?.title && formula?.formula && formula?.type)
        : [];

      const payload = {
        formulas: validFormulas.length
          ? validFormulas.map((formula) => ({
              title: formula.title,
              formula: formula.formula,
              type: formula.type.id
            }))
          : []
      };
      await AnalyticsChartService.partialUpdate(editItem?.id!, payload);

      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Análise Gráfica atualizada com sucesso!' : 'Análise Gráfica criada com sucesso!',
          severity: 'success'
        })
      );

      fetchAnalyticsChart();
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <form onSubmit={handleSubmit(createOrUpdateAnalyticsChartFormulas)}>
      <DialogContent>
        {formulas.map((formula, index) => (
          <div key={index}>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor={`formulas.${index}.title`}>Título</InputLabel>
                <ControlledTextInput
                  name={`formulas.${index}.title`}
                  placeholder="Título"
                  control={control}
                  defaultValue={formula.title}
                  errorMessage={errors.formulas?.[index]?.title?.message?.toString()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <InputLabel htmlFor={`formulas.${index}.type`}>Tipo</InputLabel>
                <ControlledComboBox
                  name={`formulas.${index}.type`}
                  control={control}
                  selectOptions={formulaTypeOptions}
                  defaultValue={formulaTypeOptions.find((option) => option.id === formula.type)}
                  errorMessage={errors.formulas?.[index]?.type?.message?.toString()}
                />
              </Grid>
              <Grid item xs={12}>
                <InputLabel htmlFor={`formulas.${index}.formula`}>Fórmula</InputLabel>
                <ControlledTextInput
                  name={`formulas.${index}.formula`}
                  placeholder="Fórmula"
                  control={control}
                  defaultValue={formula.formula}
                  errorMessage={errors.formulas?.[index]?.formula?.message?.toString()}
                />
              </Grid>
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant="outlined" color="primary" startIcon={<DeleteIcon />} onClick={() => handleDeleteFormula(index)}>
                  Excluir Fórmula
                </Button>
              </Grid>
            </Grid>
            <Divider sx={{ marginY: 2 }} />
          </div>
        ))}
        <Grid item sm={12}>
          <Button variant="outlined" color="primary" startIcon={<AddIcon />} onClick={handleAddFormula}>
            Adicionar Fórmula
          </Button>
        </Grid>
      </DialogContent>
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Cancelar
        </Button>
        <Button startIcon={<CheckIcon />} variant="contained" type="submit">
          Salvar
        </Button>
      </DialogActions>
    </form>
  );
};
