import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { AnalyticsType, AnalyticsTypeEnum } from '../../../models/AnalyticsType';
import { ChartAccounts } from '../../../models/ChartAccounts';
import { AnalyticsTypeService } from '../../../services/AnalyticsType.service';
import { ChartAccountsService } from '../../../services/ChartAccounts.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { ChartAccountsScheme } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useChartAccountsContext } from '../context/ChartAccountsContext';

interface Props {
  editItem?: ChartAccounts;
  onClose: () => void;
}

export const ChartAccountsFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const { fetchChartAccounts } = useChartAccountsContext();
  const [bpOptions, setBPOptions] = useState<SelectOption[]>([]);
  const [dreOptions, setDREOptions] = useState<SelectOption[]>([]);
  const [ncgOptions, setNcgOptions] = useState<SelectOption[]>([]);
  const [fcOptions, setFcOptions] = useState<SelectOption[]>([]);

  const getDefaultValues = (): ChartAccounts => {
    return {
      id: editItem?.id ?? 0,
      description: editItem?.description ?? '',
      code: editItem?.code ?? '',
      analytics_type_bp: editItem?.analytics_type_bp
        ? ({ id: editItem.analytics_type_bp.id, name: editItem.analytics_type_bp.description } as SelectOption)
        : null,
      analytics_type_dre: editItem?.analytics_type_dre
        ? ({ id: editItem.analytics_type_dre.id, name: editItem.analytics_type_dre.description } as SelectOption)
        : null,
      analytics_type_ncg: editItem?.analytics_type_ncg
        ? ({ id: editItem.analytics_type_ncg.id, name: editItem.analytics_type_ncg.description } as SelectOption)
        : null,
      analytics_type_fc: editItem?.analytics_type_fc
        ? ({ id: editItem.analytics_type_fc.id, name: editItem.analytics_type_fc.description } as SelectOption)
        : null
    };
  };

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors }
  } = useForm<ChartAccounts>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ChartAccountsScheme),
    defaultValues: getDefaultValues()
  });

  const fetchAnalyticsType = async () => {
    dispatch(showSpinner());
    try {
      const response = await AnalyticsTypeService.getAll();
      if (response?.data?.results) {
        const result = response?.data?.results;

        const bpFilteredItems = result.filter((item: string) => AnalyticsTypeEnum[item.type] === AnalyticsTypeEnum.BP);
        setBPOptions(
          bpFilteredItems.map((type: AnalyticsType) => ({
            id: type.id,
            name: type.description
          }))
        );

        const dreFilteredItems = result.filter((item: string) => AnalyticsTypeEnum[item.type] === AnalyticsTypeEnum.DRE);
        setDREOptions(
          dreFilteredItems.map((type: AnalyticsType) => ({
            id: type.id,
            name: type.description
          }))
        );

        const ncgFilteredItems = result.filter((item: string) => AnalyticsTypeEnum[item.type] === AnalyticsTypeEnum.NCG);
        setNcgOptions(
          ncgFilteredItems.map((type: AnalyticsType) => ({
            id: type.id,
            name: type.description
          }))
        );

        const fcFilteredItems = result.filter((item: string) => AnalyticsTypeEnum[item.type] === AnalyticsTypeEnum.FC);
        setFcOptions(
          fcFilteredItems.map((type: AnalyticsType) => ({
            id: type.id,
            name: type.description
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    fetchAnalyticsType();
  }, []);

  const createOrUpdateChartAccounts: SubmitHandler<ChartAccounts> = async (data: ChartAccounts) => {
    dispatch(showSpinner());
    try {
      const payload = {
        ...data,
        analytics_type_bp: data.analytics_type_bp?.id,
        analytics_type_dre: data.analytics_type_dre?.id,
        analytics_type_ncg: data.analytics_type_ncg?.id,
        analytics_type_fc: data.analytics_type_fc?.id
      } as ChartAccounts;

      editItem?.id ? await ChartAccountsService.update(payload) : await ChartAccountsService.create(payload);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Plano de Contas atualizado com sucesso!' : 'Plano de Contas criado com sucesso!',
          severity: 'success'
        })
      );
      fetchChartAccounts();
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Plano de Contas
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createOrUpdateChartAccounts)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="code">Código</InputLabel>
              <ControlledTextInput name="code" placeholder="Código" control={control} errorMessage={errors.code?.message?.toString()} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="description">Descrição</InputLabel>
              <ControlledTextInput
                name="description"
                placeholder="Descrição de Plano de Contas"
                control={control}
                errorMessage={errors.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="bp">BP</InputLabel>
              <ControlledComboBox
                name="analytics_type_bp"
                control={control}
                placeholder="Selecione o BP"
                errorMessage={errors.analytics_type_bp?.message?.toString()}
                selectOptions={bpOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="dre">DRE</InputLabel>
              <ControlledComboBox
                name="analytics_type_dre"
                placeholder="Selecione o DRE"
                control={control}
                errorMessage={errors.analytics_type_dre?.message?.toString()}
                selectOptions={dreOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="ncg">NCG</InputLabel>
              <ControlledComboBox
                name="analytics_type_ncg"
                placeholder="Selecione o NCG"
                control={control}
                errorMessage={errors.analytics_type_ncg?.message?.toString()}
                selectOptions={ncgOptions}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="fc">FC</InputLabel>
              <ControlledComboBox
                name="analytics_type_fc"
                placeholder="Selecione o FC"
                control={control}
                errorMessage={errors.analytics_type_fc?.message?.toString()}
                selectOptions={fcOptions}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
