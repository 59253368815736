import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, Divider, useTheme } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useRef } from 'react';
import { AnalyticsType } from '../../../models/AnalyticsType';
import { AnalyticsTab } from './AnalyticsTab';

interface Props {
  editItem?: AnalyticsType;
  onClose: () => void;
}

export const AnalyticsDialog = ({ editItem, onClose }: Props) => {
  const theme = useTheme();
  const dialogRef = useRef();

  const scrollToBottom = () => {
    dialogRef.current.scrollTop = dialogRef.current.scrollHeight + 50;
  };

  return (
    <Dialog open={!!editItem} onClose={onClose} aria-labelledby="responsive-dialog-title" fullScreen>
      {/* Header */}
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
        <DialogTitle variant="h4" color="primary">
          Análises: {editItem?.description}
        </DialogTitle>
        <Button variant="text" color="primary" onClick={onClose} startIcon={<CloseIcon />}>
          Fechar
        </Button>
      </Box>
      <Divider />
      <div ref={dialogRef} style={{ overflow: 'auto' }}>
        <DialogContent sx={{ flex: 1, backgroundColor: theme.palette.background.default }}>
          <AnalyticsTab item={editItem!} onClose={onClose} scrollToBottom={scrollToBottom} />
        </DialogContent>
      </div>
      <Divider />
    </Dialog>
  );
};
