import CheckIcon from '@mui/icons-material/Check';
import ClearAllIcon from '@mui/icons-material/ClearAll';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { ChartAccounts } from '../../../models/ChartAccounts';
import { ChartAccountsFilterFields, useChartAccountsContext } from '../context/ChartAccountsContext';

interface Props {
  onClose: () => void;
  setNumFilters: (numFilters: number) => void;
}

const getDefaultValues = (filter?: ChartAccounts): ChartAccountsFilterFields => {
  return {
    description: filter?.description ?? ''
  };
};

export const ChartAccountsFilterDialog = ({ onClose, setNumFilters }: Props) => {
  const { filterFields, setFilterFields } = useChartAccountsContext();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<ChartAccountsFilterFields>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: getDefaultValues(filterFields)
  });

  const updateNumFilters = (data: ChartAccountsFilterFields) => {
    let count = 0;
    if (data.description) count++;
    setNumFilters(count);
  };

  const onSubmit: SubmitHandler<ChartAccountsFilterFields> = async (data: ChartAccountsFilterFields) => {
    setFilterFields({ ...data } as ChartAccountsFilterFields);
    updateNumFilters(data);
    onClose();
  };

  const handleClear = () => {
    setFilterFields({} as ChartAccountsFilterFields);
    setNumFilters(0);
    onClose();
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Filtros
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <InputLabel htmlFor="description">Descrição</InputLabel>
              <ControlledTextInput
                name="description"
                placeholder="Descrição do plano de contas"
                control={control}
                errorMessage={errors.description?.message?.toString()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<ClearAllIcon />} variant="outlined" onClick={handleClear}>
            Limpar
          </Button>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Fechar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
