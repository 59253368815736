import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from '@mui/icons-material/Home';
import { Divider, Grid, Icon, Tab, Tabs } from '@mui/material';
import React, { Suspense, startTransition, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import { CheckProcessingService } from '../../../services/CheckProcessing.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { hexToRgba } from '../../../utils/utils';
import { useAnalyticFormContext } from '../context/AnalyticsContext';
import { AnalyticsBPProvider } from '../TabsContent/AnalyticBP/DynamicTableBP/context/TableContext';
import { AnalyticsDREProvider } from '../TabsContent/AnalyticDRE/DynamicTableDRE/context/TableContext';
import { AnalyticsFcProvider } from '../TabsContent/AnalyticFC/DynamicTableFC/context/TableContext';
import { AnalyticsNCGProvider } from '../TabsContent/AnalyticNCG/DynamicTableNCG/context/TableContext';
import { SoldItemAnalyticContext } from '../TabsContent/AnalyticSoldItem/TableComponent/context/SoldItemAnalyticContext';
import { FullScreenCard } from './styles';

// Importando dinamicamente as tabs
const AnalyticHomeTab = React.lazy(() => import('../TabsContent/AnalyticHome'));
const AnalyticBPTab = React.lazy(() => import('../TabsContent/AnalyticBP'));
const AnalyticDRETab = React.lazy(() => import('../TabsContent/AnalyticDRE'));
const AnalyticNcgTab = React.lazy(() => import('../TabsContent/AnalyticNCG'));
const AnalyticFcTab = React.lazy(() => import('../TabsContent/AnalyticFC'));
const AnalyticSoldItemTab = React.lazy(() => import('../TabsContent/AnalyticSoldItem'));

export const AnalyticsPage = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { currentTab, handleChangeTab, fullScreenMode } = useAnalyticFormContext();
  const [tabsVisibility, setTabsVisibility] = useState([true, false, false, false, false, false]);

  const tabs = useMemo(
    () => [
      { label: 'Análises', icon: <HomeIcon fontSize="small" />, disabled: false },
      { label: 'Balanço Patrimonial (BP)', icon: null, disabled: false },
      { label: 'Demonstração do Resultado do Exercício (DRE)', icon: null, disabled: false },
      { label: 'Necessidade de Capital de Giro (NCG)', icon: null, disabled: false },
      { label: 'Fluxo de Caixa (FC)', icon: null, disabled: false },
      { label: 'Itens Vendidos', icon: null, disabled: false }
    ],
    []
  );

  const checkEcdFiles = useCallback(async () => {
    try {
      dispatch(showSpinner({ message: 'Verificando importações em andamento...' }));
      const response = await CheckProcessingService.get();
      if (response?.data?.count === 0) {
        return true;
      } else {
        dispatch(
          showSnackbarAlert({
            title: 'Erro!',
            message: 'Existe um processo em andamento. Aguarde a finalização para acessar as análises',
            severity: 'error'
          })
        );
        return false;
      }
    } catch (error) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: 'Erro ao verificar importações em andamento',
          severity: 'error'
        })
      );
      return false;
    } finally {
      dispatch(hideSpinner());
    }
  }, [dispatch]);

  const handleCardClick = useCallback(
    (index: number) => {
      startTransition(() => {
        const updatedVisibility = tabsVisibility.map((visibility, i) => (i === index ? true : visibility));
        setTabsVisibility(updatedVisibility);
        handleChangeTab(index);
      });
    },
    [handleChangeTab, tabsVisibility]
  );

  const handleCloseTab = useCallback(
    (index: number) => {
      const updatedVisibility = tabsVisibility.map((visibility, i) => (i === index ? false : visibility));
      setTabsVisibility(updatedVisibility);
      handleChangeTab(0);
    },
    [handleChangeTab, tabsVisibility]
  );

  useEffect(() => {
    checkEcdFiles();
  }, [checkEcdFiles]);

  return (
    <FullScreenCard fullScreen={fullScreenMode}>
      <Grid container>
        <Grid item xs={12} sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            orientation="horizontal"
            variant="scrollable"
            value={currentTab}
            scrollButtons={false}
            onChange={(event, newValue) => {
              handleChangeTab(newValue);
            }}
          >
            {tabs.map((tab, index) => (
              <Tab
                key={tab.label + String(index)}
                label={tab.label}
                icon={
                  tab.icon ? (
                    tab.icon
                  ) : (
                    <Icon
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCloseTab(index);
                      }}
                      sx={{
                        marginLeft: 'auto'
                      }}
                    >
                      <CloseIcon fontSize="small" />
                    </Icon>
                  )
                }
                iconPosition="end"
                sx={{
                  height: 40,
                  maxHeight: 30,
                  minHeight: 50,
                  justifyContent: 'center'
                }}
                style={{
                  textTransform: 'none',
                  backgroundColor: currentTab === index ? hexToRgba(theme.palette.primary.main, 0.1) : 'inherit',
                  display: tabsVisibility[index] ? 'flex' : 'none'
                }}
              />
            ))}
          </Tabs>
        </Grid>
        <Divider />
        <Suspense fallback={<div>Carregando...</div>}>
          <Grid item xs={12} sx={{ display: currentTab === 0 ? 'flex' : 'none' }}>
            <AnalyticHomeTab onCardClick={handleCardClick} />
          </Grid>
          <Grid item xs={12} sx={{ display: currentTab === 1 ? 'flex' : 'none' }}>
            {tabsVisibility[1] && (
              <AnalyticsBPProvider>
                <AnalyticBPTab />
              </AnalyticsBPProvider>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: currentTab === 2 ? 'flex' : 'none' }}>
            {tabsVisibility[2] && (
              <AnalyticsDREProvider>
                <AnalyticDRETab />
              </AnalyticsDREProvider>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: currentTab === 3 ? 'flex' : 'none' }}>
            {tabsVisibility[3] && (
              <AnalyticsNCGProvider>
                <AnalyticNcgTab />
              </AnalyticsNCGProvider>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: currentTab === 4 ? 'flex' : 'none' }}>
            {tabsVisibility[4] && (
              <AnalyticsFcProvider>
                <AnalyticFcTab />
              </AnalyticsFcProvider>
            )}
          </Grid>
          <Grid item xs={12} sx={{ display: currentTab === 5 ? 'flex' : 'none' }}>
            {tabsVisibility[5] && (
              <SoldItemAnalyticContext>
                <AnalyticSoldItemTab />
              </SoldItemAnalyticContext>
            )}
          </Grid>
        </Suspense>
      </Grid>
    </FullScreenCard>
  );
};
