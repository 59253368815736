import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../../../components/basics/ControlledTextInput';
import { AnalyticsAccounts } from '../../../../../models/AnalyticsAccounts';
import { ChartAccounts } from '../../../../../models/ChartAccounts';
import { AnalyticsAccountService } from '../../../../../services/AnalyticsAccount.service';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { setInputErrorsFromApi } from '../../../../../utils/utils';

interface Props {
  parents?: SelectOption[];
  chartAccounts?: ChartAccounts;
  onClose: () => void;
  updateSaved: () => void;
}

export const AnalyticsAccountsFormDialog = ({ parents, chartAccounts, onClose, updateSaved }: Props) => {
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors }
  } = useForm<AnalyticsAccounts>({
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const createAnalyticsAccounts: SubmitHandler<AnalyticsAccounts> = async (data: AnalyticsAccounts) => {
    try {
      const payload = {
        ...data,
        parent: data.parent?.id,
        chart_accounts: chartAccounts?.id
      };

      await AnalyticsAccountService.create(payload);
      updateSaved();
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Conta criada com sucesso!',
          severity: 'success'
        })
      );
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    }
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Nova Conta
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createAnalyticsAccounts)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="parent">Conta Pai</InputLabel>
              <ControlledComboBox
                name="parent"
                control={control}
                placeholder="Selecione a Conta Pai"
                errorMessage={errors.parent?.message?.toString()}
                selectOptions={parents}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="code">Código</InputLabel>
              <ControlledTextInput
                name="code"
                placeholder="Código da Conta"
                control={control}
                errorMessage={errors.code?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="description">Descrição</InputLabel>
              <ControlledTextInput
                name="description"
                placeholder="Descrição da Conta"
                control={control}
                errorMessage={errors.description?.message?.toString()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
