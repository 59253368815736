import CloseIcon from '@mui/icons-material/Close';
import { Box, Divider, useTheme } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { ChartAccounts } from '../../../models/ChartAccounts';
import { ChartAccountConfigurationTab } from './ChartAccountConfigurationTab';

interface Props {
  item: ChartAccounts;
  onClose: () => void;
}

export const ChartAccountConfigurationFormDialog = ({ item, onClose }: Props) => {
  const theme = useTheme();

  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullScreen>
      <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', p: 2 }}>
        <DialogTitle variant="h4" color="primary">
          Plano de Contas: {item.description}
        </DialogTitle>
        <Button variant="text" color="primary" onClick={onClose} startIcon={<CloseIcon />}>
          Fechar
        </Button>
      </Box>
      <Divider />

      <DialogContent sx={{ flex: 1, backgroundColor: theme.palette.background.default }}>
        <ChartAccountConfigurationTab item={item} />
      </DialogContent>
    </Dialog>
  );
};
