import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import LockIcon from '@mui/icons-material/Lock';
import { Button, CardContent, CardHeader, Divider, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledTextInput } from '../../../../components/basics/ControlledTextInput';
import { User } from '../../../../models/User';
import { AuthService } from '../../../../services/Auth.service';
import { hideSpinner, showSpinner } from '../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../store/slicers/snackbarAlert.slicer';
import { CustomCard } from '../../../../themes/theme/GlobalStyledComponents';
import { UserScheme } from '../../../../utils/forms/validations/formValidations';
import { EditPasswordModal } from './EditPasswordModal';

export const TabProfile = () => {
  const dispatch = useDispatch();
  const [user, setUser] = useState<User>();
  const [showEditPasswordModal, setShowEditPasswordModal] = useState<boolean>(false);

  const {
    control,
    reset,
    handleSubmit,
    getValues,
    setError,
    formState: { errors, isDirty, isValid }
  } = useForm<User>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(UserScheme)
  });

  useEffect(() => {
    // TODO: O perfil do usuário está sendo carregado mais de uma vez
    fetchUserData();
  }, []);

  useEffect(() => {
    reset(user);
  }, [user]);

  const fetchUserData = async () => {
    try {
      dispatch(showSpinner());
      const response = await AuthService.getUserSessionData();
      if (response) {
        setUser(response.data);
      } else {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: 'Erro ao buscar dados do perfil.',
            severity: 'error'
          })
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar dados do perfil.',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const onSubmit = async (data: User) => {
    dispatch(showSpinner());
    const submitData = {
      ...user,
      username: data.username,
      email: data.email,
      first_name: data.first_name,
      last_name: data.last_name
    };
    try {
      await AuthService.update(submitData);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Perfil atualizado com sucesso!',
          severity: 'success'
        })
      );
      fetchUserData();
    } catch (error: any) {
      const fields = Object.keys(getValues());
      let nonFieldErrors: string[] = [];
      Object.keys(error?.data).forEach((key) => {
        if (fields.includes(key)) {
          // @ts-ignore
          setError(key, { message: error?.data[key] });
        } else {
          nonFieldErrors.push(error?.data[key]);
        }
      });
      if (nonFieldErrors.length > 0) {
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: nonFieldErrors.join('\n'),
            severity: 'error'
          })
        );
      }
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <CustomCard sx={{ mt: 4 }}>
      <EditPasswordModal showModal={showEditPasswordModal} hideModal={() => setShowEditPasswordModal(false)} />
      <CardContent>
        <CardHeader title="Dados Pessoais" subheader="Utilize os campos abaixo para modificar seus dados pessoais e de contato." />
        <form onSubmit={handleSubmit(onSubmit)}>
          <fieldset disabled={!user} style={{ border: 0 }}>
            <Grid container spacing={4} sx={{ px: 2 }}>
              <Grid item xs={12} md={6}>
                <ControlledTextInput
                  label="Username"
                  control={control}
                  name="username"
                  type="text"
                  variant="standard"
                  errorMessage={errors.username?.message?.toString()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ControlledTextInput
                  label="E-mail"
                  control={control}
                  name="email"
                  type="text"
                  variant="standard"
                  errorMessage={errors.email?.message?.toString()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ControlledTextInput
                  label="Nome"
                  control={control}
                  name="first_name"
                  type="text"
                  variant="standard"
                  errorMessage={errors.first_name?.message?.toString()}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <ControlledTextInput
                  label="Sobrenome"
                  control={control}
                  name="last_name"
                  type="text"
                  variant="standard"
                  errorMessage={errors.last_name?.message?.toString()}
                />
              </Grid>
              <Grid item xs={12} md={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" type="submit" startIcon={<CheckIcon />} disabled={!isDirty || !isValid}>
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </fieldset>
        </form>
        <Divider />
        <CardHeader title="Segurança" subheader="Utilize os campos abaixo para modificar informações de segurança do seu perfil." />
        <Grid container spacing={4} sx={{ px: 2 }}>
          <Grid item xs={12} md={12}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<LockIcon />}
              onClick={() => setShowEditPasswordModal(true)}
              disabled={!user}
            >
              Editar Senha
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </CustomCard>
  );
};
