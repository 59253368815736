import { useRoutes } from 'react-router-dom';

// project import
import MainRoutes from './authenticated.routes';
import LoginRoutes from './public.routes';

// ==============================|| ROUTING RENDER ||============================== //

export const Routes = () => {
  //se a url for '/', redireciona para '/inicio'
  if (window.location.pathname === '/') {
    window.location.pathname = '/inicio';
  }

  return useRoutes([LoginRoutes, MainRoutes]);
};
