import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { SyntheticEvent, useEffect, useState } from 'react';

// Define a base interface with id and name
export interface AutocompleteSearchOption {
  id: number;
  name: string;
}

interface Props<T extends AutocompleteSearchOption> {
  label?: string;
  defaultValue: T | null;
  selectOptions: T[];
  onSearch: (searchValue: string) => Promise<void>;
  onSelect: (event: SyntheticEvent<Element, Event>, value: T | null) => void;
  disabled?: boolean;
  errorMessage?: string;
}

export const AutocompleteSearch = <T extends AutocompleteSearchOption>({
  label,
  defaultValue,
  selectOptions,
  onSearch,
  onSelect,
  errorMessage,
  ...props
}: Props<T>) => {
  const [inputValue, setInputValue] = useState<string | undefined>();
  const [selectedValue, setSelectedValue] = useState<T | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [searchResults, setSearchResults] = useState<T[]>(selectOptions || []);

  const handleInputChange = async (event: any, newInputValue: string) => {
    setInputValue(newInputValue);
  };

  const handleSelectChange = (event: SyntheticEvent<Element, Event>, value: T | null) => {
    setSelectedValue(value);
    onSelect(event, value);
  };

  useEffect(() => {
    const debounce = setTimeout(async () => {
      if (inputValue && inputValue !== selectedValue?.name) {
        setLoading(true);
        await onSearch(inputValue);
        setLoading(false);
      }
    }, 500);
    return () => clearTimeout(debounce);
  }, [inputValue, selectedValue?.name]);

  useEffect(() => {
    setSearchResults(selectOptions);
  }, [selectOptions]);

  return (
    <Autocomplete
      {...props}
      size="small"
      options={searchResults || []}
      getOptionLabel={(option) => option.name || ''}
      multiple={false}
      noOptionsText={inputValue ? 'Nenhum resultado encontrado' : 'Digite para pesquisar'}
      loading={loading}
      loadingText="Carregando..."
      onInputChange={handleInputChange}
      defaultValue={defaultValue}
      onChange={handleSelectChange}
      value={selectedValue}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          variant="outlined"
          placeholder="Digite para pesquisar"
          error={!!errorMessage}
          helperText={errorMessage}
        />
      )}
    />
  );
};
