import VisibilityIcon from '@mui/icons-material/Visibility';
import {
  Box,
  Button,
  IconButton,
  Paper,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StyledTableRow } from '../../../../../components/basics/StyledTableRow';
import { PermissionGroup } from '../../../../../models/PermissionGroup';
import { User } from '../../../../../models/User';
import { PermissionGroupsService } from '../../../../../services/PermissionGroups.service';
import { UsersService } from '../../../../../services/Users.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { RootState } from '../../../../../store/store';
import { checkResponseStatus } from '../../../../../utils/api/response';
import { PermissionGroupsDetailsDialog } from '../../../../@permission-groups-page/PermissionGroupsDetailsDialog';

interface Props {
  user: User;
  onClose: () => void;
}

interface PermissionGroupForm extends PermissionGroup {
  isActive: boolean;
}

export const PermissionGroupsTab = ({ user, onClose }: Props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [userPermissionData, setUserPermissionData] = useState<PermissionGroupForm[]>([]);
  const [profiles, setProfiles] = useState<PermissionGroupForm[]>([]);
  const [profileDetails, setProfileDetails] = useState<PermissionGroup | null>(null);

  const { loading } = useSelector((state: RootState) => state.globalSpinner);

  const handleSwitchChange = (groupId: number, checked: boolean) => {
    const updatedGroups = profiles.map((group) => {
      if (group.id === groupId) {
        return {
          ...group,
          isActive: checked
        };
      }
      return group;
    });
    setProfiles(updatedGroups);
  };

  const onSubmit = async () => {
    const groupsToSave = profiles.filter((group) => group.isActive);
    let groupsIds = groupsToSave.map((group) => group.id?.toString()) as string[];

    dispatch(showSpinner());
    try {
      const response = await UsersService.update({
        id: userPermissionData.id,
        profiles: groupsIds
      });
      if (checkResponseStatus(response)) {
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso',
            message: 'Permissões do usuário atualizadas com sucesso',
            severity: 'success'
          })
        );
        onClose();
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao atualizar permissões do usuário',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleShowProfileDetails = (profile: PermissionGroup) => {
    setProfileDetails(profile);
  };

  const fetchUserPermissions = async () => {
    dispatch(showSpinner());
    try {
      const response = await UsersService.retrieve(user.id);
      if (checkResponseStatus(response)) {
        let userPermission = response?.data;
        setUserPermissionData(userPermission);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: error.data.detail,
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const fetchGroups = async () => {
    try {
      const response = await PermissionGroupsService.getAll();
      if (response?.data?.results) {
        const profiles = response.data.results;
        const profilesWithActive = profiles.map((group: any) => {
          const isActive = userPermissionData.profiles?.some((permissionGroup: PermissionGroup) => permissionGroup.id === group.id);
          return {
            ...group,
            isActive
          };
        });
        setProfiles(profilesWithActive);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar perfis',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  useEffect(() => {
    if (user?.id) {
      fetchUserPermissions();
    }
  }, [user]);

  useEffect(() => {
    if (userPermissionData?.id) {
      fetchGroups();
    }
  }, [userPermissionData]);

  return (
    <>
      {!loading && (
        <>
          <Box sx={{ display: 'flex', flexDirection: 'column', px: 4, pt: 1 }}>
            <Box>
              <Typography variant="h5">Selecione os perfis que o usuário terá acesso.</Typography>
              <Typography variant="body1" sx={{ color: theme.palette.grey[500] }}>
                Toque no botão para ativar ou desativar um perfil para o usuário. <br /> Você pode ver as permissões do perfil clicando em
                detalhes.
              </Typography>
              <TableContainer component={Paper} sx={{ mt: 6, borderRadius: '4px', p: 1 }} elevation={1}>
                <Table aria-label="simple table" size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                          Perfil
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                          Situação
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="h5" sx={{ textDecoration: 'underline' }}>
                          Detalhes
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {profiles.map((profile) => (
                      <StyledTableRow key={profile.id}>
                        <TableCell>
                          <Typography variant="h6">{profile.name}</Typography>
                        </TableCell>
                        <TableCell sx={{ display: 'flex', alignItems: 'center' }}>
                          <Switch
                            checked={profile.isActive}
                            onChange={() => handleSwitchChange(profile.id, !profile.isActive)}
                            inputProps={{ 'aria-label': 'controlled' }}
                          />
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleShowProfileDetails(profile)}>
                            <Tooltip title="Toque para visualizar o perfil">
                              <VisibilityIcon />
                            </Tooltip>
                          </IconButton>
                        </TableCell>
                      </StyledTableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
            <Stack flexDirection="row" columnGap={2} justifyContent="flex-end" mt={2}>
              <Button variant="contained" color="primary" onClick={onSubmit}>
                Salvar
              </Button>
              <Button variant="outlined" color="primary" onClick={onClose}>
                Cancelar
              </Button>
            </Stack>
          </Box>
          {profileDetails?.id && <PermissionGroupsDetailsDialog item={profileDetails} onClose={() => setProfileDetails(null)} />}
        </>
      )}
    </>
  );
};
