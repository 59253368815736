import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { PermissionsObj, useCrudPermissions } from '../../../hooks/useCrudPermissions';

interface AnalyticContextData {
  currentTab: number;
  handleChangeTab: (newValue: number) => void;
  fullScreenMode: boolean;
  handleToggleFullScreen: () => void;
  manualAccountingPermission: PermissionsObj;
}

const AnalyticContext = createContext<AnalyticContextData>({} as AnalyticContextData);

interface Props {
  children: React.ReactNode;
}

export const AnalyticFormContext = ({ children }: Props) => {
  const { permissions } = useCrudPermissions({ submodule: 'MANUAL_ACCOUNTING_ENTRY' });

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [fullScreenMode, setFullScreenMode] = useState(false);

  const handleToggleFullScreen = useCallback(() => {
    setFullScreenMode((prevState) => !prevState);
  }, []);

  const handleChangeTab = (newValue: number) => {
    setCurrentTab(newValue);
  };

  const value = useMemo(() => {
    return {
      currentTab,
      fullScreenMode,
      handleToggleFullScreen,
      handleChangeTab,
      manualAccountingPermission: permissions
    };
  }, [currentTab, fullScreenMode, handleToggleFullScreen, handleChangeTab]);

  return <AnalyticContext.Provider value={value}>{children}</AnalyticContext.Provider>;
};

export const useAnalyticFormContext = () => {
  const context = useContext(AnalyticContext);
  if (!context) {
    throw new Error('useAnalyticFormContext must be used within an AnalyticFormContext Provider');
  }
  return context;
};
