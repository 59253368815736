import { Box, Stack } from '@mui/material';
import { styled } from 'styled-components';

export const AccordionRow = styled(Stack)`
  display: flex;
  flex: 1;
  flex-direction: row;
`;

export const BlockContainer = styled(Box)`
  display: flex;
  flex: 1;
  align-items: center;
  font-weight: bold;
`;

export const RegistryContainer = styled(Box)`
  display: flex;
  flex: 1;
  align-items: center;
  padding-right: 2;
`;
