import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import Person2OutlinedIcon from '@mui/icons-material/Person2Outlined';
import { INavGroup } from '../models/Menu';
import { ROUTES } from '../routes/routes';

export const AccessRoutes: INavGroup = {
  id: 'group-access',
  title: 'Acesso',
  icon: <Person2OutlinedIcon />,
  type: 'group',
  module: 'ACCESS',
  level: 1,
  children: [
    {
      id: 'group-permissions',
      title: 'Perfis',
      type: 'item',
      url: ROUTES.authenticated.access.permissionGroups,
      icon: <GroupsOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'GROUP'
    },
    {
      id: 'Users',
      title: 'Usuários',
      type: 'item',
      url: ROUTES.authenticated.access.users,
      icon: <ManageAccountsOutlinedIcon />,
      breadcrumbs: true,
      submodule: 'USER' // Módulo para verificar permissão
    }
  ]
};
