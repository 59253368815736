import { CompaniesGroupsPage } from '../../../sections/@companies-group/CompaniesGroupPage';
import { CompaniesGroupContext } from '../../../sections/@companies-group/context/CompaniesGroupContext';

export const CompaniesGroupsPageWrapper = () => {
  return (
    <CompaniesGroupContext>
      <CompaniesGroupsPage />
    </CompaniesGroupContext>
  );
};
