import AddIcon from '@mui/icons-material/Add';
import { Button, Tooltip } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

import { useForm, useWatch } from 'react-hook-form';
import { useCrudPermissions } from '../../../hooks/useCrudPermissions';
import { PermissionGroup } from '../../../models/PermissionGroup';
import { PermissionGroupsDetailsDialog } from '../PermissionGroupsDetailsDialog';
import { PermissionGroupsFilterDialog } from '../PermissionGroupsFilterDialog';
import { PermissionGroupsFormDialog } from '../PermissionGroupsFormDialog';
import { PermissionGroupsTable } from '../PermissionGroupsTable';
import { usePermissionGroupsPageContext } from '../context/PermissionGroupsContext';
import { ButtonsContainer } from './styles';

export const PermissionGroupsPage = () => {
  const { permissions } = useCrudPermissions({
    submodule: 'GROUP'
  });

  const [showFilter, setShowFilter] = useState(false);
  const [newItem, setNewItem] = useState(false);
  const [editItem, setEditItem] = useState<PermissionGroup | undefined>(undefined);
  const [viewItem, setViewItem] = useState<PermissionGroup | undefined>(undefined);

  const { control } = useForm();
  const watchSearch = useWatch({
    control,
    name: 'search'
  });

  const { filterFields, setFilterFields } = usePermissionGroupsPageContext();

  const handleAddPermissionGroup = () => {
    setNewItem(true);
  };

  const handleViewItem = (item: PermissionGroup) => {
    setViewItem(item);
  };

  const handleEditItem = (item: PermissionGroup) => {
    setEditItem(item);
  };

  const handleShowFilter = () => {
    setShowFilter(true);
  };

  const handleCloseDialogs = () => {
    setShowFilter(false);
    setNewItem(false);
    setEditItem(undefined);
    setViewItem(undefined);
  };

  useEffect(() => {
    if (watchSearch) {
      const debounced = debounce(() => {
        setFilterFields({
          ...filterFields,
          search: watchSearch
        });
      }, 500);
      debounced();
      return debounced.cancel;
    } else {
      setFilterFields({
        ...filterFields,
        search: undefined
      });
    }
  }, [watchSearch]);

  return (
    <>
      <ButtonsContainer>
        {permissions.hasCreatePermission && (
          <Tooltip title="Criar Perfil">
            <Button startIcon={<AddIcon />} variant="contained" onClick={handleAddPermissionGroup}>
              Adicionar
            </Button>
          </Tooltip>
        )}
      </ButtonsContainer>
      {permissions.hasReadPermission && <PermissionGroupsTable onView={handleViewItem} onEdit={handleEditItem} permissions={permissions} />}
      {showFilter && <PermissionGroupsFilterDialog onClose={handleCloseDialogs} />}
      {viewItem && <PermissionGroupsDetailsDialog item={viewItem} onClose={handleCloseDialogs} />}
      {(newItem || editItem) && <PermissionGroupsFormDialog editItem={editItem} onClose={handleCloseDialogs} />}
    </>
  );
};
