import dayjs from 'dayjs';
import { FieldName } from 'react-hook-form';
import { SelectOption } from '../components/basics/ControlledComboBox';

export const formatDateToApi = (date: Date | string) => {
  if (date) {
    return dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }
  return dayjs(new Date()).format('YYYY-MM-DD');
};

export function formatDate(dateString: string): string {
  const date: Date = new Date(dateString);

  const formattedDate: string = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`;

  return formattedDate;
}

export function formatSimpleDate(dateString: string): string {
  const date: Date = new Date(dateString + ' 00:00:00');
  const formattedDate: string = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}/${date.getFullYear()}`;

  return formattedDate;
}

export function formatTime(dateString: string): string {
  const date: Date = new Date(dateString);

  const formattedTime: string = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;

  return formattedTime;
}

export function formatDateTime(dateString: string): string {
  const formattedDate: string = formatDate(dateString);
  const formattedTime: string = formatTime(dateString);

  const formattedDateTime: string = `${formattedDate} - ${formattedTime}`;

  return formattedDateTime;
}

export function setInputErrorsFromApi<T>(setError, errorsObject) {
  let formError: string[] = [];

  if (typeof errorsObject !== 'object') {
    return ['Ocorreu um erro interno.'];
  }

  Object.keys(errorsObject).forEach((key) => {
    const keyOf = key as FieldName<T>;
    if (!['detail', 'non_field_errors'].includes(keyOf)) {
      setError(keyOf, { message: errorsObject[key][0], type: 'manual' });
    } else {
      let errors = errorsObject[key];
      if (!Array.isArray(errors)) errors = [errors];

      formError = formError.concat(errors);
    }
  });

  if (formError.length > 0) return formError;
}

export function findSelectedOption(options: SelectOption[], query: any): SelectOption | undefined {
  const selectedOption = options?.find((option) => option.id == query);

  return selectedOption as SelectOption;
}

export function findSelectedOptions(options: SelectOption[], queries?: any[]): SelectOption[] {
  if (!queries || queries?.length === 0) {
    return [];
  }

  const queryIds = queries?.map((query) => query?.id);
  const foundOptions = options?.filter((item) => queryIds?.includes(item.id));

  return foundOptions;
}

export const validateCpf = (cpf: string) => {
  cpf = cpf.replace(/[^\d]+/g, ''); // Remove caracteres não numéricos

  if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
    return true;
  }

  const digitoVerificador = cpf.substring(9);
  let soma = 0;

  for (let i = 0; i < 9; i++) {
    soma += parseInt(cpf.charAt(i)) * (10 - i);
  }

  let resto = soma % 11;
  const primeiroDigito = resto < 2 ? 0 : 11 - resto;

  if (parseInt(digitoVerificador.charAt(0)) !== primeiroDigito) {
    return false;
  }

  soma = 0;

  for (let i = 0; i < 10; i++) {
    soma += parseInt(cpf.charAt(i)) * (11 - i);
  }

  resto = soma % 11;
  const segundoDigito = resto < 2 ? 0 : 11 - resto;

  if (parseInt(digitoVerificador.charAt(1)) !== segundoDigito) {
    return false;
  }

  return true;
};

export const validateCnpj = (cnpj) => {
  // Remove caracteres não numéricos
  cnpj = cnpj.replace(/[^\d]/g, '');

  // Verifica se o CNPJ tem 14 dígitos
  if (cnpj.length !== 14) {
    return false;
  }

  // Verifica se todos os dígitos são iguais (caso contrário, passaria na validação do tamanho)
  if (/^(\d)\1+$/.test(cnpj)) {
    return false;
  }

  // Calcula os dígitos verificadores
  const calcDigit = (str, factor) => {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
      sum += parseInt(str.charAt(i), 10) * factor--;
      if (factor < 2) {
        factor = 9;
      }
    }
    const remainder = sum % 11;
    return remainder < 2 ? 0 : 11 - remainder;
  };

  const firstDigit = calcDigit(cnpj.substring(0, 12), 5);
  const secondDigit = calcDigit(cnpj.substring(0, 13), 6);

  // Verifica se os dígitos verificadores calculados são iguais aos dígitos do CNPJ
  if (parseInt(cnpj.charAt(12), 10) !== firstDigit || parseInt(cnpj.charAt(13), 10) !== secondDigit) {
    return false;
  }

  return true;
};

export const hexToRgba = (hex: string, alpha: number): string => {
  // Remova o caractere '#' do início, se presente.
  hex = hex.replace('#', '');

  // Converta os valores de cor em números inteiros.
  const r = parseInt(hex.slice(0, 2), 16);
  const g = parseInt(hex.slice(2, 4), 16);
  const b = parseInt(hex.slice(4, 6), 16);

  // Garanta que o valor alfa esteja no intervalo válido.
  alpha = Math.min(1, Math.max(0, alpha));

  // Crie a representação RGBA da cor.
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const formatCurrency = (value: number | string) => {
  if (typeof value === 'string') {
    value = value.replace(',', '.');
  }

  const numericValue = parseFloat(value as string);
  if (isNaN(numericValue)) {
    return '';
  }

  return numericValue.toLocaleString('pt-BR', {
    style: 'currency',
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};

export const formatReportValue = (value: number) => {
  const formattedValue = new Intl.NumberFormat('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);

  return formattedValue;
};

export const toTitleCase = (str: string) => {
  return str.replace(/\w\S*/g, (txt) => {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
};

export const formatColumnNameIntoDate = (columnName: string, abbreviate: boolean = false) => {
  let monthNames;
  let semiAnnualLabel;
  let quarterlyLabel;
  if (abbreviate) {
    monthNames = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'];
    semiAnnualLabel = 'Sem';
    quarterlyLabel = 'Tri';
  } else {
    monthNames = [
      'Janeiro',
      'Fevereiro',
      'Março',
      'Abril',
      'Maio',
      'Junho',
      'Julho',
      'Agosto',
      'Setembro',
      'Outubro',
      'Novembro',
      'Dezembro'
    ];
    semiAnnualLabel = 'Semestre';
    quarterlyLabel = 'Trimestre';
  }

  if (columnName.endsWith('Y')) {
    return `${columnName.slice(0, 4)}`;
  } else if (columnName.includes('M')) {
    const year = columnName.slice(0, 4);
    const month = parseInt(columnName.slice(5), 10);
    return `${monthNames[month - 1]}/${year}`;
  } else if (columnName.includes('S')) {
    const year = columnName.slice(0, 4);
    const semester = columnName.slice(5);
    return `${semester}° ${semiAnnualLabel}/${year}`;
  } else if (columnName.includes('Q')) {
    const year = columnName.slice(0, 4);
    const quarter = columnName.slice(5);
    return `${quarter}° ${quarterlyLabel}/${year}`;
  }
  return columnName;
};

export const getColumnYear = (column: string) => {
  return column.substring(0, 4);
};

export const getStartAndFinalDate = (entryDate: string) => {
  let startDate: string;
  let finalDate: string;

  if (entryDate.endsWith('Y')) {
    const year = entryDate.slice(0, 4);
    startDate = `${year}-01-01`;
    finalDate = `${year}-12-31`;
  } else if (entryDate.includes('S')) {
    const year = entryDate.slice(0, 4);
    const semester = entryDate.slice(5);
    if (semester === '1') {
      startDate = `${year}-01-01`;
      finalDate = `${year}-06-30`;
    } else {
      startDate = `${year}-07-01`;
      finalDate = `${year}-12-31`;
    }
  } else if (entryDate.includes('Q')) {
    const year = entryDate.slice(0, 4);
    const quarter = entryDate.slice(5);
    switch (quarter) {
      case '1':
        startDate = `${year}-01-01`;
        finalDate = `${year}-03-31`;
        break;
      case '2':
        startDate = `${year}-04-01`;
        finalDate = `${year}-06-30`;
        break;
      case '3':
        startDate = `${year}-07-01`;
        finalDate = `${year}-09-30`;
        break;
      case '4':
        startDate = `${year}-10-01`;
        finalDate = `${year}-12-31`;
        break;
      default:
        startDate = `${year}-01-01`;
        finalDate = `${year}-03-31`;
    }
  } else if (entryDate.includes('M')) {
    const year = entryDate.slice(0, 4);
    const month = entryDate.slice(5).padStart(2, '0');
    startDate = `${year}-${month}-01`;
    finalDate = new Date(Number(year), Number(month), 0).toISOString().split('T')[0];
  } else {
    throw new Error('Formato de data inválido.');
  }
  return { startDate, finalDate };
};

export const monthMapping: { [key: string]: number } = {
  january: 1,
  february: 2,
  march: 3,
  april: 4,
  may: 5,
  june: 6,
  july: 7,
  august: 8,
  september: 9,
  october: 10,
  november: 11,
  december: 12
};

export const labelDisplayedRows = ({ from, to, count }) => {
  return `${from} - ${to} de ${count !== -1 ? count : `mais de ${to}`}`;
};

export const formatNegativeValue = (value: number): string => {
  const formattedValue = Math.abs(value).toLocaleString('pt-BR', { minimumFractionDigits: 0 });
  return value < 0 ? `(${formattedValue})` : formattedValue;
};

export const getPeriodTypeName = (columnName: string) => {
  if (columnName.includes('S')) {
    return 'Sem.';
  } else if (columnName.includes('Q')) {
    return 'Tri.';
  } else {
    return 'Mês';
  }
};
