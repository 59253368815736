import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Company } from '../../../models/Company';
import { CompanyGroup } from '../../../models/CompanyGroup';
import { CompanyType } from '../../../models/CompanyType';
import { CompaniesService } from '../../../services/Companies.service';
import { CompaniesGroupsService } from '../../../services/CompaniesGroups.service';
import { CompaniesTypesService } from '../../../services/CompaniesTypes.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { CompanyScheme } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi, validateCnpj } from '../../../utils/utils';
import { useCompaniesPageContext } from '../context/CompaniesPageContext';

interface Props {
  editItem?: Company;
  onClose: () => void;
}

export const CompanyFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const { fetchCompanies } = useCompaniesPageContext();

  const [loading, setLoading] = useState<boolean>(true);
  const [groups, setGroups] = useState<SelectOption[]>([]);
  const [types, setTypes] = useState<SelectOption[]>([]);

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors }
  } = useForm<Company>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(CompanyScheme),
    defaultValues: editItem ?? {
      company_name: '',
      trade_name: '',
      customer_id_fiscal_driver: '',
      is_active: true,
      cnpj: ''
    }
  });

  const watchStatus = useWatch({
    control,
    name: 'is_active',
    defaultValue: getValues('is_active')
  });

  const createOrUpdateCompany: SubmitHandler<Company> = async (data: Company) => {
    if (validateCnpj(data.cnpj)) {
      dispatch(showSpinner());
      data.cnpj = data.cnpj.replace(/[^\d]/g, '');
      try {
        const payload = {
          ...data,
          group: data.group.id,
          types: data.types?.map((types) => types.id),
          customer_id_fiscal_driver: data.customer_id_fiscal_driver
        } as Company;

        editItem?.id ? await CompaniesService.update(payload) : await CompaniesService.create(payload);
        dispatch(
          showSnackbarAlert({
            title: 'Sucesso',
            message: editItem?.id ? 'Empresa atualizada com sucesso!' : 'Empresa criada com sucesso!',
            severity: 'success'
          })
        );
        fetchCompanies();
        onClose();
      } catch (error: any) {
        const formError = setInputErrorsFromApi(setError, error.data);
        dispatch(
          showSnackbarAlert({
            title: 'Erro',
            message: formError?.join(','),
            severity: 'error'
          })
        );
      } finally {
        dispatch(hideSpinner());
      }
    } else {
      setError('cnpj', {
        type: 'format',
        message: 'Por favor, insira um CNPJ válido'
      });
    }
  };

  const handleClose = () => {
    onClose();
  };

  const fetchGroups = async () => {
    try {
      const response = await CompaniesGroupsService.get({ is_active: true });
      if (response) {
        const result = response?.data?.results;
        setGroups(
          result.map((group: CompanyGroup) => ({
            id: group.id,
            name: group.name
          }))
        );
      }
    } catch (error) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar grupos de empresas',
          severity: 'error'
        })
      );
    }
  };

  const fetchTypes = async () => {
    try {
      const response = await CompaniesTypesService.get({ is_active: true });
      if (response) {
        const result = response?.data?.results;
        setTypes(
          result.map((type: CompanyType) => ({
            id: type.id,
            name: type.name
          }))
        );
      }
    } catch (error) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar tipos de empresas',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    const loadData = async () => {
      dispatch(showSpinner());
      setLoading(true);

      await Promise.all([fetchGroups(), fetchTypes()]);

      setLoading(false);
      dispatch(hideSpinner());
    };

    loadData();
  }, []);

  return (
    <Dialog open={!loading} onClose={handleClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Nova'} Empresa
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createOrUpdateCompany)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <InputLabel htmlFor="company_name">Razão Social</InputLabel>
              <ControlledTextInput
                name="company_name"
                placeholder="Razão Social"
                control={control}
                errorMessage={errors.company_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="trade_name">Nome Fantasia</InputLabel>
              <ControlledTextInput
                placeholder="Nome Fantasia"
                name="trade_name"
                control={control}
                errorMessage={errors.trade_name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="cnpj">CNPJ</InputLabel>
              <ControlledTextInput
                mask="99.999.999/9999-99"
                placeholder="CNPJ"
                name="cnpj"
                control={control}
                errorMessage={errors?.cnpj?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="group">Grupo de Empresa</InputLabel>
              <ControlledComboBox name="group" control={control} errorMessage={errors.group?.message?.toString()} selectOptions={groups} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="types">Tipo</InputLabel>
              <ControlledComboBox
                multiple
                name="types"
                control={control}
                errorMessage={errors.types?.message?.toString()}
                selectOptions={types}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="customer_id_fiscal_driver">Id Cliente - Fiscal Driver</InputLabel>
              <ControlledTextInput
                placeholder="Id Cliente"
                name="customer_id_fiscal_driver"
                control={control}
                errorMessage={errors.customer_id_fiscal_driver?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Situação:</Typography>
              <ControlledCheckbox name="is_active" label={watchStatus ? 'Ativo' : 'Inativo'} control={control} />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={handleClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit" onClick={handleSubmit(createOrUpdateCompany)}>
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
