import { TableHead, TableRow, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import { StyledTableRow } from '../../../../../../../../components/basics/StyledTableRow';
import { formatColumnNameIntoDate, formatNegativeValue } from '../../../../../../../../utils/utils';

interface GraphComponentBpProps {
  data: {
    data: {
      metadata: {
        title: string;
        years: string[];
        columns: string[];
      };
      report: Array<{
        label: string;
        type: string;
        data: Array<{
          period: string;
          entry_value: number;
        }>;
      }>;
    };
  };
}

export const GraphTableBP = ({ data }: GraphComponentBpProps) => {
  const { metadata, report } = data.data;
  const { columns } = metadata;

  const formattedColumns = columns.map((column) => formatColumnNameIntoDate(column, true));

  return (
    <Box sx={{ width: '100%', border: 1, borderColor: 'divider' }}>
      <Paper sx={{ width: '100%' }} elevation={0}>
        <TableContainer sx={{ overflowX: 'auto' }}>
          <Table sx={{ minWidth: 700 }} aria-labelledby="tableTitle" size={'small'}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Análise
                  </Typography>
                </TableCell>
                {formattedColumns.map((column, index) => (
                  <TableCell key={index}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', minWidth: 80 }}>
                      {column}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {report.map((item, rowIndex) => (
                <StyledTableRow key={rowIndex} role="checkbox" sx={{ cursor: 'pointer' }}>
                  <TableCell align="left">{item.label}</TableCell>
                  {item.data.map((dataPoint, colIndex) => (
                    <TableCell key={colIndex} align="left">
                      {formatNegativeValue(dataPoint.entry_value)}
                    </TableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Box>
  );
};
