import { Box, Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import styled from 'styled-components';

export const ContainerCentered = styled(Box)`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

export const ContainerRow = styled(Box)`
  display: flex;
  flex-direction: row;
`;

export const ContainerColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const CustomCard = styled(Card)`
  display: flex;
  flex-direction: column;
  flex: 1;
  border: 0.5px solid ${({ theme }) => theme.palette.divider};
  transition: transform 0.3s ease-in-out;

  //mouse over pointer
  cursor: pointer;
`;

export const VerticalMargin = styled(Box)`
  margin: 1rem 0rem;
`;

export const HorizontalMargin = styled(Box)`
  margin: 0rem 1rem;
`;

export const MarginTop = styled(Box)`
  margin-top: 1rem;
`;

export const MarginBottom = styled(Box)`
  margin-bottom: 1rem;
`;

export const MarginLeft = styled(Box)`
  margin-left: 1rem;
`;

export const MarginRight = styled(Box)`
  margin-right: 1rem;
`;

export const DefaultLabel = styled(Typography).attrs({
  variant: 'h5'
})``;

export const DefaultText = styled(Typography).attrs({
  variant: 'body1'
})``;

interface IPageTitleProps {
  ismobile?: boolean;
}

export const PageTitle = styled(Typography).attrs({
  variant: 'h4'
})<IPageTitleProps>`
  display: flex;
  justify-content: ${({ ismobile }) => (ismobile ? 'center' : 'flex-start')};
  align-items: center;
  color: ${({ theme }) => theme.palette.primary.main};
  margin-bottom: 2rem;
`;
