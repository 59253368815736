// project import
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import 'dayjs/locale/pt-br';
import ScrollTop from './components/@extended/ScrollTop';
import { GlobalSpinner } from './components/basics/GlobalSpinner';
import { SnackbarAlertComponent } from './components/basics/SnackbarAlertComponent';
import { AuthContextProvider } from './context/AuthContextProvider';
import { Routes } from './routes';

import ThemeCustomization from './themes';

import { H } from 'highlight.run';

H.init(process.env.REACT_APP_HIGHTLIGHT_SERVER_KEY || '', {
  serviceName: 'frontend-app',
  tracingOrigins: true,
  networkRecording: {
    enabled: true,
    recordHeadersAndBody: true,
    urlBlocklist: [
      // insert full or partial urls that you don't want to record here
      // Out of the box, Highlight will not record these URLs (they can be safely removed):
      'https://www.googleapis.com/identitytoolkit',
      'https://securetoken.googleapis.com'
    ]
  }
});

const App = () => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-BR">
      <ThemeCustomization>
        <ScrollTop>
          <AuthContextProvider>
            <GlobalSpinner />
            <SnackbarAlertComponent />
            <Routes />
          </AuthContextProvider>
        </ScrollTop>
      </ThemeCustomization>
    </LocalizationProvider>
  );
};

export default App;
