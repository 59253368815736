import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { CompanyType } from '../../../models/CompanyType';
import { CompaniesTypesService } from '../../../services/CompaniesTypes.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { CompanyTypeScheme } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useCompaniesTypesContext } from '../context/CompaniesTypesContext';

interface Props {
  editItem?: CompanyType;
  onClose: () => void;
}

export const CompanyTypeFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const { fetchCompaniesTypes } = useCompaniesTypesContext();

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors }
  } = useForm<CompanyType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(CompanyTypeScheme),
    defaultValues: editItem ?? {
      name: '',
      is_active: true
    }
  });

  const watchStatus = useWatch({
    control,
    name: 'is_active',
    defaultValue: getValues('is_active')
  });

  const createOrUpdateCompanyType: SubmitHandler<CompanyType> = async (data: CompanyType) => {
    dispatch(showSpinner());
    try {
      editItem?.id ? await CompaniesTypesService.update(data) : await CompaniesTypesService.create(data);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Tipo de Empresa atualizada com sucesso!' : 'Tipo de Empresa criada com sucesso!',
          severity: 'success'
        })
      );
      fetchCompaniesTypes();
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Tipo de Empresa
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createOrUpdateCompanyType)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="name">Nome</InputLabel>
              <ControlledTextInput
                name="name"
                placeholder="Nome para o grupo de empresas"
                control={control}
                errorMessage={errors.name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Situação:</Typography>
              <ControlledCheckbox name="is_active" label={watchStatus ? 'Ativo' : 'Inativo'} control={control} />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
