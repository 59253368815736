import { Card as MuiCard } from '@mui/material';
import { styled } from 'styled-components';

interface FullScreenCardProps {
  fullScreen: boolean;
}

export const FullScreenCard = styled(MuiCard)<FullScreenCardProps>`
  position: ${(props) => (props.fullScreen ? 'fixed' : 'relative')};
  top: ${(props) => (props.fullScreen ? '0' : 'auto')};
  left: ${(props) => (props.fullScreen ? '0' : 'auto')};
  width: ${(props) => (props.fullScreen ? '100vw' : 'auto')};
  height: ${(props) => (props.fullScreen ? '100vh' : 'auto')};
  background-color: white;
  z-index: ${(props) => (props.fullScreen ? '1299' : '1')};
  overflow-y: ${(props) => (props.fullScreen ? 'auto' : 'visible')};
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
`;
