import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledCheckbox } from '../../../components/basics/ControlledCheckbox';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { Analytics } from '../../../models/Analytics';
import { Block } from '../../../models/Block';
import { ChartAccounts } from '../../../models/ChartAccounts';
import { CompanyGroup } from '../../../models/CompanyGroup';
import { AnalyticsService } from '../../../services/Analytics.service';
import { BlockRegistryService } from '../../../services/BlockRegistries.service';
import { ChartAccountsService } from '../../../services/ChartAccounts.service';
import { CompaniesGroupsService } from '../../../services/CompaniesGroups.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { CompanyGroupScheme } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useCompaniesGroupsContext } from '../context/CompaniesGroupContext';

interface Props {
  editItem?: CompanyGroup;
  onClose: () => void;
}

export const CompanyGroupFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const { fetchCompaniesGroups } = useCompaniesGroupsContext();
  const [registriesEcd, setRegistriesEcd] = useState<Block[]>([]);
  const [chartAccounts, setChartAccounts] = useState<SelectOption[]>([]);
  const [analytics, setAnalytics] = useState<SelectOption[]>([]);

  const getDefaultValues = (): CompanyGroup => {
    return {
      id: editItem?.id ?? 0,
      name: editItem?.name ?? '',
      is_active: editItem?.is_active ?? true,
      use_budget: editItem?.use_budget ?? true,
      use_cost_center: editItem?.use_cost_center ?? true,
      chart_accounts: editItem?.chart_accounts
        ? ({ id: editItem.chart_accounts.id, name: editItem.chart_accounts.description } as SelectOption)
        : null,
      dre_avg_analytics: editItem?.dre_avg_analytics
        ? ({ id: editItem.dre_avg_analytics.id, name: editItem.dre_avg_analytics.description } as SelectOption)
        : null
    };
  };

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    formState: { errors }
  } = useForm<CompanyGroup>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(CompanyGroupScheme),
    defaultValues: getDefaultValues()
  });

  const selectedChartAccount = useWatch({
    control,
    name: 'chart_accounts'
  });

  const watchStatus = useWatch({
    control,
    name: 'is_active',
    defaultValue: getValues('is_active')
  });

  const watchStatusBudget = useWatch({
    control,
    name: 'use_budget',
    defaultValue: getValues('use_budget')
  });

  const watchStatusCostCenter = useWatch({
    control,
    name: 'use_cost_center',
    defaultValue: getValues('use_cost_center')
  });

  useEffect(() => {
    if (!editItem?.id) {
      fetchEcdBlockRegistry();
    }
    fetchChartAccounts();
  }, []);

  useEffect(() => {
    fetchAnalytics(selectedChartAccount?.id);
  }, [selectedChartAccount]);

  const fetchEcdBlockRegistry = async () => {
    try {
      const response = await BlockRegistryService.get();
      if (response?.data?.results) {
        const blockData = response?.data?.results;
        setRegistriesEcd(blockData);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Blocos e Registros',
          severity: 'error'
        })
      );
    }
  };

  const fetchChartAccounts = async () => {
    try {
      const response = await ChartAccountsService.getAll();
      if (response) {
        const result = response?.data?.results;
        setChartAccounts(
          result.map((type: ChartAccounts) => ({
            id: type.id,
            name: type.description
          }))
        );
      }
    } catch (error) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar tipos de empresas',
          severity: 'error'
        })
      );
    }
  };

  const fetchAnalytics = async (chartAccountId = null) => {
    try {
      const response = await AnalyticsService.get({ level: 1, type: 'DRE', chart_accounts: chartAccountId });
      if (response) {
        const result = response?.data?.results;
        setAnalytics(
          result.map((type: Analytics) => ({
            id: type.id,
            name: type.description
          }))
        );
      }
    } catch (error) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar análises',
          severity: 'error'
        })
      );
    }
  };

  const createOrUpdateCompanyGroup: SubmitHandler<CompanyGroup> = async (data: CompanyGroup) => {
    dispatch(showSpinner());
    try {
      if (!editItem?.id) {
        //create ecd registries relationship
        const registryIds = registriesEcd.map((registry) => registry.id);
        data.registries = registryIds;
      }

      const payload = {
        ...data,
        chart_accounts: data.chart_accounts?.id || null,
        dre_avg_analytics: data.dre_avg_analytics?.id || null
      } as CompanyGroup;

      editItem?.id ? await CompaniesGroupsService.update(payload) : await CompaniesGroupsService.create(payload);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Grupo de Empresa atualizada com sucesso!' : 'Grupo de Empresa criada com sucesso!',
          severity: 'success'
        })
      );
      fetchCompaniesGroups();
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Grupo de Empresa
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createOrUpdateCompanyGroup)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="name">Nome</InputLabel>
              <ControlledTextInput
                name="name"
                placeholder="Nome para o grupo de empresas"
                control={control}
                errorMessage={errors.name?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Situação:</Typography>
              <ControlledCheckbox name="is_active" label={watchStatus ? 'Ativo' : 'Inativo'} control={control} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Usa Orçamento:</Typography>
              <ControlledCheckbox name="use_budget" label={watchStatusBudget ? 'Sim' : 'Não'} control={control} />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="body1">Usa Centro de Custo:</Typography>
              <ControlledCheckbox name="use_cost_center" label={watchStatusCostCenter ? 'Sim' : 'Não'} control={control} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="chart_accounts">Plano de Contas</InputLabel>
              <ControlledComboBox
                name="chart_accounts"
                control={control}
                errorMessage={errors.chart_accounts?.message?.toString()}
                selectOptions={chartAccounts}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="dre_avg_analytics">Análise para Calculo %AV no DRE</InputLabel>
              <ControlledComboBox
                name="dre_avg_analytics"
                control={control}
                errorMessage={errors.dre_avg_analytics?.message?.toString()}
                selectOptions={analytics}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
