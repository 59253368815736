import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { CompanyParameters } from '../models/CompanyParameters';
import { CompanyParametersFilterFields } from '../sections/@company-parameters/context/CompanyParametersPageContext';

const CompanyParametersUrl = '/parameters/';

export const CompanyParametersService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(CompanyParametersUrl, { params: { page_size: 0 } });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  get: async (filterFields: CompanyParametersFilterFields, page: number = 0, page_size: number = 5) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(CompanyParametersUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: CompanyParameters) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(CompanyParametersUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: CompanyParameters) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${CompanyParametersUrl}${data.id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.delete(`${CompanyParametersUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  deleteMany: async (ids: string[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${CompanyParametersUrl}batch-delete/`, {
        ids
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
