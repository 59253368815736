import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { IAnalyticsFilter } from '../models/Analytics';

const AnalysisFCUrl = '/reports/fc';

export const AnalysisFCService = {
  post: async (data: IAnalyticsFilter) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(AnalysisFCUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
