import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { INavGroup, INavItem } from '../../../../../models/Menu';
import { activeNavGroup } from '../../../../../store/slicers/menu.slicers';
import { RootState } from '../../../../../store/store';
import { DrawerClosedWithSubmenus } from './components/DrawerClosedWithSubmenus';
import { DrawerOpenWithSubmenus } from './components/DrawerOpenWithSubmenus';

interface NavGroupProps {
  item: INavGroup;
}
export const NavGroup: React.FC<NavGroupProps> = ({ item }: NavGroupProps) => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { drawerOpen, openNavGroup, openNavItem } = useSelector((state: RootState) => state.menu);

  const isAnyChildEnabled = (item: any, openNavItem: string) => {
    if (item.children && item.children.length > 0) {
      // Verifica se algum filho está habilitado recursivamente
      return item.children.some((child: any) => isAnyChildEnabled(child, openNavItem));
    } else {
      // Verifica se o próprio item está habilitado
      return item.id === openNavItem || item.url === location.pathname;
    }
  };

  const navItemSelected = isAnyChildEnabled(item, openNavItem);
  const groupOpen = openNavGroup.includes(item.id);

  React.useEffect(() => {
    if (item.url === location.pathname) {
      dispatch(activeNavGroup({ openNavGroup: item.id }));
    }

    if (item?.children?.some((child: INavItem) => child.url === location.pathname)) {
      dispatch(activeNavGroup({ openNavGroup: item.id }));
    }
  }, []);

  return (
    <>
      {/* MENU ABERTO */}
      {drawerOpen && <DrawerOpenWithSubmenus item={item} groupOpen={groupOpen} navItemSelected={navItemSelected} />}

      {/* MENU FECHADO */}
      {!drawerOpen && <DrawerClosedWithSubmenus item={item} navItemSelected={navItemSelected} />}
    </>
  );
};
