import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledTextInput } from '../../../../../components/basics/ControlledTextInput';
import { IAnalyticsDRERowBudget } from '../../../../../models/AnalyticsDRE';
import { BudgetItemReplicate } from '../../../../../models/BudgetItem';
import { CostCenter } from '../../../../../models/CostCenter';
import { CostCenterService } from '../../../../../services/CostCenter.service';
import { hideSpinner, showSpinner } from '../../../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../../../store/slicers/snackbarAlert.slicer';
import { CostCenterScheme } from '../../../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../../../utils/utils';

export interface BudgetItemCostCenter {
  cost_center?: number;
}

interface Props {
  onClose: () => void;
  item: BudgetItemReplicate;
  addLineCostCenter: (item: IAnalyticsDRERowBudget) => void;
  company: number;
}

export const BudgetItemCostCenterFormDialog = ({ onClose, item, addLineCostCenter, company }: Props) => {
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<CostCenter>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(CostCenterScheme),
    defaultValues: {}
  });

  const createCostCenter: SubmitHandler<CostCenter> = async (data: CostCenter) => {
    dispatch(showSpinner());

    try {
      data = {
        ...data,
        company: company ?? null
      };

      const response = await CostCenterService.create(data);
      if (response?.data?.id) {
        const newLine = {
          id: response?.data?.id,
          description: `${data?.code} - ${data.description}`,
          level: 4,
          values: {
            year_avg: '0',
            semi_annual_avg: '0',
            quarterly_avg: '0',
            monthly_avg: '0'
          },
          children: [],
          account: item?.row
        };

        addLineCostCenter(newLine);
      }
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: 'Centro de Custo cadastrado com sucesso!',
          severity: 'success'
        })
      );

      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Cadastrar Centro de Custo
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createCostCenter)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="code">Código</InputLabel>
              <ControlledTextInput name="code" placeholder="Código" control={control} errorMessage={errors.code?.message?.toString()} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="description">Descrição</InputLabel>
              <ControlledTextInput
                name="description"
                placeholder="Descrição"
                control={control}
                errorMessage={errors.description?.message?.toString()}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
