import { Box } from '@mui/material';
import styled from 'styled-components';

interface Props {
  open: boolean;
}

export const DrawerHeaderStyled = styled(Box).attrs({})<Props>`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: flex-end;
  background-color: ${({ theme, open }) => (open ? theme.palette.background.paper : theme.palette.secondary.main)};
  border-bottom: 1px solid ${({ theme, open }) => (open ? theme.palette.divider : 'transparent')};
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
`;
