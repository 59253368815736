// BarChart.js
import { Card, CardContent, Typography } from '@mui/material';
import ReactECharts from 'echarts-for-react';
import { formatColumnNameIntoDate, formatNegativeValue } from '../../../../../../../utils/utils';
import { GraphTableNCG } from './GraphTableComponent';

interface GraphComponentNcgProps {
  data: {
    data: {
      metadata: {
        title: string;
        years: string[];
        columns: string[];
      };
      report: Array<{
        label: string;
        type: string;
        data: Array<{
          period: string;
          entry_value: number;
        }>;
      }>;
    };
  };
}

export const GraphComponentNcg = ({ data }: GraphComponentNcgProps) => {
  const { metadata, report } = data?.data;
  const { title, columns } = metadata;

  const formattedColumns = columns.map((column) => formatColumnNameIntoDate(column, true));

  const generateSeries = () => {
    const hasBothLineAndBar = report.some((item) => item.type === 'LINE') && report.some((item) => item.type === 'BAR');

    return report
      .filter((item) => item.type !== 'NONE')
      .map((item) => ({
        name: item.label,
        type: item.type.toLowerCase(),
        data: item.data.map((d) => d.entry_value),
        yAxisIndex: hasBothLineAndBar && item.type === 'LINE' ? 1 : 0
      }));
  };

  const option = {
    tooltip: {
      trigger: 'axis',
      formatter: (params: any) => {
        let tooltipText = `${params[0].axisValue}<br/>`;
        params.forEach((item: any) => {
          const value = formatNegativeValue(item.data);
          tooltipText += `${item.marker} ${item.seriesName}: ${value}<br/>`;
        });
        return tooltipText;
      }
    },
    xAxis: {
      type: 'category',
      data: formattedColumns
    },
    yAxis: [
      {
        type: 'value'
      },
      {
        type: 'value',
        position: 'right'
      }
    ],
    series: generateSeries()
  };

  return (
    <Card style={{ marginBottom: '20px', backgroundColor: '#fff', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
      <CardContent sx={{ alignContent: 'center' }}>
        <Typography variant="h3" component="div" style={{ marginBottom: '10px', fontWeight: 'bold', textAlign: 'center' }}>
          {title}
        </Typography>
        <GraphTableNCG data={data} />
        <ReactECharts option={option} style={{ height: '400px', width: '100%' }} />
      </CardContent>
    </Card>
  );
};
