import { useState } from 'react';
import { AccountingEntry } from '../../../../models/AccountingEntry';
import { ApiResults } from '../../../../models/ApiResults';
import { CompanyGroup } from '../../../../models/CompanyGroup';
import { ManualInputsContext } from '../../../@manual-inputs/context/ManualInputsContext';
import { IAnalysisModal } from '../../models/AnalysisModal';
import { ManualInputsTableModal } from './ManualInputsTableModal';

interface Props {
  modalData: IAnalysisModal;
  companyGroup: CompanyGroup;
  onClose: () => void;
  onSubmit: () => Promise<void>;
  onMinimize: () => void;
  onToggleModalFullscreen: () => void;
}

export interface AccountingEntryFilterFields extends AccountingEntry {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}
export interface AccountEntryResult extends ApiResults {
  results: {
    metadata: {
      previous_balance: string;
      total_credit: string;
      total_debit: string;
    };
    report: AccountingEntry[];
  };
}

export const ManualInputsModal = ({ modalData, companyGroup, onClose, onMinimize, onToggleModalFullscreen, onSubmit }: Props) => {
  const [shouldRefresh, setShouldRefresh] = useState(false);

  //se o usuário salvou alguma alteração, guarda um estado para atualizar a tabela quando o modal for fechado
  const handleManualInputSubmitted = async (refresh?: boolean) => {
    setShouldRefresh(refresh ?? false);
  };

  const handleClose = () => {
    shouldRefresh ? onSubmit() : onClose();
  };

  return (
    <ManualInputsContext>
      <ManualInputsTableModal
        key={modalData.title}
        modalData={modalData}
        companyGroup={companyGroup}
        onClose={handleClose}
        onSubmit={handleManualInputSubmitted}
        onMinimize={onMinimize}
        onToggleModalFullscreen={onToggleModalFullscreen}
      />
    </ManualInputsContext>
  );
};
