// useCRUDPermissions.js
import { useEffect, useState } from 'react';
import { useAuthContext } from '../context/AuthContextProvider';

interface Props {
  submodule: string;
}

export interface PermissionsObj {
  hasCreatePermission: boolean;
  hasReadPermission: boolean;
  hasUpdatePermission: boolean;
  hasDeletePermission: boolean;
}

export const useCrudPermissions = ({ submodule }: Props) => {
  const { user: userAuth } = useAuthContext();
  const [permissions, setPermissions] = useState<PermissionsObj>({} as PermissionsObj);

  const hasCreatePermission = () => {
    return (
      userAuth?.session_permissions?.some(
        (permission) => permission?.submodule?.toUpperCase() === submodule.toUpperCase() && permission.permission === 'CREATE'
      ) || false
    );
  };

  const hasReadPermission = () => {
    return (
      userAuth?.session_permissions?.some(
        (permission) => permission?.submodule?.toUpperCase() === submodule.toUpperCase() && permission.permission === 'READ'
      ) || false
    );
  };

  const hasUpdatePermission = () => {
    return (
      userAuth?.session_permissions?.some(
        (permission) => permission?.submodule?.toUpperCase() === submodule.toUpperCase() && permission.permission === 'UPDATE'
      ) || false
    );
  };

  const hasDeletePermission = () => {
    return (
      userAuth?.session_permissions?.some(
        (permission) => permission?.submodule?.toUpperCase() === submodule.toUpperCase() && permission.permission === 'DELETE'
      ) || false
    );
  };

  useEffect(() => {
    let createPermission = hasCreatePermission();
    let readPermision = hasReadPermission();
    let updatePermission = hasUpdatePermission();
    let deletePermission = hasDeletePermission();

    setPermissions({
      hasCreatePermission: createPermission,
      hasReadPermission: readPermision,
      hasUpdatePermission: updatePermission,
      hasDeletePermission: deletePermission
    });
  }, [userAuth]);

  return {
    permissions
  };
};
