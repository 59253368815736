import { AccessRoutes } from './access';
import { AnalyticsRoutes } from './analytics';
import { ConciliationRoutes } from './conciliation';
import { DashboardRoutes } from './dashboard';
import { OrganizationsRoutes } from './organizations';
import { ProcessingRoutes } from './processing';
import { SettingsRoutes } from './settings';

// ==============================|| MENU ITEMS ||============================== //

export const MenuItems = {
  items: [DashboardRoutes, AccessRoutes, OrganizationsRoutes, AnalyticsRoutes, ProcessingRoutes, ConciliationRoutes, SettingsRoutes]
};
