import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';

const BlockRegistryUrl = '/ecd/registry/';

export const BlockRegistryService = {
  get: async (page: number = 0, page_size: number = 0) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(BlockRegistryUrl, {
        params: {
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
