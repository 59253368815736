import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';

const CheckProcessingUrl = '/ecd/check-processing/';

export const CheckProcessingService = {
  get: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(CheckProcessingUrl, {
        params: {
          page_size: 0
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
