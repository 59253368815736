import { Close } from '@mui/icons-material';
import CheckIcon from '@mui/icons-material/Check';
import { Box, Button, DialogActions, DialogContent, Divider, InputLabel, Popover } from '@mui/material';
import { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../../components/basics/ControlledComboBox';
import { IAnalyticsChart } from '../../../../models/AnalyticsChart';
import { CostCenter } from '../../../../models/CostCenter';
import { CostCenterService } from '../../../../services/CostCenter.service';
import { showSnackbarAlert } from '../../../../store/slicers/snackbarAlert.slicer';

interface Props {
  anchorEl: HTMLElement | null;
  onClose: () => void;
  onFilter: (costCenters: number[] | null) => void;
}

export const BudgetItemFilterPopover = ({ anchorEl, onClose, onFilter }: Props) => {
  const dispatch = useDispatch();
  const [costCenters, setCostCenters] = useState<SelectOption[]>([]);

  const { handleSubmit, watch, control, reset } = useForm<CostCenter>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {}
  });

  const filterSubmit: SubmitHandler<IAnalyticsChart> = () => {
    onClose();
    const selectedCostCenters = watch('cost_centers')?.map((cc: SelectOption) => cc.id) || null;
    onFilter(selectedCostCenters);
  };

  const handleClear = () => {
    reset({
      cost_centers: []
    });
  };

  const fetchCostCenters = async () => {
    try {
      const response = await CostCenterService.get({ page_size: 0 });
      if (response?.data?.results) {
        const dataAccounts = response?.data?.results;
        setCostCenters(
          dataAccounts.map((account: CostCenter) => ({
            id: account.id,
            name: `${account.code} - ${account.description}`
          }))
        );
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: 'Erro ao buscar Centros de Custo',
          severity: 'error'
        })
      );
    }
  };

  useEffect(() => {
    fetchCostCenters();
  }, []);

  return (
    <Popover
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left'
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}
    >
      <form onSubmit={handleSubmit(filterSubmit)}>
        <DialogContent sx={{ width: '300px' }}>
          <Box sx={{ pt: 2, px: 2, pr: 1, display: 'row', alignItems: 'center' }}>
            <InputLabel htmlFor="cost_centers">Centros de Custo</InputLabel>
            <ControlledComboBox
              placeholder="Centros de Custo"
              name="cost_centers"
              multiple={true}
              control={control}
              selectOptions={costCenters}
            />
          </Box>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 1, my: 1, justifyContent: 'center' }}>
          <Button variant="outlined" color="primary" onClick={handleClear} startIcon={<Close />}>
            Limpar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" color="primary" type="submit">
            Filtrar
          </Button>
        </DialogActions>
      </form>
    </Popover>
  );
};
