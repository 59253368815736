import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Analytics } from '../../../models/Analytics';
import { AnalyticsType } from '../../../models/AnalyticsType';
import { ApiResults } from '../../../models/ApiResults';
import { AnalyticsTypeService } from '../../../services/AnalyticsType.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface AnalyticsTypeFilterFields extends AnalyticsType {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface AnalyticsFilterFields extends Analytics {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  list_children?: number;
  type?: string;
  chart_accounts?: number;
}

export interface AnalyticsTypeResult extends ApiResults {
  results: AnalyticsType[];
}

interface AnalyticsTypeContextProps {
  analyticsType: AnalyticsTypeResult | undefined;
  fetchAnalyticsType: () => void;
  deleteAnalyticsType: (id: number) => Promise<void>;
  deleteManyAnalyticsType: (items: string[]) => Promise<void>;
  filterFields: AnalyticsTypeFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<AnalyticsTypeFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const AnalyticsTypeContextProvider = createContext<AnalyticsTypeContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const AnalyticsTypeContext = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [analyticsType, setAnalyticsType] = React.useState<AnalyticsTypeResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<AnalyticsTypeFilterFields>({});

  const fetchAnalyticsType = async () => {
    dispatch(showSpinner());
    try {
      const response = await AnalyticsTypeService.get(filterFields, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setAnalyticsType(response.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteAnalyticsType = async (id: number) => {
    dispatch(showSpinner());
    try {
      await AnalyticsTypeService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      fetchAnalyticsType();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyAnalyticsType = async (items: string[]) => {
    dispatch(showSpinner());
    debugger;
    try {
      await AnalyticsTypeService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registros excluídos!',
          severity: 'success'
        })
      );
      fetchAnalyticsType();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchAnalyticsType();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: AnalyticsTypeContextProps = {
    analyticsType,
    fetchAnalyticsType,
    deleteAnalyticsType,
    deleteManyAnalyticsType,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <AnalyticsTypeContextProvider.Provider value={contextValue}>{children}</AnalyticsTypeContextProvider.Provider>;
};

export const useAnalyticsTypeContext = () => {
  const context = useContext(AnalyticsTypeContextProvider);
  if (!context) {
    throw new Error('useAnalyticsTypeContext must be used within a AnalyticsTypeContextProvider');
  }
  return context;
};
