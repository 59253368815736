import { List } from '@mui/material';
import { useEffect, useState } from 'react';
import { useAuthContext } from '../../../../../context/AuthContextProvider';
import { MenuItems } from '../../../../../menu-items';
import { INavGroup } from '../../../../../models/Menu';
import { NavGroup } from './NavGroup';

const Navigation: React.FC = () => {
  const { user: userAuth } = useAuthContext();

  // Estado local para armazenar os itens de menu com permissão
  const [menuItems, setMenuItems] = useState<INavGroup[]>();

  const checkMenuItemsPermissions = () => {
    const filteredMenuItems = MenuItems.items
      .map((item: INavGroup) => {
        // Se o item tiver filhos, filtre os filhos com permissão
        if (item.children) {
          const filteredChildren = item.children.filter((child) => {
            // Se não houver um módulo definido, mantenha o item
            if (!child.submodule) return true;

            // Verifique se o usuário tem permissão para o módulo
            return (
              userAuth?.session_permissions && userAuth.session_permissions.some((permission) => permission.submodule === child.submodule)
            );
          });

          // Se algum filho permanecer após a filtragem, mantenha o item pai
          if (filteredChildren.length > 0) {
            return { ...item, children: filteredChildren };
          } else {
            return null; // Remova o item pai se nenhum filho tiver permissão
          }
        }

        // Se não houver filhos, mantenha o item
        return item;
      })
      .filter(Boolean); // Remova itens nulos após a filtragem

    setMenuItems(filteredMenuItems as INavGroup[]);
  };

  useEffect(() => {
    if (userAuth?.session_permissions) {
      checkMenuItemsPermissions();
    }
  }, [userAuth]);

  return (
    <List>
      {menuItems?.map((item) => (
        <NavGroup key={item.id} item={item} />
      ))}
    </List>
  );
};

export default Navigation;
