import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography/Typography';
import { useAuthContext } from '../../../context/AuthContextProvider';
import { UserTabs } from '../UserTabPanel';
import { CardTabs, CoverCard, UserAvatar, UserAvatarWrapper } from './styles';
import { getUserName } from '../../../models/User';

export const UserCoverComponent = () => {
  const theme = useTheme();

  const { user: userAuth } = useAuthContext();

  return (
    <CoverCard sx={{ mt: 4 }}>
      <UserAvatarWrapper>
        <UserAvatar src={undefined} alt="Usuário" className="user-image" />
        <Typography variant="h4" mt={1} color={theme.palette.common.white}>
          {userAuth && getUserName(userAuth)}
        </Typography>
      </UserAvatarWrapper>
      <CardTabs>
        <UserTabs />
      </CardTabs>
    </CoverCard>
  );
};
