import { AxiosResponse, isAxiosError } from 'axios';
import createAxiosInstance from '../middlewares/axios.interceptors';
import { ChartAccounts } from '../models/ChartAccounts';
import { ChartAccountsFilterFields } from '../sections/@chart-accounts/context/ChartAccountsContext';

const ChartAccountUrl = '/analytics/charts-accounts/';

export const ChartAccountsService = {
  getAll: async () => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(ChartAccountUrl, { params: { page_size: 0 } });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  get: async (filterFields: ChartAccountsFilterFields, page: number = 0, page_size: number = 5) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(ChartAccountUrl, {
        params: {
          ...filterFields,
          page: page + 1,
          page_size: page_size
        }
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  getOne: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.get(`${ChartAccountUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  create: async (data: ChartAccounts) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(ChartAccountUrl, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  update: async (data: ChartAccounts) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.put(`${ChartAccountUrl}${data.id}/`, data);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  delete: async (id: number) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.delete(`${ChartAccountUrl}${id}/`);
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  },

  deleteMany: async (ids: string[]) => {
    const axiosInstance = createAxiosInstance();
    try {
      return await axiosInstance.post(`${ChartAccountUrl}batch-delete/`, {
        ids
      });
    } catch (err) {
      if (isAxiosError(err)) {
        throw err.response as AxiosResponse;
      }
    }
  }
};
