import { Box, Card, CircularProgress, Modal, Theme, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

export const GlobalSpinner = () => {
  const theme = useTheme();
  const ismobile = useMediaQuery((theme: Theme) => theme.breakpoints?.down('sm'));
  const { loading: showSpinner, message } = useSelector((state: RootState) => state.globalSpinner);

  return (
    <Modal
      open={showSpinner || false}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{
        display: 'flex',
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Card
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          borderRadius: ismobile ? 0 : 2,
          padding: '2rem',
          width: ismobile ? '100vw' : undefined,
          height: ismobile ? '100vh' : undefined,
          border: ismobile ? 'none' : 'solid',
          borderWidth: '2px',
          borderColor: theme.palette.primary.main
        }}
      >
        <Box
          gap={3}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <CircularProgress />
          <Typography variant="h5" sx={{ color: theme.palette.primary.main }} textAlign="center">
            {message}
          </Typography>
        </Box>
      </Card>
    </Modal>
  );
};
