import { SoldItemsPage } from '../../../sections/@sold-items/SoldItemsPage';
import { SoldItemsPageContext } from '../../../sections/@sold-items/context/SoldItemsContext';

export const SoldItemsPageWrapper = () => {
  return (
    <SoldItemsPageContext>
      <SoldItemsPage />
    </SoldItemsPageContext>
  );
};
