import { yupResolver } from '@hookform/resolvers/yup';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { Divider, Grid, InputLabel } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { ControlledComboBox, SelectOption } from '../../../components/basics/ControlledComboBox';
import { ControlledTextInput } from '../../../components/basics/ControlledTextInput';
import { AnalyticsType, AnalyticsTypeEnum } from '../../../models/AnalyticsType';
import { AnalyticsTypeService } from '../../../services/AnalyticsType.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { AnalyticsTypeScheme } from '../../../utils/forms/validations/formValidations';
import { setInputErrorsFromApi } from '../../../utils/utils';
import { useAnalyticsTypeContext } from '../context/AnalyticsTypeContext';

interface Props {
  editItem?: AnalyticsType;
  onClose: () => void;
}

export const AnalyticsTypeFormDialog = ({ editItem, onClose }: Props) => {
  const dispatch = useDispatch();
  const analyticsTypeOptions = Object.entries(AnalyticsTypeEnum).map(([key, value]) => ({ id: key, name: value }));
  const { fetchAnalyticsType } = useAnalyticsTypeContext();

  const getDefaultValues = (): AnalyticsType => {
    return {
      id: editItem?.id ?? 0,
      description: editItem?.description ?? '',
      code: editItem?.code ?? '',
      type: editItem?.type ? ({ id: editItem.type, name: AnalyticsTypeEnum[editItem.type] } as SelectOption) : null
    };
  };

  const {
    control,
    handleSubmit,
    setError,
    formState: { errors }
  } = useForm<AnalyticsType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(AnalyticsTypeScheme),
    defaultValues: getDefaultValues()
  });

  const createOrUpdateAnalyticsType: SubmitHandler<AnalyticsType> = async (data: AnalyticsType) => {
    dispatch(showSpinner());
    try {
      const payload = {
        ...data,
        type: data.type?.id
      } as AnalyticsType;

      editItem?.id ? await AnalyticsTypeService.update(payload) : await AnalyticsTypeService.create(payload);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso',
          message: editItem?.id ? 'Tipo de Análise atualizada com sucesso!' : 'Tipo de Análise criada com sucesso!',
          severity: 'success'
        })
      );
      fetchAnalyticsType();
      onClose();
    } catch (error: any) {
      const formError = setInputErrorsFromApi(setError, error.data);
      dispatch(
        showSnackbarAlert({
          title: 'Erro',
          message: formError?.join(','),
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <Dialog open={true} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        {editItem?.id ? 'Editar' : 'Novo'} Tipo de Análise
      </DialogTitle>
      <Divider />
      <form onSubmit={handleSubmit(createOrUpdateAnalyticsType)}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="code">Código</InputLabel>
              <ControlledTextInput name="code" placeholder="Código" control={control} errorMessage={errors.code?.message?.toString()} />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="description">Descrição</InputLabel>
              <ControlledTextInput
                name="description"
                placeholder="Descrição de Tipo de Análise"
                control={control}
                errorMessage={errors.description?.message?.toString()}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel htmlFor="type">Tipo</InputLabel>
              <ControlledComboBox
                name="type"
                control={control}
                errorMessage={errors.type?.message?.toString()}
                selectOptions={analyticsTypeOptions}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions sx={{ mx: 2, my: 1 }}>
          <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button startIcon={<CheckIcon />} variant="contained" type="submit">
            Salvar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};
