import { SelectOption } from '../components/basics/ControlledComboBox';


export enum AnalyticsTypeEnum {
  BP = "BP - Balanço Patrimonial",
  DRE = "DRE - Demonstração do Resultado do Exercício",
  NCG = "NCG - Necessidade de Capital de Giro",
  FC = "FC - Fluxo de Caixa",
}

export interface AnalyticsType {
  id: number;
  type?: string | SelectOption;
  code?: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
}
