import { useDispatch } from 'react-redux';
import { AttachmentsService } from '../services/Attachments.service';
import { showSnackbarAlert } from '../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../utils/api/response';

export const useUploadFile = () => {
  const dispatch = useDispatch();

  const uploadFiles = async (files: any[]) => {
    let uploadedAttachments: number[] = [];

    for (let i = 0; i < files.length; i++) {
      //se o arquivo já tiver sido enviado, não envia novamente
      if (files[i].id) {
        uploadedAttachments.push(files[i].id);
        continue;
      }

      try {
        let file = new FormData();
        file.append('file', files[i]);
        const response = await AttachmentsService.create(file);
        if (checkResponseStatus(response)) {
          uploadedAttachments.push(response?.data.id);
        }
      } catch (error: any) {
        dispatch(showSnackbarAlert({ title: 'Erro', message: 'Erro ao enviar anexo', severity: 'error' }));
      }
    }

    return uploadedAttachments;
  };

  return { uploadFiles };
};
