import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { Budget } from '../../../models/Budget';
import { BudgetItem } from '../../../models/BudgetItem';
import { BudgetService } from '../../../services/Budget.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface BudgetFilterFields extends Budget {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
}

export interface BudgetItemFilterFields extends BudgetItem {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  list_children?: number;
}

export interface BudgetResult extends ApiResults {
  results: Budget[];
}

interface BudgetContextProps {
  budgets: BudgetResult | undefined;
  fetchBudget: () => void;
  deleteBudget: (id: number) => Promise<void>;
  deleteManyBudget: (items: string[]) => Promise<void>;
  filterFields: BudgetFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<BudgetFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const BudgetContextProvider = createContext<BudgetContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const BudgetContext = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [budgets, setBudgets] = React.useState<BudgetResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<BudgetFilterFields>({});

  const fetchBudget = async () => {
    dispatch(showSpinner());
    try {
      const response = await BudgetService.get(filterFields, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setBudgets(response.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteBudget = async (id: number) => {
    dispatch(showSpinner());
    try {
      await BudgetService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      fetchBudget();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyBudget = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await BudgetService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registros excluídos!',
          severity: 'success'
        })
      );
      fetchBudget();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchBudget();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: BudgetContextProps = {
    budgets,
    fetchBudget,
    deleteBudget,
    deleteManyBudget,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <BudgetContextProvider.Provider value={contextValue}>{children}</BudgetContextProvider.Provider>;
};

export const useBudgetContext = () => {
  const context = useContext(BudgetContextProvider);
  if (!context) {
    throw new Error('useBudgetContext must be used within a BudgetContextProvider');
  }
  return context;
};
