import CloseIcon from '@mui/icons-material/Close';
import { Alert, Checkbox, Divider, FormControlLabel, Grid, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { CompanyGroup } from '../../../models/CompanyGroup';

interface Props {
  item: CompanyGroup;
  onClose: () => void;
}

export const CompanyGroupDetailsDialog = ({ item, onClose }: Props) => {
  return (
    <Dialog open={!!item} onClose={onClose} aria-labelledby="responsive-dialog-title" fullWidth>
      <DialogTitle variant="h4" color="primary">
        Detalhes do Grupo de Empresa
      </DialogTitle>
      <Divider />
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Nome:
            </Typography>
            <Typography variant="body1" gutterBottom>
              {item.name}
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Situação:
            </Typography>
            <>{item.is_active ? <Alert severity="success">Ativo</Alert> : <Alert severity="error">Inativo</Alert>}</>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Usa Orçamento:
            </Typography>
            <FormControlLabel
              control={<Checkbox checked={item.use_budget ? true : false} inputProps={{ 'aria-label': 'controlled' }} disabled />}
              label={item.use_budget ? 'Sim' : 'Não'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Usa Centro de custo:
            </Typography>
            <FormControlLabel
              control={<Checkbox checked={item.use_cost_center ? true : false} inputProps={{ 'aria-label': 'controlled' }} disabled />}
              label={item.use_cost_center ? 'Sim' : 'Não'}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Plano de Contas:
            </Typography>
            <Typography variant="body1" gutterBottom>
              -
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="body1" color="secondary" gutterBottom>
              Percentual de Análise:
            </Typography>
            <Typography variant="body1" gutterBottom>
              -
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Divider />
      <DialogActions sx={{ mx: 2, my: 1 }}>
        <Button startIcon={<CloseIcon />} variant="outlined" onClick={onClose}>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
