import React, { createContext, useContext, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ApiResults } from '../../../models/ApiResults';
import { CompanyGroup } from '../../../models/CompanyGroup';
import { CompaniesGroupsService } from '../../../services/CompaniesGroups.service';
import { hideSpinner, showSpinner } from '../../../store/slicers/globalSpinner.slicer';
import { showSnackbarAlert } from '../../../store/slicers/snackbarAlert.slicer';
import { checkResponseStatus } from '../../../utils/api/response';

export interface CompaniesGroupsFilterFields extends CompanyGroup {
  order_by?: string;
  page?: number;
  page_size?: number;
  search?: string;
  is_active?: any;
}

export interface CompaniesGroupsResult extends ApiResults {
  results: CompanyGroup[];
}

interface CompaniesGroupsContextProps {
  companiesGroups: CompaniesGroupsResult | undefined;
  fetchCompaniesGroups: () => void;
  deleteCompanyGroup: (id: number) => Promise<void>;
  deleteManyCompaniesGroups: (items: string[]) => Promise<void>;
  filterFields: CompaniesGroupsFilterFields;
  setFilterFields: React.Dispatch<React.SetStateAction<CompaniesGroupsFilterFields>>;
  page: number;
  rowsPerPage: number;
  handleOrderChange: (property: string, order: 'asc' | 'desc') => void;
  handlePageChange: (page: number) => void;
  handleOnChangeRowsPerPage: (page_size: number) => void;
}

const CompaniesGroupContextProvider = createContext<CompaniesGroupsContextProps | undefined>(undefined);

interface Props {
  children: React.ReactNode;
}

export const CompaniesGroupContext = ({ children }: Props) => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = React.useState<number>(50);
  const [companiesGroups, setCompaniesGroups] = React.useState<CompaniesGroupsResult | undefined>(undefined);
  const [filterFields, setFilterFields] = React.useState<CompaniesGroupsFilterFields>({});

  const fetchCompaniesGroups = async () => {
    dispatch(showSpinner());
    try {
      const response = await CompaniesGroupsService.get(filterFields, page, rowsPerPage);
      if (response && checkResponseStatus(response)) {
        setCompaniesGroups(response.data);
      }
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteCompanyGroup = async (id: number) => {
    dispatch(showSpinner());
    try {
      await CompaniesGroupsService.delete(id);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registro excluído!',
          severity: 'success'
        })
      );
      fetchCompaniesGroups();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data.detail || error.data.non_field_errors || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const deleteManyCompaniesGroups = async (items: string[]) => {
    dispatch(showSpinner());
    try {
      await CompaniesGroupsService.deleteMany(items);
      dispatch(
        showSnackbarAlert({
          title: 'Sucesso!',
          message: 'Registros excluídos!',
          severity: 'success'
        })
      );
      fetchCompaniesGroups();
    } catch (error: any) {
      dispatch(
        showSnackbarAlert({
          title: 'Erro!',
          message: error.data || 'Houve um erro ao processar a sua solicitação',
          severity: 'error'
        })
      );
    } finally {
      dispatch(hideSpinner());
    }
  };

  const handleOrderChange = (property: string, order: 'asc' | 'desc') => {
    const orderFilter = order === 'asc' ? property : `-${property}`;
    setFilterFields({ ...filterFields, order_by: orderFilter });
  };

  const handlePageChange = (page: number) => {
    setPage(page);
  };

  const handleOnChangeRowsPerPage = (page_size: number) => {
    setRowsPerPage(page_size);
  };

  useEffect(() => {
    fetchCompaniesGroups();
  }, [filterFields, page, rowsPerPage]);

  const contextValue: CompaniesGroupsContextProps = {
    companiesGroups,
    fetchCompaniesGroups,
    deleteCompanyGroup,
    deleteManyCompaniesGroups,
    filterFields,
    setFilterFields,
    page,
    rowsPerPage,
    handleOrderChange,
    handlePageChange,
    handleOnChangeRowsPerPage
  };

  return <CompaniesGroupContextProvider.Provider value={contextValue}>{children}</CompaniesGroupContextProvider.Provider>;
};

export const useCompaniesGroupsContext = () => {
  const context = useContext(CompaniesGroupContextProvider);
  if (!context) {
    throw new Error('useCompaniesContext must be used within a CompaniesGroupsProvider');
  }
  return context;
};
