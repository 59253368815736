import { FormHelperText, Switch, SwitchProps } from '@mui/material';
import React from 'react';
import { Control, Controller } from 'react-hook-form';

interface Props extends SwitchProps {
  name: string;
  control: Control<any>;
}

export const ControlledSwitch: React.FC<Props> = ({
  name,
  control,
  ...rest
}: Props) => {

  const _renderInput = ({ field: { onChange, value }, fieldState: { error } }: any) => {
    return (
      <>
        <Switch
          onChange={onChange}
          value={value}
          checked={value}
          {...rest}
        />
        {(error) && <FormHelperText error={!!error}>{error ? error.message : ''}</FormHelperText>}
      </>
    );
  };

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={false}
      render={_renderInput}
    />
  );
};
